import '../../styles/Wiki.css'

import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useQuery } from '@tanstack/react-query';

import { Button, IconButton } from '@mui/material';

import { RiCloseLine, RiGitPullRequestLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ReactMarkdown from 'react-markdown'
import { getWikiPage } from '../../services/wiki.service';
import CustomFields from './CustomFields'
import { UserInfosContext } from '../../context/UserInfosContext';

function Wiki({ ruleURL, ruleID, setWikiIsOpen, customFields }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [wikiContent, setWikiContent] = useState('No associated wiki page found for this SIGMA rule');

	const { setSnackbarObj } = useContext(UserInfosContext);

	const {
		data: githubVersion,
		isLoading: loadingGithubVersion,
		error: errorGithubVersion,
		failureReason: failureGithubVersion,
		failureCount: failureCountGithubVersion,
		isFetching: fetchingGithubVersion
	} = useQuery({
		enabled: !!ruleURL, // ensures the query is only executed when these conditions are met.
		queryKey: ['githubVersion', { path: ruleURL }],
		queryFn: () => getWikiPage(ruleURL),
	});
	useEffect(() => {
		if (githubVersion) { setWikiContent(githubVersion); }
	}, [githubVersion])

	useEffect(() => {
		const snackbarGithubVersion = ResponseSnackbarErrorHandler('githubVersion', errorGithubVersion, failureGithubVersion, failureCountGithubVersion)
		if (snackbarGithubVersion) { setSnackbarObj(snackbarGithubVersion) }

	}, [errorGithubVersion, failureCountGithubVersion, failureGithubVersion, setSnackbarObj]);

	return (
		<aside className='wiki_container'>
			<div className='wiki_title'>
				<IconButton
					aria-label="close"
					onClick={() => { setWikiIsOpen(false) }}
					sx={{
						position: 'absolute',
						left: 1,
						top: 1,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<RiCloseLine />
				</IconButton>
				<h4>Wiki</h4>
				<Button
					aria-label="edit"
					variant="outlined"
					type="submit"
					startIcon={<RiGitPullRequestLine />}
					onClick={() => { navigate('/wikis/' + ruleID) }}
					sx={{
						position: 'absolute',
						right: '2px',
						textTransform: 'none',
						fontSize: '11px', fontWeight: '400', padding: '5px 10px', margin: '5px',
					}}
				>
					{t('button.contribution')}
				</Button>
			</div>
			<div className='wiki_content invest-markdown'>
				{(loadingGithubVersion && fetchingGithubVersion)
					? "Loading ..."
					: <>
						<ReactMarkdown children={wikiContent} />
						<CustomFields data={customFields} />
					</>
				}
			</div>
		</aside>
	)
}

export default Wiki
