import { useState, useEffect, useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { Button } from '@mui/material';

import { RiSave2Line } from 'react-icons/ri';

import Loader from '../../components/Loader';
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { NotificationPreferencesSelector } from '../../components/NotificationPreferencesSelector';
import { getClientMe } from "../../services/client.service";
import { putUserMe } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function ProfilePageNotifications() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

    const [selectedOptions, setSelectedOptions] = useState(userInfos.notifications_preferences);

    const {
        data: myClientInfo,
        isLoading: loadingMyClientInfo,
        error: errorMyClientInfo,
        failureReason: failureMyClientInfo,
        failureCount: failureCountMyClientInfo,
        isFetching: fetchingMyClientInfo
    } = useQuery({
        enabled: !!getAccessTokenSilently && userInfos.profile === 'Client', // ensures the query is only executed when these conditions are met.
        queryKey: ['myClientInfo'],
        queryFn: () => getAccessTokenSilently().then((token) => getClientMe(token)),
    });

    const putUserMeMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => putUserMe(token, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myUserInfo'], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        putUserMeMutation.mutate({ notifications_preferences: selectedOptions })
    }

    useEffect(() => {
        const snackbarMyClientInfo = ResponseSnackbarErrorHandler('myClientInfo', errorMyClientInfo, failureMyClientInfo, failureCountMyClientInfo)
        if (snackbarMyClientInfo) { setSnackbarObj(snackbarMyClientInfo) }

    }, [errorMyClientInfo, failureCountMyClientInfo, failureMyClientInfo, setSnackbarObj]);


    /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
    if ((loadingMyClientInfo && fetchingMyClientInfo)
    ) return <Loader />

    return (
        <>
            <div className="flexbox-summary">
                <div className="widget">
                    <h5>{t('profile.notificationsPreferences-title')}</h5>
                    <div className="summary-content">

                        <div className="notificationType-item">
                            <ul>
                                {/* available only for clients */}
                                {userInfos.profile === 'Client' ? <>
                                    {myClientInfo?.invoice_periods?.alerts_allowed > 0 ?
                                        <li>
                                            <NotificationPreferencesSelector
                                                notificationType="subscription_monitoring"
                                                selectedOptions={selectedOptions}
                                                setSelectedOptions={setSelectedOptions}
                                            />
                                        </li>
                                        : null}
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="investigation_created"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="investigation_updated"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="investigation_pendingClient"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                </> : null}
                                {/* available only for to Nybble staff */}
                                {userInfos.profile === 'Analyst_L2' || userInfos.profile === 'Admin' ? <>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="investigation_commented"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="huntReport_pending"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="wikiContribution_pending"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                </> : null}
                                {/* available for Hunter & Hunter-Analyst */}
                                {userInfos.profile.includes('Hunter') ? <>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="huntCampaign_added"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="huntReport_reviewed"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                </> : null}
                                {/* available for Analyst_L1 & Hunter-Analyst */}
                                {userInfos.profile === 'Analyst_L1' || userInfos.profile === 'Hunter-Analyst' ? <>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="wikiContribution_reviewed"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                </> : null}
                                {/* available for external to Nybble staff */}
                                {userInfos.profile !== 'Admin' && userInfos.profile !== 'Analyst_L2' ? <>
                                    <li>
                                        <NotificationPreferencesSelector
                                            notificationType="invoice_available"
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </li>
                                </> : null}
                            </ul>
                        </div>
                    </div>
                    <div className='widget-submit-btn'>
                        <Button
                            variant="contained"
                            startIcon={<RiSave2Line />}
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            {t('button.save')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ProfilePageNotifications