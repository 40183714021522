import '../../App.css'
import '../../styles/AlertsPage.css'
import '../../styles/InvestigationsPage.css'
import '../../styles/HuntsPage.css'
import '../../styles/Wiki.css'

import { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown'

import { TextField, Grid, Button, Skeleton } from '@mui/material';
import { Alert, AlertTitle, IconButton, Collapse } from '@mui/material';

import { RiSendPlaneLine, RiSave2Line, RiArrowRightSLine, RiCloseLine, RiDeleteBin2Line, RiRefreshLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getWikiContribution, putWikiContribution, deleteWikiContribution } from '../../services/wikiContribution.service';
import { getUser } from '../../services/user.service';
import { getWikiPage, getRuleURLs } from '../../services/wiki.service';
import { dateExpire } from '../../utils/formatDate';
import { UserInfosContext } from '../../context/UserInfosContext';


function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

function EditWikiContribution() {
	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient()

	let { contributionId } = useParams();
	const navigate = useNavigate();

	const [openAlert, setOpenAlert] = useState(true);
	const [validatorName, setValidatorName] = useState('');
	const [expireDate, setExpireDate] = useState();

	const { setSnackbarObj } = useContext(UserInfosContext);

	// react state. used for field validation and initial display
	const [wikiFormValues, setWikiFormValues] = useState({
		wiki_page_name: '',
		status: '',
		wiki_file_path: '',
		new_version: '',
		current_version: '',
	});

	// validation failed indicators
	const [wikiFormErrors, setWikiFormErrors] = useState({
		wiki_page_name: false,
		status: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	// has user done at least one thing on the field (to avoid displaying an error at 1st form display)
	const [wikiFormTouches, setWikiFormTouches] = useState({
		wiki_page_name: false,
		status: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	const {
		data: ruleURLs,
		isLoading: loadingRuleURLs,
		error: errorRuleURLs,
		failureReason: failureRuleURLs,
		failureCount: failureCountRuleURLs,
		isFetching: fetchingRuleURLs,
	} = useQuery({
		queryKey: ['ruleURLs'],
		queryFn: () => getRuleURLs(),
		staleTime: 60 * 60 * 1000, // 60 mins (duration data is considered fresh)
		cacheTime: 65 * 60 * 1000, // 65 mins (duration React Query stores inactive data before it is deleted from the cache)
	});

	const {
		data: wikiContribution,
		isLoading: loadingWikiContribution,
		error: errorWikiContribution,
		failureReason: failureWikiContribution,
		failureCount: failureCountWikiContribution,
		isFetching: fetchingWikiContribution
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when getAccessTokenSilently and campaignId are available.
		queryKey: ['wikiContribution', { _id: contributionId }],
		queryFn: () => getAccessTokenSilently().then((token) => getWikiContribution(token, contributionId)),
	});
	useEffect(() => {
		if (wikiContribution) {
			setWikiFormValues(wikiContribution);
			setExpireDate(dateExpire(wikiContribution.createdAt))
		}
	}, [wikiContribution])

	const {
		data: user,
		// isLoading: loadingUser,
		error: errorUser,
		failureReason: failureUser,
		failureCount: failureCountUser,
		// isFetching: fetchingUser
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!wikiFormValues.validator_id, // ensures the query is only executed when these conditions are met.
		queryKey: ['user', { _id: wikiFormValues.validator_id }],
		queryFn: () => getAccessTokenSilently().then((token) => getUser(token, wikiFormValues.validator_id)),
	});
	useEffect(() => {
		if (user) { setValidatorName(user.displayName + ' <' + user.email + '>'); }
	}, [user])

	const {
		data: githubVersion,
		isLoading: loadingGithubVersion,
		error: errorGithubVersion,
		failureReason: failureGithubVersion,
		failureCount: failureCountGithubVersion,
		isFetching: fetchingGithubVersion
	} = useQuery({
		enabled: wikiFormValues?.wiki_file_path?.length !== 0, // ensures the query is only executed when these conditions are met.
		queryKey: ['githubVersion', { path: wikiFormValues.wiki_file_path }],
		queryFn: () => getWikiPage(wikiFormValues.wiki_file_path),
	});

	// update the react state with user input
	const handleInputChange = e => {
		const { name, value } = e.target
		setWikiFormValues({ ...wikiFormValues, [name]: value })
	}

	// update the validation infos when user leaves the field (used only on fields requiring validation)
	const handleBlur = e => {
		const { name,
			// value
		} = e.target
		wikiFormValues[name] ? setWikiFormErrors({ ...wikiFormErrors, [name]: false }) : setWikiFormErrors({ ...wikiFormErrors, [name]: true });
		setWikiFormTouches({ ...wikiFormTouches, [name]: true });
	}

	// submit button: enabled only when everything is alright
	function isEnabled() {
		return (
			(wikiFormValues.status === 'Draft')
		)
	}

	const putWikiContributionMutation = useMutation({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		mutationFn: (content) => getAccessTokenSilently().then((token) => putWikiContribution(token, contributionId, content)),
		onSuccess: (data) => {
			queryClient.setQueryData(['wikiContribution', { _id: contributionId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
			queryClient.invalidateQueries({ queryKey: ['wikiContributionsList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: data.status === 'Pending Review' ? t("snackbar.submitted") : t("snackbar.saved"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			navigate(data.status === 'Pending Review' ? '/wikis' : null)
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContribution', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleSave = (e) => {
		e.preventDefault()
		putWikiContributionMutation.mutate(wikiFormValues)
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		putWikiContributionMutation.mutate({ ...wikiFormValues, status: 'Pending Review' });
	}

	async function handleReload() {
		const ruleID = getKeyByValue(ruleURLs, wikiFormValues.wiki_file_path)
		if (ruleID) {
			navigate('/wikis/' + ruleID)
		}
	}

	const deleteWikiContributionMutation = useMutation({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => deleteWikiContribution(token, contributionId)),
		onSuccess: () => {
			// result.message === "Successfully deleted. "
			queryClient.invalidateQueries({ queryKey: ['wikiContribution', { _id: contributionId }] }) //invalid query data, need refetch
			queryClient.invalidateQueries({ queryKey: ['wikiContributionsList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.deleted"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			navigate('/wikis')
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContribution', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleDelete = (e) => {
		e.preventDefault()
		deleteWikiContributionMutation.mutate()
	}

	useEffect(() => {
		const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
		if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

		const snackbarWikiContribution = ResponseSnackbarErrorHandler('wikiContribution', errorWikiContribution, failureWikiContribution, failureCountWikiContribution)
		if (snackbarWikiContribution) { setSnackbarObj(snackbarWikiContribution) }

		const snackbarGithubVersion = ResponseSnackbarErrorHandler('githubVersion', errorGithubVersion, failureGithubVersion, failureCountGithubVersion)
		if (snackbarGithubVersion) { setSnackbarObj(snackbarGithubVersion) }

		const snackbarRuleURLs = ResponseSnackbarErrorHandler('ruleURLs', errorRuleURLs, failureRuleURLs, failureCountRuleURLs)
		if (snackbarRuleURLs) { setSnackbarObj(snackbarRuleURLs) }

	}, [errorGithubVersion, errorRuleURLs, errorUser, errorWikiContribution, failureCountGithubVersion, failureCountRuleURLs, failureCountUser, failureCountWikiContribution, failureGithubVersion, failureRuleURLs, failureUser, failureWikiContribution, setSnackbarObj]);

	/* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
	const loadingWikiContributionData = (loadingWikiContribution && fetchingWikiContribution)
	const loadingGithubVersionData = (loadingGithubVersion && fetchingGithubVersion)
	const loadingRuleURLsData = (loadingRuleURLs && fetchingRuleURLs)


	return (
		<div className='report-container'>
			<div className="menu-title">
				<h4>
					<span>
						<Link activeclassname={"active"} to='/wikis'>
							{/* Edit Wiki */}
							{t('wikis.createWikiContribution.head-title')}
						</Link>
					</span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} />{wikiFormValues.wiki_page_name + ' '}
					{wikiFormValues.status === 'Draft'
						? <span className='menu-expireDate'>
							({t('wikis.editWikiContribution.head-expirationDate')}{expireDate})
						</span>
						: null
					}
				</h4>
				{loadingWikiContributionData ? <Skeleton width={'350px'} /> : null}
			</div>
			<div className='report-form'>
				{wikiFormValues.status === 'Draft' && !loadingGithubVersionData && githubVersion !== wikiFormValues.current_version
					? <Collapse in={openAlert}>
						<Alert severity="warning"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpenAlert(false);
									}}
								>
									<RiCloseLine fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2 }}
						>
							<AlertTitle>{t('wikis.editWikiContribution.alert-title-updated')}</AlertTitle>
							{t('wikis.editWikiContribution.alert-title-updated2')}
						</Alert>
					</Collapse>
					: null
				}
				{validatorName && wikiFormValues.status === 'Accepted'
					? <Collapse in={openAlert}>
						<Alert severity="success"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpenAlert(false);
									}}
								>
									<RiCloseLine fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2 }}
						>
							{/* <AlertTitle>This contribution has been accepted by {validatorName}</AlertTitle> */}
							<AlertTitle>{t('wikis.editWikiContribution.alert-title-accepted')} {validatorName}</AlertTitle>
							{wikiFormValues.price}{t('wikis.editWikiContribution.alert-title-accepted2')}
						</Alert>
					</Collapse>
					: null
				}
				{validatorName && wikiFormValues.status === 'Rejected'
					? <Collapse in={openAlert}>
						<Alert severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpenAlert(false);
									}}
								>
									<RiCloseLine fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2 }}
						>
							{/* <AlertTitle>This contribution has been rejected by {validatorName}</AlertTitle> */}
							<AlertTitle>{t('wikis.editWikiContribution.alert-title-rejected')} {validatorName}</AlertTitle>
							{t('wikis.editWikiContribution.alert-title-rejected2')} {wikiFormValues.reject_reason}
						</Alert>
					</Collapse>
					: null
				}
				{wikiFormValues.status === 'Pending Review'
					? <Collapse in={openAlert}>
						<Alert severity="info"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpenAlert(false);
									}}
								>
									<RiCloseLine fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2 }}
						>
							{/* <AlertTitle>This contribution is pending review.</AlertTitle> */}
							<AlertTitle>{t('wikis.editWikiContribution.alert-title-pending')}</AlertTitle>
							{t('wikis.editWikiContribution.alert-title-pending2')}
						</Alert>
					</Collapse>
					: null
				}
				<Grid container spacing={2} direction='row'>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiSave2Line />}
							disabled={!isEnabled()}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-6)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-6)" }
							}}
							onClick={(e) => { handleSave(e) }}
						>
							{/* Save */}
							{t('button.save')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiSendPlaneLine />}
							disabled={!isEnabled()}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-4)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-4)" }
							}}
							onClick={(e) => { handleSubmit(e) }}
						>
							{/* Submit */}
							{t('button.submit')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiRefreshLine />}
							disabled={!isEnabled() || !!loadingRuleURLsData}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-7)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-7)" }
							}}
							onClick={() => { handleReload() }}
						>
							{/* Submit */}
							{t('button.reload')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiDeleteBin2Line />}
							disabled={!isEnabled()}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-2)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-2)" }
							}}
							onClick={(e) => { handleDelete(e) }}
						>
							{/* Submit */}
							{t('button.delete')}
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} md={6}>
						<div className="widget">
							<h5>{t('form.wiki-edition')}</h5>
							<div className="widgetFormValuesTextArea edit_wiki_content">
								{loadingWikiContributionData
									? [...Array(10)].map((_, index) => (
										<Skeleton key={index} />
									))
									: <TextField
										fullWidth
										required
										name="new_version"
										variant="standard"
										multiline
										minRows={3}
										maxRows={50}
										onChange={handleInputChange}
										onBlur={handleBlur}
										value={wikiFormValues.new_version}
										InputProps={{ readOnly: wikiFormValues.status === 'Draft' ? false : true, }}
									/>
								}
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6}>
						<div className='widget'>
							<h5>{t('form.wiki-render')}</h5>
							<div className='invest-markdown edit_wiki_content'>
								{loadingWikiContributionData
									? <>
										<Skeleton variant="rounded" width={'100%'} height={'27px'} sx={{ margin: '0.67em 0' }} />
										{[...Array(9)].map((_, index) => (
											<Skeleton key={index} />
										))}
									</>
									: <ReactMarkdown children={wikiFormValues.new_version} />
								}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default EditWikiContribution
