import { useState, useEffect, useContext, useMemo } from 'react'
import '../styles/Scoring.css'

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";
import { useQuery } from '@tanstack/react-query';

import { IconButton, Tooltip, TextField, Skeleton, Button } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dialog, DialogContent } from "@mui/material";

import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { RiArrowLeftSLine, RiArrowRightSLine, RiBarChart2Line } from 'react-icons/ri';

import moment from 'moment';

import { ResponseSnackbarErrorHandler } from './ResponseSnackbar';
import { UserInfosContext } from '../context/UserInfosContext';
import { fetchUserScoring } from '../services/user.service';
import { formatDate } from "../utils/formatDate";
import { reduceOpacity, darkenColor } from "../utils/formatStyle";
import { LineChart, RadarChart } from './Charts';
import { CustomDialogTitle } from './CustomDialogTitle';

import { Table } from "@nybble-security/nybble-react-library";


function ScoringStats({ userId }) {

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const { setSnackbarObj } = useContext(UserInfosContext);

  const todayDate = moment();
  const todayDateIso = todayDate.toISOString();
  const yesterdayDateIso = moment().subtract(1, 'day').toISOString();

  const [fromDateFormValue, setFromDateFormValue] = useState(moment(yesterdayDateIso));
  const [toDateFormValue, setToDateFormValue] = useState(moment(todayDateIso));
  const [scoringPeriod, setScoringPeriod] = useState('day');

  const {
    data: userScoring,
    isLoading: loadingUserScoring,
    error: errorUserScoring,
    failureReason: failureUserScoring,
    failureCount: failureCountUserScoring,
    isFetching: fetchingUserScoring
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!fromDateFormValue && !!toDateFormValue, // ensures the query is only executed when these conditions are met.
    queryKey: ['userScoring', { _id: userId, period: scoringPeriod, toDate: toDateFormValue }],
    queryFn: () => getAccessTokenSilently().then((token) => fetchUserScoring(token, {
      userId: userId,
      fromDate: fromDateFormValue.toISOString(),
      toDate: toDateFormValue.toISOString()
    })),
    staleTime: 60 * 60 * 1000, // 60 mins (duration data is considered fresh)
    cacheTime: 65 * 60 * 1000, // 65 mins (duration React Query stores inactive data before it is deleted from the cache)
  });

  const handleToggle = (event, period) => {
    if (!!period) {
      setScoringPeriod(period);
      setFromDateFormValue(toDateFormValue.clone().subtract(1, period)); // Subtract 1 period on today's date and set fromDate
    }
  };

  const handleDateChange = (operation) => {
    let newToDate, newFromDate;

    // add or subtract 1 day, week, month or year based on scoringPeriod
    newToDate = toDateFormValue.clone()[operation](1, scoringPeriod);
    newFromDate = fromDateFormValue.clone()[operation](1, scoringPeriod);

    // Check if the new toDate is after today's date
    if (newToDate.isAfter(moment(), 'day')) {
      newToDate = moment(); // Set toDate to today's date
      newFromDate = moment().subtract(1, scoringPeriod); // Subtract 1 scoringPeriod on today's date and set fromDate
    }

    setToDateFormValue(newToDate);
    setFromDateFormValue(newFromDate);
  };

  const handleIncrementDate = () => {
    handleDateChange('add');
  };

  const handleDecrementDate = () => {
    handleDateChange('subtract');
  };

  useEffect(() => {
    const snackbarUserInfos = ResponseSnackbarErrorHandler('userScoring', errorUserScoring, failureUserScoring, failureCountUserScoring)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }
  }, [errorUserScoring, failureCountUserScoring, failureUserScoring, setSnackbarObj]);


  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingUserScoringData = (loadingUserScoring && fetchingUserScoring)


  return (
    <div className="scoringContainer">
      <h4>
        <span>
          {t('statistics.scoring.page-title')}
        </span>
      </h4>
      <div className='scoringHeader'>
        <div className='scoringDatePickerContainer'>
          <IconButton
            onClick={handleDecrementDate}
          >
            <RiArrowLeftSLine />
          </IconButton>
          <IconButton
            onClick={handleIncrementDate}
            disabled={toDateFormValue.isSame(moment().startOf('day'), 'day')}
            sx={{
              "&.Mui-disabled": {
                pointerEvents: "unset", // allow :hover styles to be triggered
                cursor: "not-allowed", // and custom cursor can be defined without :hover state
              },
            }}
          >
            <RiArrowRightSLine />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              format="DD/MM/YYYY"
              value={toDateFormValue}
              disableFuture
              openTo={scoringPeriod === 'week' ? 'day' : scoringPeriod}
              views={['year', 'month', 'day']}
              onChange={(newValue) => {
                setToDateFormValue(newValue)
                setFromDateFormValue(newValue.clone().subtract(1, scoringPeriod));
              }}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{
                textField: {
                  size: 'small',
                }
              }}
              InputProps={{
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                  "& .MuiButtonBase-root": { padding: "0" },
                }
              }}
            />
          </LocalizationProvider>
        </div>
        <div>
          <ToggleButtonGroup
            color='primary'
            value={scoringPeriod}
            exclusive
            onChange={handleToggle}
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <ToggleButton
              value='day'
              variant="outlined"
              sx={{
                textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 12px',
                width: '100%',
                whiteSpace: 'nowrap',
                "&.Mui-selected, &.Mui-selected:hover": {
                  borderColor: 'var(--button-background-9)',
                  backgroundColor: 'var(--button-background-9)',
                  color: 'white'
                },
              }}
            >
              {t('button.1d')}
            </ToggleButton>
            <ToggleButton
              value='week'
              variant="outlined"
              sx={{
                textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 12px',
                width: '100%',
                whiteSpace: 'nowrap',
                "&.Mui-selected, &.Mui-selected:hover": {
                  borderColor: 'var(--button-background-9)',
                  backgroundColor: 'var(--button-background-9)',
                  color: 'white'
                },
              }}
            >
              {t('button.1w')}
            </ToggleButton>
            <ToggleButton
              value='month'
              variant="outlined"
              sx={{
                textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 12px',
                width: '100%',
                whiteSpace: 'nowrap',
                "&.Mui-selected, &.Mui-selected:hover": {
                  borderColor: 'var(--button-background-9)',
                  backgroundColor: 'var(--button-background-9)',
                  color: 'white'
                },
              }}
            >
              {t('button.1m')}
            </ToggleButton>
            <ToggleButton
              value='year'
              variant="outlined"
              sx={{
                textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 12px',
                width: '100%',
                whiteSpace: 'nowrap',
                "&.Mui-selected, &.Mui-selected:hover": {
                  borderColor: 'var(--button-background-9)',
                  backgroundColor: 'var(--button-background-9)',
                  color: 'white'
                },
              }}
            >
              {t('button.1y')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {!loadingUserScoringData && (!userScoring || userScoring.length === 0)
        ? <div className="scoringNoData">
          <RiBarChart2Line />
          <h2>{t('statistics.scoring.noData-title') + ' ' + t('statistics.scoring.page-title')}</h2>
          <span>{t('statistics.scoring.noData-period')}</span>
        </div>
        : scoringPeriod === 'day'
          ? <Rank1Day userScoring={userScoring} loadingData={loadingUserScoringData} />
          : <RankRange
            userScoring={userScoring}
            loadingData={loadingUserScoringData}
            setScoringPeriod={setScoringPeriod}
            setToDateFormValue={setToDateFormValue}
            setFromDateFormValue={setFromDateFormValue}
          />
      }
    </div >
  )
}

function Rank1Day({ userScoring, loadingData }) {
  const { t } = useTranslation();

  const [openHelperDialog, setOpenHelperDialog] = useState(false);

  const labels = [
    "NBAL",
    "TR",
    "SLA",
    "NoteComm",
    "NoteContr",
    "TFP",
    "TTP",
    "TFN",
    "TTN"
  ];

  const colorChart = getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color1')

  const rank = userScoring?.[0]?.rank ?? {};
  const transformedData = [rank].flatMap(item => labels.map(label => item[label]));

  const datasets = [{
    data: transformedData,
    borderWidth: 2,
    borderColor: colorChart,
    backgroundColor: 'transparent',
  }];

  const highestNbTotalAnalysts = Math.max(rank.NbTotalAnalysts); // Calculate the highest value of NbTotalAnalysts
  const suggestedMax = highestNbTotalAnalysts * 1.1; // Adds 10% to have a better visual rendering for points equal to the max value

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 1, // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMax: suggestedMax, // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        reverse: true, // invert axis
      },
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  }

  return (
    <div className="scoringRank1DayContainer">
      <div className="scoringRank1DaySubContainer">
        <div className="scoringWidget">
          {loadingData ? <Skeleton variant='circular' width={'100%'} height={'100%'} animation="wave" /> :
            <>
              <h5>{t(`statistics.scoring.NOTE-title`)}</h5>
              <span className='rankNote'> {userScoring[0]?.rank?.NOTE} </span>
              <span className='rankNbTotal'> / {userScoring[0]?.rank?.NbTotalAnalysts}</span>
            </>
          }
        </div>
        <div className="scoringRank1DayTextContainer">
          <div>
            <p>
              {t(`statistics.scoring.helperText.title`)}
              <Button
                onClick={() => { setOpenHelperDialog(true) }}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '2px', margin: '0 3px',
                  minWidth: '0px',
                }}
              >
                {t('button.more')}
              </Button>
            </p>
          </div>
          <Dialog
            fullWidth
            maxWidth='md'
            open={openHelperDialog}
            onClose={() => setOpenHelperDialog(false)}
          >
            <CustomDialogTitle onClose={() => setOpenHelperDialog(false)}>
              {t('general.help')}
            </CustomDialogTitle>
            <DialogContent>
              {labels.map((label, index) => (
                <>
                  {index !== 0 ? <hr style={{ width: '100%', border: '0.5px solid #c4c4c4' }} /> : null}
                    <span key={index} className='helperTextTitle'>
                      {t(`statistics.scoring.helperText.${label}-title`)} : </span>
                    <span> {t(`statistics.scoring.helperText.${label}-text`)} </span>
                </>
              ))}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className='scoringRank1DayChartContainer'>
        {loadingData ? <Skeleton variant='circular' width={'100%'} height={'100%'} animation="wave" /> :
          <RadarChart labels={labels} datasets={datasets} options={options} />
        }
      </div>
    </div>
  );
}

function RankRange({ userScoring, loadingData, setScoringPeriod, setToDateFormValue, setFromDateFormValue }) {
  const { t } = useTranslation();
  const { userInfos } = useContext(UserInfosContext);

  const rank = userScoring || []

  // Extracting labels (timestamps)
  const labels = rank.map(data => formatDate(data.timestamp, userInfos.language));

  // Extracting fields required in chart & colors from css to customize chart
  const fields = [
    { label: 'NOTE', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color1'), },
    { label: 'NBAL', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color2'), },
    { label: 'TR', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color3'), },
    { label: 'SLA', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color4'), },
    { label: 'NoteComm', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color10'), },
    { label: 'NoteContr', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color6'), },
    { label: 'TFP', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color8'), },
    { label: 'TTP', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color7'), },
    { label: 'TFN', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color5'), },
    { label: 'TTN', color: getComputedStyle(document.documentElement).getPropertyValue('--scoring-label-color9'), },
  ];

  const [hiddenLabel, setHiddenLabel] = useState(
    {
      'NOTE': false,
      'NBAL': true,
      'TR': true,
      'SLA': true,
      'NoteComm': true,
      'NoteContr': true,
      'TFP': true,
      'TTP': true,
      'TFN': true,
      'TTN': true,
    }
  )

  // Extracting rank values for the specified keys
  let datasets = fields.map((field, index) => ({
    label: field.label,
    data: rank.map(data => data.rank[field.label]),
    borderColor: field.color,
    backgroundColor: reduceOpacity(field.color, 0.4),
    hidden: hiddenLabel[field.label],
  }));


  // Calculate the highest value of NbTotalAnalysts
  const highestNbTotalAnalysts = Math.max(...rank.map(data => data.rank.NbTotalAnalysts));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: t('statistics.scoring.chart.label-NbTotalAnalysts')
        },
        suggestedMin: 1, // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMax: highestNbTotalAnalysts, // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        reverse: true, // invert axis
        type: 'logarithmic',
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  }

  const handleActiveLabel = (label) => {
    setHiddenLabel(oldValue => ({ ...oldValue, [label]: !oldValue[label] }))
  };
  const handleClickOpenDay1 = (timestamp) => {
    setScoringPeriod('day');
    setToDateFormValue(moment(timestamp))
    setFromDateFormValue(moment(timestamp).subtract(1, 'day'));
  };

  // Define colors based on rank position
  function getColorClass(value, total) {
    if (value <= total / 3) {
      return 'cell-top-rank';
    } else if (value <= (total * 2) / 3) {
      return 'cell-middle-rank';
    } else {
      return 'cell-bottom-rank';
    }
  };

  const columnsDefinition = useMemo(
    () => [
      {
        accessorKey: 'timestamp',
        header: 'TimeStamp',
        cell: ({ cell }) => (
          <div className='buttonEditLink'>
            <button onClick={() => {
              handleClickOpenDay1(cell.getValue())
            }}
            >
              {formatDate(cell.getValue(), userInfos.language)}
            </button>
          </div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      ...fields.map(field => ({
        accessorKey: field.label,
        header: field.label,
        meta: {
          textAlign: 'center'
        },
        cell: ({ cell, row }) => (
          <div className={`cell - align ${getColorClass(cell.getValue(), row.original.NbTotalAnalysts)}`}>
            {cell.getValue() + ' / ' + row.original.NbTotalAnalysts}
          </div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
        width: '8%'
      }))
      // QLS : disable react-hooks/exhaustive-deps to remove the warning of the hook dependency requirement for handleClickOpenDay1
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [fields]
  )


  const flattenedRankData = rank.map(obj => {
    const flattenedRank = Object.entries(obj.rank).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    return {
      ...flattenedRank,
      timestamp: obj.timestamp,
      _id: obj._id
    };
  });

  return (
    <div className="scoringRankRangeContainer">
      <div className='scoringRankRangeSubContainer'>
        {fields.map((field, index) => (
          <Tooltip title={t('statistics.scoring.' + field.label + '-helper')}>
            <ToggleButton
              onClick={() => handleActiveLabel(field.label)}
              selected={!hiddenLabel[field.label]}
              value={!hiddenLabel[field.label]}
              key={index}
              variant="outlined"
              sx={{
                borderColor: field.color,
                color: field.color,
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '400',
                padding: '6px 12px',
                ':hover': {
                  borderColor: darkenColor(field.color),
                  color: darkenColor(field.color),
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                  borderColor: field.color,
                  backgroundColor: field.color,
                  // backgroundColor: reduceOpacity(field.color, 0.3),
                  color: 'white'
                },
              }}
            >
              {field.label}
            </ToggleButton>
          </Tooltip>
        ))}
      </div>
      <div className='scoringRankRangeChartContainer'>
        <LineChart labels={labels} datasets={datasets} options={options} />
      </div>
      <Table data={flattenedRankData} isLoading={loadingData} columnsDefinition={columnsDefinition} skeletonRows={5} />
    </div >
  );
}

export default ScoringStats
