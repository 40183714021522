import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, Grid, Skeleton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { RiArrowRightSLine, RiSendPlane2Line, RiDeleteBin2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import AnalystDashboard from '../Statistics/AnalystDashboard';
import { dateDisplay } from '../../utils/formatDate';
import { getUser, putUser, deleteUser } from '../../services/user.service';
import { AdminUserLanguage, AdminUserProfile } from '../../data/constants';
import { UserInfosContext } from '../../context/UserInfosContext';

function UserSingle({ clientsList, loadingData }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  let { userId } = useParams();
  const navigate = useNavigate();

  const [dialOpen, setDialOpen] = useState(false);
  const handleClickOpen = () => { setDialOpen(true); };
  const handleClose = () => { setDialOpen(false) };

  const { setSnackbarObj } = useContext(UserInfosContext);

  // react state. used for field validation and initial display
  const [userFormValues, setUserFormValues] = useState({
    displayName: '',
    email: '',
    profile: '',
  });

  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
    failureReason: failureUser,
    failureCount: failureCountUser,
    isFetching: fetchingUser
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['user', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getUser(token, userId)),
  });
  useEffect(() => {
    if (user) { setUserFormValues(user); }
  }, [user])

  // validation failed indicators
  const [userFormErrors, setUserFormErrors] = useState({
    displayName: false,
    email: false,
    profile: false,
    client_id: false,
    language: false
  });

  // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
  const [userFormTouches, setUserFormTouches] = useState({
    displayName: false,
    email: false,
    profile: false,
    client_id: false,
    language: false
  });

  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    setUserFormValues({ ...userFormValues, [name]: value })
  }
  // update the react state with user input
  // QLS :  set userFormValues.client_id's value to ['*'] when the user profile is different from 'Client'
  const handleInputChangeProfile = e => {
    const { name, value } = e.target
    setUserFormValues({ ...userFormValues, [name]: value })
    setUserFormValues(userFormValues => ({ ...userFormValues, client_id: ['*'] }));
  }
  // update the react state with user input
  // QLS :  set userFormValues.client_id's value in an array
  const handleInputChangeClient_id = e => {
    const { name, value } = e.target
    setUserFormValues({ ...userFormValues, [name]: [value] })
  }

  // update the validation infos when user leaves the field (used only on fields requiring validation)
  const handleBlur = e => {
    const { name } = e.target
    userFormValues[name] ? setUserFormErrors({ ...userFormErrors, [name]: false }) : setUserFormErrors({ ...userFormErrors, [name]: true });
    setUserFormTouches({ ...userFormTouches, [name]: true });
  }

  // submit button: enabled only when everything is alright
  function isEnabled() {
    return (
      (userFormValues.displayName && userFormValues.email && userFormValues.profile)
      &&
      (!userFormErrors.displayName && !userFormErrors.email && !userFormErrors.profile)
    )
  }

  const putUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => putUser(token, userId, userFormValues)),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { _id: userId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    },
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    putUserMutation.mutate()
  }

  const deleteUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => deleteUser(token, userId)),
    onSuccess: () => {
      // result.message === "Successfully deleted. "
      queryClient.invalidateQueries({ queryKey: ['user', { _id: userId }] }) //invalid query data, need refetch
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.deleted"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleDelete = (e) => {
    e.preventDefault()
    deleteUserMutation.mutate()
  }

  useEffect(() => {
    const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }
  }, [errorUser, failureCountUser, failureUser, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingUserData = (loadingUser && fetchingUser)


  return (
    <div className='nhub-campaign-content-container'>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/users/'>
              {/* Users */}
              {t('adminPages.userSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {userFormValues.displayName}
        </h4>
        {!userFormValues.displayName ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='nhub-campaignSingle-content'>
        <Grid container spacing={2}>
          <Grid item xs sm="auto">
            <div className="flexbox-summary">
              <div className="widget">
                <h5>{t('adminPages.userSingle.info-title')}</h5>
                <div className="summary-content">
                  <div className="summary-items summary-titles">
                    <ul>
                      <li>{t('adminPages.userSingle.info-Id')}</li>
                      <li>{t('adminPages.userSingle.info-DisplayName')}</li>
                      <li>{t('adminPages.userSingle.info-Email')}</li>
                      <li>{t('adminPages.userSingle.info-Profile')}</li>
                      {userFormValues.profile === "Client" ? <li>{t('adminPages.userSingle.info-Client')}</li> : null}
                      <li>{t('adminPages.userSingle.info-Language')}</li>
                      <li>{t('adminPages.userSingle.info-CreatedDate')}</li>
                      <li>{t('adminPages.userSingle.info-UpdateDate')}</li>
                    </ul>
                  </div>
                  <div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
                    <ul>
                      <li> {loadingUserData ? <Skeleton width={'220px'}/> : userFormValues._id}</li>
                      <li>
                        {loadingUserData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="displayName"
                            value={userFormValues.displayName}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                          ></input>
                        }
                      </li>
                      <li>
                        {loadingUserData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="email"
                            value={userFormValues.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                          ></input>
                        }
                      </li>
                      <li>
                        {loadingUserData ? <Skeleton /> :
                          <select
                            required
                            name="profile"
                            value={userFormValues.profile}
                            onChange={handleInputChangeProfile}
                          >
                            {AdminUserProfile.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      {userFormValues.profile === "Client"
                        ? <li>
                          {loadingData || loadingUserData ? <Skeleton /> :
                            <select
                              required
                              name="client_id"
                              value={userFormValues.client_id}
                              onChange={handleInputChangeClient_id}
                            >
                              {clientsList.map((option) => (
                                <option key={option._id} value={option._id}>
                                  {option.displayName}
                                </option>
                              ))}
                            </select>
                          }
                        </li>
                        : null}
                      <li>
                        {loadingUserData ? <Skeleton /> :
                          <select
                            required
                            name="language"
                            value={userFormValues.language}
                            onChange={handleInputChange}
                          >
                            {AdminUserLanguage.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      <li>{loadingUserData ? <Skeleton /> : dateDisplay(userFormValues.createdAt)}</li>
                      <li>{loadingUserData ? <Skeleton /> : dateDisplay(userFormValues.updatedAt)}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="campaignBox-3">
          <Button
            variant="contained"
            startIcon={<RiSendPlane2Line />}
            disabled={!isEnabled()}
            sx={{
              backgroundColor: "var(--button-background-6)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
              ':hover': { backgroundColor: "var(--button-background-hover-6)" }
            }}
            onClick={(e) => { handleSubmit(e) }}
          >
            {t('button.submit')}
          </Button>
          <Button
            variant="contained"
            startIcon={<RiDeleteBin2Line />}
            disabled={!isEnabled()}
            sx={{
              backgroundColor: "var(--button-background-2)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
              ':hover': { backgroundColor: "var(--button-background-hover-2)" }
            }}
            onClick={() => { handleClickOpen() }}
          >
            {t('button.delete')}
          </Button>
        </div>
      </div>
      {userFormValues.profile === "Hunter-Analyst" || userFormValues.profile === "Analyst_L1" 
      ? <AnalystDashboard/>
      : null 
      }
      <div>
        <Dialog
          fullWidth
          maxWidth='sm'
          open={dialOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* <h5>Delete ?</h5> */}
            <h5>{t('adminPages.userSingle.dialog-title')}</h5>
          </DialogTitle>
          <DialogContent>
            {t('adminPages.userSingle.dialog-content')} "{userFormValues.displayName}" ?
            <br />
            {t('adminPages.userSingle.dialog-content2')}
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => { handleDelete(e) }}>{t('button.confirm')}</Button>
            <Button onClick={handleClose}>{t('button.cancel')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
}

export default UserSingle;