
import '../../styles/Tables.css'
import '../../styles/InvestigationsPage.css'

import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { dateDisplay, priorityTranslator } from '../../utils/formatDate';


function InvestAlerts() {
  const { t } = useTranslation();
  const { alertsData, loadingInvestigationData, loadingRelatedAlertsData } = useOutletContext();

  const headers = [
    { header: t('tables.header-Name'), width: '140px' },
    { header: t('tables.header-Severity') },
    { header: t('tables.header-CreatedDate') }
  ]

  if (loadingInvestigationData || loadingRelatedAlertsData) return (
    <div className='nhub-invest-content-container'>
      <div className='investBox-alert-table'>
        <div className='tableFixHead'>
          <SkeletonTable columns={headers} rows={5} />
        </div>
      </div>
    </div>
  )

  return (<Table data={alertsData} />);
}

function Table({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Name'),
        accessor: 'name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
      },
      {
        Header: t('tables.header-Severity'),
        accessor: 'severity',
        Filter: false,
        canSorter: false,
        Cell: ({ row, cell: { value } }) => <>{priorityTranslator(value)}</>
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: false,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    [t]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='nhub-invest-content-container'>
      <div className='investBox-alert-table'>
        <div className='tableFixHead'>
          <table {...getTableProps()} >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(!data || data.length === 0)
                ? <p style={{ margin: '15px' }}>{t('investigations.investAlerts.info-noData')}</p>
                : rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr key={row.id}{...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id}{...cell.getCellProps()} >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvestAlerts;