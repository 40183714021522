import { useState, useEffect, useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { RiSave2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { Language } from '../../data/i18n/enums/Language';
import { putUserMe } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function ProfilePageGeneralInfos() {
    const language = [
        {
            value: 'en',
            label: 'English',
        },
        {
            value: 'fr',
            label: 'Français',
        },
    ];

    const { t, i18n } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

    const [lang, setLang] = useState(userInfos.language);
    const [dialOpen, setDialOpen] = useState(false);


    const handleClose = () => { setDialOpen(false) };


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (lang === 'fr') {
                i18n.changeLanguage(Language.FR);
            } else {
                i18n.changeLanguage(Language.EN);
            }
        }
        return () => mounted = false;
    }, [i18n, lang])

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setProfileFormValues(userInfos);
            setLang(userInfos.language)
        }

        return () => mounted = false;
    }, [userInfos])

    // react state. used for field validation and initial display
    const [profileFormValues, setProfileFormValues] = useState({
        displayName: '',
        email: '',
        language: ''
    });

    // validation failed indicators
    const [profileFormErrors, setProfileFormErrors] = useState({
        displayName: false,
        email: false,
        language: false,
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [profileFormTouches, setProfileFormTouches] = useState({
        displayName: false,
        email: false,
        language: false,
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setProfileFormValues({ ...profileFormValues, [name]: value });
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        profileFormValues[name] ? setProfileFormErrors({ ...profileFormErrors, [name]: false }) : setProfileFormErrors({ ...profileFormErrors, [name]: true });
        setProfileFormTouches({ ...profileFormTouches, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (profileFormValues.displayName && profileFormValues.email)
            &&
            (!profileFormErrors.displayName && !profileFormErrors.email)
        )
    }

    const putUserMeMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putUserMe(token, profileFormValues)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myUserInfo'], data) //update query data
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            if (profileFormValues.language !== lang) {
                setDialOpen(true)
            }
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        putUserMeMutation.mutate()
    }

    return (
        <>
            <div className="flexbox-summary">
                <div className="widget">
                    <h5>{t('profile.info-title')}</h5>
                    <div className="summary-content">
                        <div className="summary-items summary-titles">
                            <ul>
                                <li>{t('profile.info-DisplayName')}</li>
                                <li>{t('profile.info-Email')}</li>
                                <li>{t('profile.info-Language')}</li>
                            </ul>
                        </div>
                        <div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
                            <ul>
                                <li>
                                    <input
                                        required
                                        type="text"
                                        name="displayName"
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        value={profileFormValues.displayName}
                                    ></input>
                                </li>
                                <li>
                                    <input
                                        required
                                        type="text"
                                        name="email"
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        disabled // TODO: formalize mail changing, as it breaks email verification on Auth0 + session loop on browser
                                        value={profileFormValues.email}
                                    ></input>
                                </li>
                                <li>
                                    <select
                                        required
                                        name="language"
                                        onChange={handleInputChange}
                                        value={profileFormValues.language}
                                    >
                                        {language.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='widget-submit-btn'>
                        <Button
                            variant="contained"
                            startIcon={<RiSave2Line />}
                            disabled={!isEnabled()}
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            {t('button.save')}
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={dialOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <h5>{t('profile.alert-title')}</h5>
                </DialogTitle>
                <DialogContent>
                    {t('profile.alert-text')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        window.location.reload(false);
                    }}>
                        {t('profile.alert-button-yes')}
                    </Button>
                    <Button onClick={handleClose}>
                        {t('profile.alert-button-no')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default ProfilePageGeneralInfos