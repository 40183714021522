import React, { Component } from 'react';
import styled from 'styled-components';

import { RiArrowDropRightLine, RiArrowDropDownLine } from 'react-icons/ri';

export const PropertyName = styled.div`
  color: var(--raw-title-1);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

class ExpandableProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: !!this.props.expanded,
    };
  }

  render() {
    return (
      <>
        <PropertyName onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          {this.state.isOpen ? (
            <RiArrowDropDownLine style={{ fontSize: 'x-large', fontWeight: 'bold', marginBottom: '-7px', color: 'var(--raw-opener)' }} />
          ) : (
            <RiArrowDropRightLine style={{ fontSize: 'x-large', fontWeight: 'bold', marginBottom: '-7px', color: 'var(--raw-opener)' }} />
          )}
          {this.props.title}
        </PropertyName>
        {this.state.isOpen ? this.props.children : null}
        {React.Children.count(this.props.children) === 0 && this.state.isOpen ? 'The list is empty!' : null}
      </>
    );
  }
}

export default ExpandableProperty;
