import '../../styles/HuntsPage.css'

import { useContext } from 'react';

import { Grid } from '@mui/material';

import ProfilePageGeneralInfos from './ProfilePageGeneralInfos';
import ProfilePageApiKey from './ProfilePageApiKey';
import ProfilePageRegisterInfos from './ProfilePageRegisterInfos';
import ProfilePageNotifications from './ProfilePageNotifications';
import { UserInfosContext } from '../../context/UserInfosContext';

function ProfilePage() {

  const { userInfos } = useContext(UserInfosContext);

  return (
    <div className='nhub-profile-content-container'>
      <div className='nhub-profileSingle-content'>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          marginBottom={'10px'}
        >
          <Grid item xs={12} sm={6}>
            <ProfilePageGeneralInfos />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProfilePageApiKey />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ProfilePageNotifications />
          </Grid>
        </Grid>
        {userInfos.profile === 'Analyst_L1' || userInfos.profile.includes('Hunter')
          ? <Grid item xs={12}>
            <ProfilePageRegisterInfos />
          </Grid>
          : null
        }
      </div>
    </div>
  )
}
export default ProfilePage