import '../styles/CandidatePage.css'

import React from 'react';

import { useTranslation } from "react-i18next";

import { Link, Button } from '@mui/material';


function CandidatePage() {

  const { t } = useTranslation();

  const registerURI = process.env.REACT_APP_REGISTER_URI

  return (
    <div className='candidate-container'>

      <span className='login-form-titles'>{t('candidate.title')}</span>
      <span>{t('candidate.subTitle')}</span>
      <span>{t('candidate.subTitle2')}</span>
      <Link href={registerURI} title={t('login.button-linkRegister')} aria-label={t('login.button-linkRegister')} >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "var(--button-background-6)",
            textTransform: 'none',
            fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '30px',
            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
          }}
        >
          {t('candidate.button')}
        </Button>
      </Link>
    </div>

  );
};
export default CandidatePage;