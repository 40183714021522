import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Grid, Button, Select, MenuItem, TextField, Skeleton } from '@mui/material';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getHuntCampaign, putHuntCampaign } from '../../services/huntCampaign.service';

import { UserInfosContext } from '../../context/UserInfosContext';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function HuntersSelect({ huntCampaignsList, usersList, loadingData }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()

  const [campaignId, setCampaignId] = useState('');
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);

  const { setSnackbarObj } = useContext(UserInfosContext);


  function SortByDateList(list) {
    const sortedList = list.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0))
    return sortedList
  }

  // update the react state with user input
  const handleInputChange = e => {
    const { value } = e.target
    setCampaignId(value)
  }

  const {
    data: huntCampaign,
    isLoading: loadingHuntCampaign,
    error: errorHuntCampaign,
    failureReason: failureHuntCampaign,
    failureCount: failureCountHuntCampaign,
    isFetching: fetchingHuntCampaign
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when these conditions are met.
    queryKey: ['huntCampaign', { _id: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaign(token, campaignId)),
  });
  useEffect(() => {
    if (!!usersList) {
      if (huntCampaign) {
        setRight(usersList.filter(element => (huntCampaign.hunter_ids).includes(element._id)));
      }
      setLeft(usersList.filter(element => element.profile.includes('Hunter') && !right.includes(element)));
    }
  }, [huntCampaign, usersList, campaignId, right])

  const putHuntCampaignMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when these conditions are met.
    mutationFn: (huntersSelectedList) => getAccessTokenSilently().then((token) => putHuntCampaign(token, campaignId, { hunter_ids: huntersSelectedList })),
    onSuccess: (data) => {
      queryClient.setQueryData(['huntCampaign', { _id: campaignId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('huntCampaign', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  async function handleSave() {
    const huntersSelectedList = right.map(function (right) {
      return right._id;
    });
    putHuntCampaignMutation.mutate({ huntersSelectedList })
  }

  // submit button: enabled only when everything is alright
  function isEnabled() {
    return (
      campaignId
    )
  }

  const handleCheckedRight = () => {
    setRight(right.concat(usersList?.filter(element => (leftChecked).includes(element._id))));
    setLeft(not(left, usersList?.filter(element => (leftChecked).includes(element._id))));
    setLeftChecked([])
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(usersList?.filter(element => (rightChecked).includes(element._id))));
    setRight(not(right, usersList?.filter(element => (rightChecked).includes(element._id))));
    setRightChecked([])
  };

  const handleChangeMultipleLeft = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setLeftChecked(value);
  };
  const handleChangeMultipleRight = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setRightChecked(value);
  };

  function profileToLetter(profile) {
    switch (profile) {
      case 'Hunter-Analyst': return '(H+A) ';
      case 'Hunter': return '(H) ';
      default: return '(?) ';
    }
  }

  useEffect(() => {
    const snackbarHuntCampaign = ResponseSnackbarErrorHandler('huntCampaign', errorHuntCampaign, failureHuntCampaign, failureCountHuntCampaign)
    if (snackbarHuntCampaign) { setSnackbarObj(snackbarHuntCampaign) }
  }, [errorHuntCampaign, failureCountHuntCampaign, failureHuntCampaign, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingHuntCampaignData = (loadingHuntCampaign && fetchingHuntCampaign)

  return (
    <div className='hunterBox-container'>
      <div className='campaign-line'>
        {/* <h5>Campaign</h5> */}
        <h5>{t('adminPages.huntersSelect.head-title')}</h5>
          {loadingData ? <Skeleton variant="rounded" width={'15vw'} height={'25px'} /> :
            <TextField
              // fullWidth
              id="campaign-select"
              select
              name='campaign-select'
              variant="standard"
              onChange={handleInputChange}
              value={campaignId}
              sx={{minWidth:'15vw'}}
            >
              {SortByDateList(huntCampaignsList).map((option) => (
                option.status === 'On Hold'
                  ? <MenuItem key={option._id} value={option._id} sx={{ fontSize: 12 }}>
                    <i className='locked'>{option.name}</i>
                  </MenuItem>
                  : <MenuItem key={option._id} value={option._id} sx={{ fontSize: 12 }}>
                    {option.name}
                  </MenuItem>
              ))}
            </TextField>
          }
      </div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={5}>
          <div className='widget'>
            {/* Available Hunters */}
            <h5>{t('adminPages.huntersSelect.title-AvailableHunters')} ({left.length})</h5>
            <Select
              multiple
              native
              value={leftChecked}
              onChange={handleChangeMultipleLeft}
              sx={{
                minHeight: 'var(--select-minHeight)',
                alignItems: 'start',
                overflow: 'hidden',
                fontSize: '12px',
                fontWeight: 400,
                'select': { marginRight: '-15px', minHeight: 'var(--select-minHeight)' }, //hide scrollbar background
                ':hover': { border: 'none', },
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              {loadingHuntCampaignData
                ? <option value="skeleton" width='100%'>
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px', display: 'block' }} />
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px', display: 'block' }} />
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px', display: 'block' }} />
                  <div style={{ width: '100vw' }} />
                </option>
                : left.map((value) => (
                  <option key={value._id} value={value._id}>
                    {profileToLetter(value.profile) + value.displayName + ' <' + value.email + '>'}
                  </option>
                ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center" justifyContent="left">
            <Button
              variant="contained"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0 || !campaignId}
              aria-label="move selected right"
              sx={{
                my: 0.5,
                backgroundColor: "var(--button-background-6)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
              }}
            >
              {t('button.add')}
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0 || !campaignId}
              aria-label="move selected left"
              sx={{
                my: 0.5,
                backgroundColor: "var(--button-background-7)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                ':hover': { backgroundColor: "var(--button-background-hover-7)" }
              }}
            >
              {t('button.remove')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!isEnabled()}
              onClick={() => { handleSave() }}
              sx={{
                my: 10,
                backgroundColor: "var(--button-background-5)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                ':hover': { backgroundColor: "var(--button-background-hover-5)" }
              }}
            >
              {t('button.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div className='widget'>
            {/* Selected Hunters */}
            <h5>{t('adminPages.huntersSelect.title-SelectedHunters')} ({right.length})</h5>
            <Select
              multiple
              native
              value={rightChecked}
              onChange={handleChangeMultipleRight}
              sx={{
                minHeight: '--select-minHeight',
                alignItems: 'start',
                overflow: 'hidden',
                fontSize: '12px',
                fontWeight: 400,
                'select': { marginRight: '-15px', minHeight: 'var(--select-minHeight)' }, //hide scrollbar background
                ':hover': { border: 'none', },
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            >
              {loadingHuntCampaignData
                ? <option value="skeleton">
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px' }} />
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px' }} />
                  <Skeleton variant="rounded" animation="wave" width={'100%'} height={14} sx={{ marginBottom: '5px' }} />
                  <div style={{ width: '100vw' }} />
                </option>
                : right.map((value) => (
                  <option key={value._id} value={value._id}>
                    {profileToLetter(value.profile) + value.displayName + ' <' + value.email + '>'}
                  </option>
                ))}
            </Select>
          </div>
        </Grid>
      </Grid>
      {/* )} */}
    </div>
  );
}

export default HuntersSelect;