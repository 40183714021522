import { Skeleton } from '@mui/material';

function SkeletonInvestHistory({ rows }) {

  return (
    <div>
      <div className="history-container">
        {Array.from(Array(rows), (e, i) => {
          return (
            <div key={i} className='history-box widget'>
              <div className='avatar avatarSkeleton'>
                <Skeleton variant='img' width={50} height={50} />
              </div>
              <span className='history-user'>
                <Skeleton width={'150px'} />
              </span>
              <span className='history-description'>
                <Skeleton width={'250px'} />
              </span>
              <span className='history-date'>
                <Skeleton width={'80px'} />
              </span>
            </div>
          )
        })}
      </div>
    </div>
  );
};
export default SkeletonInvestHistory;