import * as React from 'react';
import { useTranslation } from "react-i18next";

import { useIsOverflow } from '../../hooks/useIsOverflow';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import '../../styles/AlertsPage.css'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {                  
    color: 'var(--nhub-color2)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--nhub-color-background-2)',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid var(--nhub-color2)',
    whiteSpace: 'wrap'
  },

}));

function FieldsRow(props){
  return Object.values(props.property).map((property, index) => (
    <Field
      _key={index}
      key={index}
      property={property}
      propertyName={Object.getOwnPropertyNames(props.property)[index]}
      siem_url={props.siem_url}
      timestamp={props.timestamp}
      />
  ))
}

function Field(props){
  const ref = React.useRef();
  const isOverflow = useIsOverflow(ref);
  
  return (
    isOverflow ? (
      <HtmlTooltip
        title={props.property.toString()}
        placement="bottom"
      >
        <span className='tags' ref={ref}>
          <span className='fieldTitle' key={props._key}> {(props.propertyName)} : </span>
          <span>
            {typeof props.property === 'object'
              ? JSON.stringify(props.property)
              : props.property.toString()}
          </span>
        </span>
      </HtmlTooltip>
    )
      :
      (
        <span className='tags' ref={ref}>
          <span className='fieldTitle' key={props._key}> {(props.propertyName)} : </span>
          <span>
            {typeof props.property === 'object'
              // ? <NestedFieldsRow property={(props.property)} />
              ? JSON.stringify(props.property)
              : props.property.toString()}
          </span>
        </span>
      )
  )
}

/* 
  generate a sekoia interval from rawevent timestamp
  if not provided (unexpected), fallback to current time
*/
function generateDateIntervalFromTimestamp(rawevent_timestamp = (new Date().toISOString())){

  let dateFrom = new Date(rawevent_timestamp);
  let dateTo = new Date(rawevent_timestamp);

  dateFrom.setMinutes(dateFrom.getMinutes() - 15);
  dateTo.setMinutes(dateTo.getMinutes() + 15);

  return "(from:'"+dateFrom.toISOString()+"',to:'"+dateTo.toISOString()+"')"
}

export function SekoiaFields(props) {
  const { t } = useTranslation();


  if (!props.datas) {
    // totally unexpected, user has refreshed the page, but data is not yet ready or not given by the parent (timing issue)...
    return <div className='noFields'>{t('alerts.fields.info-noData')}</div>
  }
  else if (props.datas.length === 0) {
    // render nothing
    return <div className='noFields'>{t('alerts.fields.info-noData')}</div>
  }
  else {
    return (
        
          <div className="fieldsRow">
                <FieldsRow
                  property={JSON.parse(props.datas)}
                  siem_url={props.siem_url}
                  timestamp={generateDateIntervalFromTimestamp(props.timestamp)}
                />
          </div>  
      );
  }
}

export function SekoiaDashboardURL(dashboard_url, rawevent_timestamp) {

  return dashboard_url
}