import axios from "axios";


export function getWikiPage(ruleURL) {
    return axios
        .get('https://raw.githubusercontent.com/nybble-security/sigma-rules-wiki/main' + ruleURL,
            { timeout: 5000 })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

export function getRuleURLs() {
    return axios
        .get("https://raw.githubusercontent.com/nybble-security/sigma-rules-wiki/main/id_to_pages.json",
            { timeout: 5000 })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}