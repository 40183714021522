import '../../App.css'
import '../../styles/InvestigationsPage.css'

import { useEffect, useContext } from 'react'
import { useParams, NavLink, Link, Outlet } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueries } from '@tanstack/react-query';

import { Skeleton } from '@mui/material';
import { RiArrowRightSLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getInvestigation } from '../../services/investigation.service';
import { getAlert } from '../../services/alert.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function InvestigationSingle() {
	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();

	let { investId } = useParams();

	const { setSnackbarObj } = useContext(UserInfosContext);

	const {
		data: investigation,
		isLoading: loadingInvestigation,
		error: errorInvestigation,
		failureReason: failureInvestigation,
		failureCount: failureCountInvestigation,
		isFetching: fetchingInvestigation
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!investId, // ensures the query is only executed when these conditions are met.
		queryKey: ['investigation', { _id: investId }],
		queryFn: () => getAccessTokenSilently().then((token) => getInvestigation(token, investId)),
	});

	// Retrieve alerts relating to the investigation	
	const relatedAlerts = useQueries({
		enabled: !!getAccessTokenSilently && !!investigation?.alerts,
		queries: investigation?.alerts?.map((alert) => ({
			enabled: !!getAccessTokenSilently && !!investigation?.alerts,
			queryKey: ['alert', { _id: alert }],
			queryFn: () => getAccessTokenSilently().then((token) => getAlert(token, alert)),
		})) ?? [],
	});

	// Check if any query is loading
	const anyQueryLoading = relatedAlerts.some((queryResult) => queryResult.isLoading);

	// Check if any query is fetching
	const anyQueryFetching = relatedAlerts.some((queryResult) => queryResult.isFetching);

	// Check if all queries are successful
	const allQueriesSuccessful = relatedAlerts.every((queryResult) => queryResult.isSuccess);

	// Accessing the data for each query
	const alertsDataQueryResult = relatedAlerts.map((queryResult) => queryResult.data);

	// set alertsData only when all queries are successful
	let alertsData = allQueriesSuccessful ? alertsDataQueryResult : []

	// Catches a failed query 
	const alertsDataQueryIsError = relatedAlerts.find((queryResult) => queryResult.isError);

	useEffect(() => {
		const snackbarUserInfos = ResponseSnackbarErrorHandler('investigation', errorInvestigation, failureInvestigation, failureCountInvestigation)
		if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

		const snackbarAlert = ResponseSnackbarErrorHandler('alertsList', alertsDataQueryIsError?.error, alertsDataQueryIsError?.failureReason, alertsDataQueryIsError?.failureCount)
		if (snackbarAlert) { setSnackbarObj(snackbarAlert) }

	}, [alertsDataQueryIsError?.error, alertsDataQueryIsError?.failureCount, alertsDataQueryIsError?.failureReason, errorInvestigation, failureCountInvestigation, failureInvestigation, setSnackbarObj]);

	/* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
	const loadingInvestigationData = (loadingInvestigation && fetchingInvestigation)
	const loadingRelatedAlertsData = (anyQueryLoading && anyQueryFetching)

	return (
		<div className='nhub-invest-body-container'>
			<div className="menu-title">
				<h4>
					<span><Link activeclassname={"active"} to='/investigations'>
						{/* Investigations */}
						{t('investigations.investigationSingle.head-title')}
					</Link></span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {investigation?.name}
				</h4>
				{loadingInvestigationData ? <Skeleton width={'250px'} /> : null}
			</div>
			<div className="invest-menu-tab-bar">
				<ul className='menu-tab-items'>
					<li><NavLink activeclassname="active" end to={'/investigations/' + investId} >
						{t('investigations.investigationSingle.tab-Summary')}
					</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/investigations/' + investId + '/InvestAlerts'}>
						{t('investigations.investigationSingle.tab-Alerts')} {alertsData
							? '(' + alertsData.length + ')'
							: null}
					</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/investigations/' + investId + '/InvestMitreAttack'}>
						{t('investigations.investigationSingle.tab-Mitre')}
					</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/investigations/' + investId + '/InvestHistory'}>
						{t('investigations.investigationSingle.tab-History')}
					</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/investigations/' + investId + '/InvestComments'}>
						{t('investigations.investigationSingle.tab-Comments')}
					</NavLink></li>
				</ul>
			</div>
			<div className="investBox-content">
				<Outlet context={{ investigation: investigation, alertsData: alertsData, loadingInvestigationData, loadingRelatedAlertsData }} />
			</div>
		</div>
	)
}
export default InvestigationSingle