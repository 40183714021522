import '../../styles/AlertsPage.css'
import '../../styles/InvestigationsPage.css'
import icoBlueShield from '../../assets/images/icoBlueShield.svg'

import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'

import { Grid, Skeleton } from '@mui/material';

import DownloadButton from '../../components/DownloadButton';
import { downloadReport } from '../../services/investigation.service';
import { dateDisplay, priorityTranslator } from '../../utils/formatDate';
import { useGetTechDetails } from '../../services/helpers/investigation.helper';


function InvestSummary() {
    const { t } = useTranslation();

    const { investigation, loadingInvestigationData } = useOutletContext();

    const [impact, setImpact] = useState('');
    const [resolution, setResolution] = useState('');
    const investTech = useGetTechDetails(investigation?.tech_id) || {};

    useEffect(() => {
        if (investigation) {

            switch (investigation.impact) {
                case 'WithImpact': setImpact('Yes')
                    break;
                case 'NoImpact': setImpact('No')
                    break;
                case 'NotApplicable': setImpact('N/A')
                    break;
                default: return setImpact('N/A')
            }

            switch (investigation.resolution) {
                case 'TruePositive': setResolution('True Positive')
                    break;
                case 'FalsePositive': setResolution('False Positive')
                    break;
                case 'InProgress': setResolution('In Progress')
                    break;
                case 'Other': setResolution('Other')
                    break;
                case 'Duplicated': setResolution('Duplicated')
                    break;
                case 'Indeterminate': setResolution('Undetermined')
                    break;
                default: return setResolution('Undetermined')
            }
        }
    }, [investigation])

    return (
        <div className='nhub-invest-content-container'>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs sm="auto">
                    <Grid container spacing={2} direction="column">
                        <Grid item xs>
                            <div className="flexbox-summary">
                                <div className="widget">
                                    {/* <h5>General Information</h5> */}
                                    <h5>{t('investigations.investSummary.info-title')}</h5>
                                    <div className="summary-content">
                                        <div className="summary-items summary-titles">
                                            <ul>
                                                <li>{t('investigations.investSummary.info-Status')}</li>
                                                <li>{t('investigations.investSummary.info-Priority')}</li>
                                                <li>{t('investigations.investSummary.info-Created')}</li>
                                                <li>{t('investigations.investSummary.info-Updated')}</li>
                                                <li>{t('investigations.investSummary.info-Assigned')}</li>
                                            </ul>
                                        </div>
                                        <div className="summary-items summary-values">
                                            <ul>
                                                <li>{loadingInvestigationData ? <Skeleton width={'114px'} /> : investigation?.status}</li>
                                                <li>{loadingInvestigationData ? <Skeleton /> : priorityTranslator(investigation?.priority)}</li>
                                                <li>{loadingInvestigationData ? <Skeleton /> : dateDisplay(investigation?.createdAt)}</li>
                                                <li>{loadingInvestigationData ? <Skeleton /> : dateDisplay(investigation?.updatedAt)}</li>
                                                <li>{loadingInvestigationData ? <Skeleton /> : investTech.displayName || (investigation?.tech_id ? 'unknown user' : null) || 'No technician yet'}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        {investigation?.status === 'Resolved'
                            ? <Grid item xs>
                                <div className="flexbox-summary">
                                    <div className="widget widget-invest-resolved">
                                        {/* <h5>Investigation is finished</h5> */}
                                        <h5>{t('investigations.investSummary.info-finished')}</h5>
                                        <img src={icoBlueShield} alt='icoBlueShield' className='icoBlueShield' />
                                        <div className="summary-content">
                                            <div className="summary-items summary-titles">
                                                <ul>
                                                    <li>{t('investigations.investSummary.info-Impact')}</li>
                                                    <li>{t('investigations.investSummary.info-Resolution')}</li>
                                                </ul>
                                            </div>
                                            <div className="summary-items summary-values">
                                                <ul>
                                                    <li>{impact}</li>
                                                    <li>{resolution}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        {investigation?.report
                                            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <DownloadButton
                                                    downloadFunction={downloadReport}
                                                    downloadParameters={{ investId: investigation?._id }}
                                                    label={t('button.downloadReport')}
                                                    fileName='report.pdf'
                                                />
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </Grid>
                            : null
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm>
                    <div className="widget">
                        {/* <h5>Key Findings</h5> */}
                        <h5>{t('investigations.investSummary.key-title')}</h5>
                        <div className="invest-markdown">
                            {loadingInvestigationData
                                ? <>
                                    < Skeleton variant="rounded" width={250} height={27} sx={{ margin: '0.67em 0' }} />
                                    {[...Array(10)].map((_, index) => (
                                        <Skeleton key={index} />
                                    ))}
                                </>
                                : <ReactMarkdown children={investigation?.key_findings} />
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default InvestSummary