import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React, { useState, useEffect, useContext } from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { RiFilter2Line } from 'react-icons/ri';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ValidateReport from './ValidateReport';
import { dateDisplay } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { getHuntReportPending } from '../../services/huntReport.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function ReportsTable({ data, huntCampaignsList }) {
  const { t } = useTranslation();

  const [openValidateReport, setOpenValidateReport] = useState(false);
  const [currentReport, setCurrentReport] = useState(undefined);

  const handleClickOpenValidateReport = (report) => {
    setOpenValidateReport(true);
    setCurrentReport(report)
  };


  // This is a custom filter UI for selecting campaign by id and render a name instead
  // Putting the function inside the component gives access to the huntCampaignsList to do id -> campaign name mapping
  function SelectCampaignIDColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })

      return [...options.values()]
    }, [id, preFilteredRows])

    const [isOpen, setIsOpen] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    // Render a multi-select box0
    return (
      <>
        <span
          className="filter-opener"
        >
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isFiltered ? (
              <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
            ) : (
              <RiFilter2Line />
            )}
          </IconButton>
        </span>
        {isOpen
          ?
          <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
            <div className='filter-container'>
              <Select
                MenuProps={{
                  disablePortal: true
                }}
                value={filterValue}
                onChange={e => {
                  setIsFiltered(e.target.value);
                  setFilter(e.target.value || undefined)
                }}
                sx={{
                  m: 1,
                  minWidth: 120,
                  fontSize: 12,
                  height: 24,
                  maxHeight: 24,
                  minHeight: 24,
                  backgroundColor: "var(--nhub-color-background-1)",
                }}
                variant="outlined"
                autoWidth
              >
                <MenuItem value={undefined} sx={{ fontSize: 12 }}>
                  {/* <em>All</em> */}
                  <em>{t('tables.select-all')}</em>
                </MenuItem>
                {options.map((option, i) => (
                  <MenuItem key={i} value={option} sx={{ fontSize: 12 }}>
                    <em>{(huntCampaignsList.length > 0) ? huntCampaignsList.find(element => element._id === option).name : option}</em>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </ClickAwayListener>
          : null
        }
      </>
    )
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )



  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Title'),
        accessor: 'summary', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Cell: ({ row, cell: { value } }) => (<div className='buttonEditLink'>
          <button onClick={() => { handleClickOpenValidateReport(row.original) }}
          >
            {value}
          </button>
        </div>
        )

      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
      {
        Header: t('tables.header-Campaign'),
        accessor: 'campaign_id',
        Filter: SelectCampaignIDColumnFilter,
        filter: 'includes',
        canSorter: false,
        Cell: ({ row, cell: { value } }) => huntCampaignsList.length !== 0 ? (huntCampaignsList.find(element => element._id === value)).name : <>{value}</>

      },
      {
        Header: t('tables.header-Impact'),
        accessor: 'impact',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
      },

    ],
    // QLS : disable react-hooks/exhaustive-deps to remove the warning of the hook dependency requirement for SelectCampaignIDColumnFilter
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [huntCampaignsList]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead invest-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(!data || data.length === 0)
            ? <p style={{ margin: '15px' }}>{t('adminPages.pendingWikisTable.info-noData')}</p>
            : rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {openValidateReport
        ? <div>
          <ValidateReport setOpenValidateReport={setOpenValidateReport} openValidateReport={openValidateReport} currentReport={currentReport} />
        </div>
        : null
      }
    </div>
  );
}

function PendingReportsTable({ huntCampaignsList, loadingData }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: pendingReportList,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['pendingReportList'],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntReportPending(token)),
  });

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('pendingReportList', error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }
  }, [error, failureCount, failureReason, setSnackbarObj]);

  const headers = [
    { header: t('tables.header-Title') },
    { header: t('tables.header-UpdateDate'), enableSorting:true  },
    { header: t('tables.header-Campaign'), enableColumnFilter:true  },
    { header: t('tables.header-Impact'), enableColumnFilter:true  },
  ]

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if ((isLoading && isFetching) || loadingData) {
    return (
      <div className='tableFixHead invest-table'>
        <SkeletonTable columns={headers} rows={5} />
      </div>
    )
  }

  return (<ReportsTable data={pendingReportList} huntCampaignsList={huntCampaignsList} />);
}

export default PendingReportsTable;