import React from 'react';
import RecursiveProperty from './RecursiveProperty';
import { useTranslation } from "react-i18next";

import '../../../styles/AlertsPage.css'

// https://dev.to/baso53/recursive-rendering-in-react-building-a-universal-json-renderer-f59
// https://github.com/baso53/react-json-component

// var obj2 = JSON.stringify(testJson);    // Converting JSON object to JSON string
// var obj3 = JSON.parse(obj2);            // Converting JSON string to JS object



// //return an array of values that match on a certain key
// function getValues(obj, key) {
//   var objects = [];
//   for (var i in obj) {
//       if (!obj.hasOwnProperty(i)) continue;
//       if (typeof obj[i] == 'object') {
//           objects = objects.concat(getValues(obj[i], key));
//       } else if (i == key) {
//           objects.push(obj[i]);
//       }
//   }
//   return objects;
// }

function ProcessName(isRoot,name){
  //if(isRoot)
  if(isNaN(name))
  {
    return (name + " ")
  }
  return " "
  
}

// function flattenJSON(obj = {}, res = {}, extraKey = ''){
//   const key = 0
//   for(key in obj){
//      if(typeof obj[key] !== 'object'){
//         res[extraKey + key] = obj[key];
//      }else{
//         flattenJSON(obj[key], res, `${extraKey}${key}.`);
//      };
//   };
//   return res;
// };


function RawEvent(props) {
  const { t } = useTranslation();

  if (!props.datas) {
    // totally unexpected, user has refreshed the page, but data is not yet ready or not given by the parent (timing issue)...
    return <p>{t('alerts.rawEvent.info-noData')}</p>
}
else if (props.datas.length === 0) {
    // render nothing
    return <p>{t('alerts.rawEvent.info-noData')}</p>
}
else {
  return (
  <div className="raw-event-table">
    <h5>Raw Event</h5>

      <div className="json-view">
      <RecursiveProperty
      property={JSON.parse(props.datas)} // your JSON document
      //property={flattenJSON(jsonElement)} // your JSON document flatted
      propertyName= {'Event @'+(JSON.parse(props.datas)["@timestamp"])} // root dropdown menu label
      excludeBottomBorder={false} // to include or exclude bottom border on the last entry in the list, default: false
      emptyPropertyLabel="Property is empty" // text to display for null or undefined values
      rootProperty={true} // to signify that this is the root of the document, from a user perspective always needs to be true
      propertyNameProcessor={(isRoot,name) => ProcessName(isRoot, name)}
      />
      </div>



    
  
  </div>
    
  );
    }

}
export default RawEvent;