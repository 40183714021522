import '../../App.css'
import '../../styles/AlertsPage.css'
import '../../styles/InvestigationsPage.css'

import { useEffect, useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';


import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import WikiContributionsTable from './WikiContributionsTable';
import { getAllWikiContributions } from '../../services/wikiContribution.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function WikiPage() {
	const { getAccessTokenSilently } = useAuth0();

	const { setSnackbarObj } = useContext(UserInfosContext);

	const {
		data: wikiContributionsList,
		isLoading,
		error,
		failureReason,
		failureCount,
		isFetching
	} = useQuery({
		enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
		queryKey: ['wikiContributionsList'],
		queryFn: () => getAccessTokenSilently().then((token) => getAllWikiContributions(token)),
	});

	useEffect(() => {
		const snackbar = ResponseSnackbarErrorHandler('wikiContributionsList', error, failureReason, failureCount)
		if (snackbar) { setSnackbarObj(snackbar) }

	}, [error, failureCount, failureReason, setSnackbarObj]);

	return (
		<div className='nhub-alert-body-container'>
			<WikiContributionsTable wikiContributionsList={wikiContributionsList}  loadingData={isLoading && isFetching} />
		</div>
	)
}

export default WikiPage