/*
    Everything to push / pull data from alert API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_WIKI_CONTRIBUTION_URL = `${calc_nhub_api()}/api/wiki-contribution/`

export function getAllWikiContributions(token) {
    return axios
        .get(API_WIKI_CONTRIBUTION_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Save a Wiki Contribution
export function postWikiContribution(token, content) {
    return axios
        .post(
            API_WIKI_CONTRIBUTION_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Get Pending Wiki Contribution
export function getPendingWikiContributions(token) {
    return axios
        .get(API_WIKI_CONTRIBUTION_URL + 'pending', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get a Wiki Contribution
export function getWikiContribution(token, Id) {
    return axios
        .get(API_WIKI_CONTRIBUTION_URL + Id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Edit a Wiki Contribution
export function putWikiContribution(token, Id, content) {
    return axios
        .put(
            API_WIKI_CONTRIBUTION_URL + Id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Delete a Wiki Contribution
export function deleteWikiContribution(token, Id) {
    return axios
        .delete(API_WIKI_CONTRIBUTION_URL + Id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//For admin to accept Analyst's Wiki's contribution
export function postWikiContributionAccept(token, id, validatorRating ) {
    return axios
        .post(
            API_WIKI_CONTRIBUTION_URL + id + '/accept',
            validatorRating,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//For admin to reject Analyst's Wiki's contribution with a reason explained
export function postWikiContributionReject(token, id, rejectReason) {
    return axios
        .post(
            API_WIKI_CONTRIBUTION_URL + id + '/reject',
            rejectReason,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}


