import "../../styles/Campaigns.css"

import { useEffect, useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import SkeletonCampaignItem from '../../components/SkeletonCampaignItem';
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import CampaignItem from './CampaignItem';
import { getAllHuntCampaign } from '../../services/huntCampaign.service';
import { UserInfosContext } from '../../context/UserInfosContext';


export default function Campaigns() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { setSnackbarObj } = useContext(UserInfosContext);

    const {
        data: campaigns,
        isLoading: loadingCampaigns,
        error: errorCampaigns,
        failureReason: failureCampaigns,
        failureCount: failureCountCampaigns,
    } = useQuery({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
        queryKey: ['huntCampaignsList'],
        queryFn: () => getAccessTokenSilently().then((token) => getAllHuntCampaign(token)),
    }
    );

    useEffect(() => {
        const snackbarCampaigns = ResponseSnackbarErrorHandler('huntCampaignsList', errorCampaigns, failureCampaigns, failureCountCampaigns)
        if (snackbarCampaigns) { setSnackbarObj(snackbarCampaigns) }

    }, [errorCampaigns, failureCampaigns, failureCountCampaigns, setSnackbarObj]);

    return (
        <div id="campaign-container">
            <h1>{t('hunts.campaigns.title')}</h1>
            <div className='campaign-grid'>
                {loadingCampaigns
                    ? <SkeletonCampaignItem rows={2} />
                    : <>{campaigns?.length === 0 ? <p>{t('hunts.campaigns.no-campaigns')}</p> :
                        campaigns?.map((campaign) => (
                            <CampaignItem
                                name={campaign.name}
                                prices={[campaign.price_critical, campaign.price_low, campaign.price_medium, campaign.price_high]}
                                end_date={campaign.end_date}
                                status={campaign.status}
                                id={campaign._id}
                                key={campaign._id}
                                logo={campaign.logo_url}
                            />
                        ))
                    }</>
                }
            </div>
        </div>
    )
}
