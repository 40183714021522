import { useState, useEffect, useContext } from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Alert, AlertTitle } from "@mui/material";

import Loader from '../../components/Loader';
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import AddConnectorButton from "../../components/connectors/AddConnectorButton";
import ElasticConnector from "../../components/connectors/ElasticConnector";
import SekoiaConnector from "../../components/connectors/SekoiaConnector";
// import SplunkConnector from "../../components/connectors/SplunkConnector";
// import QRadarConnector from "../../components/connectors/QRadarConnector";
import { getClientMe } from "../../services/client.service";
import { getAllConnectors } from "../../services/connector.service";
import { UserInfosContext } from '../../context/UserInfosContext';

function Connectors() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [connectorsList, setConnectorsList] = useState([]);
  const [connectorsAddList, setConnectorsAddList] = useState([]);
  const [byosClient, setBYOSClient] = useState(true);

  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: myClientInfo,
    isLoading: loadingMyClientInfo,
    error: errorMyClientInfo,
    failureReason: failureMyClientInfo,
    failureCount: failureCountMyClientInfo,
    isFetching: fetchingMyClientInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['myClientInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getClientMe(token)),
  });
  useEffect(() => {
    if (myClientInfo?.instance === 'HUB+DETECT') {
      setBYOSClient(false);
    }
  }, [myClientInfo])

  const {
    data: connectors,
    isLoading: loadingConnectors,
    error: errorConnectors,
    failureReason: failureConnectors,
    failureCount: failureCountConnectors,
    isFetching: fetchingConnectors
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!myClientInfo && myClientInfo.instance !== 'HUB+DETECT', // ensures the query is only executed when these conditions are met.
    queryKey: ['connectorsList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllConnectors(token)),
  });
  useEffect(() => {
    if (connectors) {
      setConnectorsList(connectors);
    }
  }, [connectors])


  function addConnector(e) {
    setConnectorsAddList(currentList => { return [...currentList, { _id: 'newConn-' + currentList.length, connector_type: e }] });
  }

  /* adding newly added connector to according state */
  function callback_addtoEditConnector(properties, idToRemove) {
    setConnectorsList(currentList => { return [...currentList, properties] }); // adding to current connectors
    setConnectorsAddList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the adding list, as it's done
  }

  function callback_cancelAdd(idToRemove) {
    setConnectorsAddList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the adding list, as it's canceled
  }

  function callback_deleteConnector(idToRemove) {
    setConnectorsList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the current connector list, as it's deleted
  }

  useEffect(() => {
    const snackbarMyClientInfo = ResponseSnackbarErrorHandler('myClientInfo', errorMyClientInfo, failureMyClientInfo, failureCountMyClientInfo)
    if (snackbarMyClientInfo) { setSnackbarObj(snackbarMyClientInfo) }

    const snackbarConnectors = ResponseSnackbarErrorHandler('connectorsList', errorConnectors, failureConnectors, failureCountConnectors)
    if (snackbarConnectors) { setSnackbarObj(snackbarConnectors) }
  }, [errorConnectors, errorMyClientInfo, failureConnectors, failureCountConnectors, failureCountMyClientInfo, failureMyClientInfo, setSnackbarObj]);

  /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if ((loadingMyClientInfo && fetchingMyClientInfo) ||
    (loadingConnectors && fetchingConnectors)
  ) return <Loader />

  return (
    <div className='nhub-alert-body-container'>
      {byosClient ? (
          <>
            <div className='agent-title'>
              <h4>{t('clientSettings.connectors.new-title')}</h4>
            </div>
            <div id="new-connectors-button-container">
              <AddConnectorButton label={t('clientSettings.connectorsButton.add-elastic')} onClick={() => addConnector('elastic')} />
              <AddConnectorButton label={t('clientSettings.connectorsButton.add-sekoia')} onClick={() => addConnector('sekoia')} />
              {/* <AddConnectorButton label={t('clientSettings.connectorsButton.add-splunk')} onClick={() => addConnector('splunk')} />
              <AddConnectorButton label={t('clientSettings.connectorsButton.add-qradar')} onClick={() => addConnector('qradar')} /> */}
            </div>
            <div id="adding-connectors" style={{ marginTop: '10px' }}>
              {
                connectorsAddList.map((connector) => {
                  switch (connector.connector_type) {
                    case 'elastic': return <ElasticConnector
                      key={connector._id} tempId={connector._id} initialState={'add'}
                      callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} />;
                    case 'sekoia': return <SekoiaConnector
                      key={connector._id} tempId={connector._id} initialState={'add'}
                      callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} />;
                    // case 'splunk': return <SplunkConnector
                    //   key={connector._id} tempId={connector._id} initialState={'add'}
                    //   callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} />;
                    // case 'qradar': return <QRadarConnector
                    //   key={connector._id} tempId={connector._id} initialState={'add'}
                    //   callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} />;
                    default: return <></>;
                  }
                })
              }
            </div>
            <div className='agent-title'>
              <h4>{t('clientSettings.connectors.existing-title')}</h4>
            </div>
            <div id="configured-connectors">
              {
                connectorsList.map((connector) => {
                  switch (connector.connector_type) {
                    case 'elastic': return <ElasticConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
                    case 'sekoia': return <SekoiaConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
                    // case 'splunk': return <SplunkConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
                    // case 'qradar': return <QRadarConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
                    default: return <></>;
                  }
                })
              }
            </div>
          </>
        ) : (
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>{t('clientSettings.connectors.byos-line1')}<br />{t('clientSettings.connectors.byos-line2')}</AlertTitle>
          </Alert>
        )
      }
    </div>
  )
}
export default Connectors