import nybbleLogo from '../../assets/nybble-logo/Nybble-Logo-White.svg'
import '../../styles/InvestigationsPage.css'

import { useOutletContext } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { GetRelativeTime, getInitials } from '../../utils/formatDate'
import SkeletonInvestHistory from '../../components/SkeletonInvestHistory'

function InvestHistory() {
    const { t } = useTranslation();

    const { investigation, loadingInvestigationData } = useOutletContext();
    const history = investigation?.history || {};

    if (loadingInvestigationData) return <SkeletonInvestHistory rows={10} />

    if (!history || history.length === 0) {
        // render nothing
        return <p>{t('investigations.investHistory.info-noData')}</p>
    } else {
        return (
            <div>
                <div className="history-container">
                    {history.slice(0).reverse().map((element, i) => (
                        <div key={i} className='history-box widget'>
                            {(element.profile === 'Admin')
                                ? (
                                    <div className='avatar'>
                                        <img src={nybbleLogo} alt='nybbleLogo' />
                                    </div>
                                ) : (
                                    <div className='avatar'>
                                        {getInitials(element.author)}
                                    </div>
                                )}
                            <span className='history-user'>
                                {element.author}
                            </span>
                            <span className='history-description'>
                                {element.content}
                            </span>
                            <span className='history-date'>
                                {GetRelativeTime(element.timestamp, t('lang'))}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default InvestHistory