import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React, { useState } from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import Button from '@mui/material/Button';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import PreviewReport from './PreviewReport';
import { dateDisplay } from '../../utils/formatDate';
import { SelectColumnFilter } from '../../components/TableFilters';


function ReportsTable({data}) {
  const { t } = useTranslation();

  const [huntFormValues, setHuntFormValues] = useState(undefined);
  const [openPreviewReport, setOpenPreviewReport] = useState(false);

  const handleClickOpenPreviewReport = (report) => {
    setHuntFormValues(report)
    setOpenPreviewReport(true);
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Title'),
        accessor: 'summary', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
        Cell: ({ row, cell: { value } }) => (
          <div className="row-title">
            <Button
              sx={{
                color: 'var(--title-1)',
                textAlign: 'left',
                textTransform: 'none',
                fontSize: '12px', fontWeight: '400', padding: '6px 10px',
                ':hover': { backgroundColor: 'transparent' }
              }}
              onClick={() => { handleClickOpenPreviewReport(row.original) }}
            >
              {value}
            </Button>
          </div>
        )

      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
      {
        Header: t('tables.header-Impact'),
        accessor: 'impact',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
      },


    ],
    [t]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      //  defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead report-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id}{...row.getRowProps()}>

                {row.cells.map(cell => {
                  return (
                    <td key={cell.id}{...cell.getCellProps()} >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {openPreviewReport
        ? <div>
          <PreviewReport huntFormValues={huntFormValues} setOpenPreviewReport={setOpenPreviewReport} openPreviewReport={openPreviewReport} />
        </div>
        : null
      }
    </div>
  );
}

function ReportsClientsTable({reports}) {
  const { t } = useTranslation();

  if (!reports || reports.length === 0) {
    // render nothing
    return <p>{t('hunts.clientSide.reportsClientsTable.info-noData')}</p>
  }
  else {
    return (<ReportsTable data={reports} />);
  }
}

export default ReportsClientsTable;