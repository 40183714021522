import '../../styles/HuntsPage.css'
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function SettingsPage() {
	const { t } = useTranslation();

	return (
		<div className='nhub-campaign-body-container'>
			<div className="invest-menu-tab-bar admin-tab">
				<ul className='menu-tab-items'>
					<li><NavLink activeclassname="active" to={'/settings/agents'}>{t('clientSettings.settingsPage.tab-Agents')}</NavLink></li>
					<li><NavLink activeclassname="active" to={'/settings/connectors'}>{t('clientSettings.settingsPage.tab-Connectors')}</NavLink></li>
				</ul>
			</div>
			<div className="campaignBox-content">
				<Outlet />
			</div>
		</div>
	)
}
export default SettingsPage