import '../styles/NotificationPreferencesSelector.css'

import { useState } from 'react'

import { useTranslation } from "react-i18next";

import { Button, ClickAwayListener, Tooltip, Checkbox, FormControlLabel } from '@mui/material';


export function NotificationPreferencesSelector({ notificationType, selectedOptions, setSelectedOptions }) {
    const { t } = useTranslation();

    const channels = [
        {
            value: 'email',
            label: 'Email',
            enabled: true
        },
        {
            value: 'nybbleHub',
            label: t('profile.notificationsPreferences-option-nybbleHub'),
            enabled: false
        },
    ];

    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    const handleHideOptions = () => {
        setShowOptions(false);
    };

    const getSelectedLabels = () => {
        if (!selectedOptions || !selectedOptions[notificationType]) {
            return '';
        }

        const selectedLabels = channels
            .filter(option => selectedOptions[notificationType][option.value])
            .map(option => option.label);

        return selectedLabels.join(', ');
    };


    return (
        <div className='notificationType-container'>
            <hr style={{ width: '100%', border: '0.5px solid #c4c4c4' }} />

            <h5>{t('profile.notificationsPreferences-' + notificationType)}</h5>
            <span className='notificationType-helper'>
                {t('profile.notificationsPreferences-' + notificationType + '-helper')}
            </span>
            <div>
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: 'none',
                        fontSize: '12px', fontWeight: '400',
                        padding: '2px 5px',
                        marginTop: '5px',
                    }}
                    onClick={toggleOptions}
                >
                    {t('profile.notificationsPreferences-notifyMe')}: {getSelectedLabels() || t('profile.notificationsPreferences-notNotify')}
                </Button>
                <div>
                    {showOptions && (
                        <ClickAwayListener onClickAway={handleHideOptions} >
                            <div className='notificationPreferencesSelector'>
                                <h5>{t('profile.notificationsPreferences-selectChanel')}</h5>
                                <hr style={{ width: '100%', border: '0.5px solid #c4c4c4' }} />
                                {channels.map((option) => (
                                    <Tooltip disableHoverListener={!!option.enabled} title={t('profile.notificationsPreferences-option-notAvailable')} placement="right">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={notificationType}
                                                    value={option.value}
                                                    checked={selectedOptions[notificationType] && selectedOptions[notificationType][option.value]}
                                                    onChange={(e) => {
                                                        const { checked, value } = e.target;
                                                        setSelectedOptions(prevOptions => ({
                                                            ...prevOptions,
                                                            [notificationType]: {
                                                                ...prevOptions[notificationType],
                                                                [value]: checked
                                                            }
                                                        }));
                                                    }}
                                                    size="small"
                                                />
                                            }
                                            label={option.label}
                                            sx={{
                                                margin: '0 5px',
                                                width: '100%',
                                                borderRadius: "5px",
                                                ':hover': { backgroundColor: "var(--mainNav-button-hover)" }
                                            }}
                                            disabled={!option.enabled}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </ClickAwayListener >
                    )}
                </div>
            </div>
        </div>
    );
};