/*
    Everything to push / pull data from alert API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_ALERTS_URL = `${calc_nhub_api()}/api/alert/`

export function getAllAlerts(token) {
    return axios
        .get(API_ALERTS_URL + 'opened', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

export function getAlert(token, alertId) {
    return axios
        .get(API_ALERTS_URL + alertId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

export function postLockAlert(token, alertId) {
    return axios
        .post(
            API_ALERTS_URL + 'lock/' + alertId,
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

export function postUnlockAlert(token, alertId) {
    return axios
        .post(
            API_ALERTS_URL + 'unlock/' + alertId,
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

export function postAlertStatus(token, alertId, objStatus) {
    return axios
        .post(
            API_ALERTS_URL + 'decide/' + alertId,
            objStatus,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

