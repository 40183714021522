import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import '../styles/LoginPage.css'
import frSelect from '../assets/images/language/fr.png'
import gbSelect from '../assets/images/language/gb.png'


import i18n from "../i18n";
import { Language } from '../data/i18n/enums/Language';
import { UserInfosContext } from '../context/UserInfosContext';

function LoginBanner() {

    const { userInfos, setUserInfos } = useContext(UserInfosContext);

    function handleLanguage(lang) {
        setUserInfos({ ...userInfos, 'language': lang })
        localStorage.setItem("handleLanguage", JSON.stringify(lang))
        if (lang === 'fr') {
            i18n.changeLanguage(Language.FR);
        } else {
            i18n.changeLanguage(Language.EN);
        }
    }

    return (
        <div className='login-banner'>
            <NavLink activeclassname="active" to='/' >
                <svg alt='Nybble' className='login-logo' id="Layer_1" version="2.0" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 758.4 178.7">
                    <path className="st0" d="M78.2,65.5,63.2,91l15,25.5h30l15-25.5-15-25.5Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M79.5.6,65.7,24,79.5,47.4H107L120.7,24,107,.6Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M13.6,45.6l9.7,25.3,26.8,3.8L67.3,53.2,57.6,27.9,30.8,24.1Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M7.7,125.1l25.8,8.2,19.7-18.6L47.1,87.9,21.3,79.7,1.6,98.3Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M66.1,179.3l22.5-15.1-2.2-27-24.8-12L39.1,140.3l2.2,27Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M145,167.5l2.3-27-22.5-15.1L100,137.3l-2.3,27,22.5,15Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M184.9,98.5,165.2,79.8,139.4,88l-6.2,26.8,19.7,18.6,25.8-8.2Z" transform="translate(-1.6 -0.6)" />
                    <path className="st0" d="M155.8,24.2,129,28l-9.7,25.3,17.1,21.5L163.3,71,173,45.7Z" transform="translate(-1.6 -0.6)" />
                    <g>
                        <path className="st1" d="M220,39.1h21.8l32.5,54.5V39.1h24.5v103H277.2L244.6,87.8v54.3H220V39.1Z" transform="translate(-1.6 -0.6)" />
                        <path className="st1" d="M349.7,107.4,317.1,39.1H343l19.2,41.2c4.3-9.1,9.7-21.9,19.3-41.2h25.4l-32.5,68.3v34.7H349.8V107.4Z" transform="translate(-1.6 -0.6)" />
                        <path className="st1" d="M422.6,39.1H459c28.6,0,35.5,17.1,35.5,30.9a28.192,28.192,0,0,1-6,17.6,29.022,29.022,0,0,1,12.2,23.3c0,14-5.5,31.2-41.6,31.2H422.7V39.1Zm24.6,40.7h10.1c10.6,0,12.5-5.9,12.5-9.7,0-4-.9-10.2-11-10.2H447.1l.1,19.9Zm0,41.6h11.5c15.1-.3,17.2-4.8,17.2-10.5,0-5.6-3.4-10.6-18.6-10.6H447.2Z" transform="translate(-1.6 -0.6)" />
                        <path className="st1" d="M519,39.1h36.4c28.6,0,35.5,17.1,35.5,30.9a28.192,28.192,0,0,1-6,17.6,29.022,29.022,0,0,1,12.2,23.3c0,14-5.5,31.2-41.6,31.2H519V39.1Zm24.6,40.7h10.1c10.6,0,12.5-5.9,12.5-9.7,0-4-.9-10.2-11-10.2H543.5l.1,19.9Zm0,41.6h11.5c15.1-.3,17.2-4.8,17.2-10.5,0-5.6-3.4-10.6-18.6-10.6H543.6v21.1Z" transform="translate(-1.6 -0.6)" />
                        <path className="st1" d="M615.4,39.1h24.5V121h36.6v21.2H615.4Z" transform="translate(-1.6 -0.6)" />
                        <path className="st1" d="M696.7,39.1h62.2V59.8H721.3V81.3h31.4V102H721.3v19.3H760V142H696.7V39.1Z" transform="translate(-1.6 -0.6)" />
                    </g>
                </svg>
                <span>Hub</span>
            </NavLink>
            <div className='select-language login-select-language'>
                <ul>
                    <li>
                        <button className={i18n.resolvedLanguage === 'fr' ? 'languageButtonIsActive' : ''} onClick={() => { handleLanguage("fr") }}>
                            <img src={frSelect} alt='Français' className='select-language-img' />
                        </button>
                    </li>
                    <li>
                        <button className={i18n.resolvedLanguage === 'en' ? 'languageButtonIsActive' : ''} onClick={() => { handleLanguage("en") }}>
                            <img src={gbSelect} alt='English' className='select-language-img' />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default LoginBanner;