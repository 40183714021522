import '../../App.css'
import '../../styles/AlertsPage.css'
import '../../styles/InvestigationsPage.css'
import '../../styles/HuntsPage.css'
import '../../styles/Wiki.css'

import { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown'
import HtmlDiff from 'htmldiff-js';
import parse from 'html-react-parser';

import { Button, TextField, Grid, Skeleton } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Rating, Typography } from '@mui/material';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';

import { RiCloseLine, RiArrowRightSLine, RiArrowGoBackLine, RiThumbUpLine, RiThumbDownLine, RiGithubLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getWikiContribution, putWikiContribution, postWikiContributionAccept, postWikiContributionReject } from '../../services/wikiContribution.service';
import { getUser } from '../../services/user.service';
import { getWikiPage } from '../../services/wiki.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function ValidateWiki() {
	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient()

	let { contributionId } = useParams();
	const navigate = useNavigate();

	const [openAlert, setOpenAlert] = useState(true);
	const [ownerName, setOwnerName] = useState('');
	const [dialOpen, setDialOpen] = useState(false);

	const [reviewRejected, setReviewRejected] = useState(undefined);

	const { setSnackbarObj } = useContext(UserInfosContext);

	// react state. used for field validation and initial display
	const [wikiFormValues, setWikiFormValues] = useState({
		wiki_page_name: '',
		status: '',
		wiki_file_path: '',
		new_version: '',
		current_version: '',
	});

	// validation failed indicators
	const [wikiFormErrors, setWikiFormErrors] = useState({
		wiki_page_name: false,
		status: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	// has user done at least one thing on the field (to avoid displaying an error at 1st form display)
	const [wikiFormTouches, setWikiFormTouches] = useState({
		wiki_page_name: false,
		status: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	const {
		data: wikiContribution,
		isLoading: loadingWikiContribution,
		error: errorWikiContribution,
		failureReason: failureWikiContribution,
		failureCount: failureCountWikiContribution,
		isFetching: fetchingWikiContribution
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		queryKey: ['wikiContribution', { _id: contributionId }],
		queryFn: () => getAccessTokenSilently().then((token) => getWikiContribution(token, contributionId)),
	});
	useEffect(() => {
		if (wikiContribution) { setWikiFormValues(wikiContribution); }
	}, [wikiContribution])

	const {
		data: user,
		// isLoading: loadingUser,
		error: errorUser,
		failureReason: failureUser,
		failureCount: failureCountUser,
		// isFetching: fetchingUser
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!wikiFormValues.owner_id, // ensures the query is only executed when these conditions are met.
		queryKey: ['user', { _id: wikiFormValues.owner_id }],
		queryFn: () => getAccessTokenSilently().then((token) => getUser(token, wikiFormValues.owner_id)),
	});
	useEffect(() => {
		if (user) { setOwnerName(user.displayName + ' <' + user.email + '>'); }
	}, [user])

	const {
		data: githubVersion,
		// isLoading: loadingGithubVersion,
		error: errorGithubVersion,
		failureReason: failureGithubVersion,
		failureCount: failureCountGithubVersion,
		// isFetching: fetchingGithubVersion
	} = useQuery({
		enabled: wikiFormValues?.wiki_file_path?.length !== 0, // ensures the query is only executed when these conditions are met.
		queryKey: ['githubVersion', { path: wikiFormValues.wiki_file_path }],
		queryFn: () => getWikiPage(wikiFormValues.wiki_file_path),
	});


	// update the react state with user input
	const handleInputChange = e => {
		const { name, value } = e.target
		setWikiFormValues({ ...wikiFormValues, [name]: value })
	}

	// update the validation infos when user leaves the field (used only on fields requiring validation)
	const handleBlur = e => {
		const { name,
			// value
		} = e.target
		wikiFormValues[name] ? setWikiFormErrors({ ...wikiFormErrors, [name]: false }) : setWikiFormErrors({ ...wikiFormErrors, [name]: true });
		setWikiFormTouches({ ...wikiFormTouches, [name]: true });
	}


	// submit button: enabled only when everything is alright
	function rejectConfirmIsEnabled() {
		return (
			(wikiFormValues.reject_reason) && (!wikiFormErrors.reject_reason)
		)
	}
	// submit button: enabled only when everything is alright
	function acceptConfirmIsEnabled() {
		const isValidValue = (value) => typeof value === 'number' && Number.isInteger(value) && value >= 1 && value <= 5 ? true : false;
		return (
			isValidValue(wikiFormValues.validator_rating)
		)
	}

	const handleClickOpenDial = (ReviewChoice) => {
		setReviewRejected(ReviewChoice);
		setDialOpen(true);
	};
	const handleCloseDial = () => { setDialOpen(false) };

	const postWikiContributionRejectMutation = useMutation({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => postWikiContributionReject(token, contributionId, { reject_reason: wikiFormValues.reject_reason })),
		onSuccess: (data) => {
			// result.status === 'Rejected'
			queryClient.setQueryData(['wikiContribution', { _id: contributionId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
			queryClient.invalidateQueries({ queryKey: ['pendingWikisList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.saved"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			handleClose(true);
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContribution', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handlePostReject = (e) => {
		e.preventDefault()
		postWikiContributionRejectMutation.mutate()
	}

	const postWikiContributionAcceptMutation = useMutation({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => postWikiContributionAccept(token, contributionId, { validator_rating: wikiFormValues.validator_rating })),
		onSuccess: (data) => {
			// result.status === 'Accepted'
			queryClient.setQueryData(['wikiContribution', { _id: contributionId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
			queryClient.invalidateQueries({ queryKey: ['pendingWikisList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.saved"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			handleClose(true);
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContribution', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handlePostAccept = (e) => {
		e.preventDefault()
		postWikiContributionAcceptMutation.mutate()
	}

	const putWikiContributionMutation = useMutation({
		enabled: !!getAccessTokenSilently && !!contributionId, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => putWikiContribution(token, contributionId, { status: 'Draft' })),
		onSuccess: (data) => {
			// result.status === 'Draft'
			queryClient.setQueryData(['wikiContribution', { _id: contributionId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
			queryClient.invalidateQueries({ queryKey: ['pendingWikisList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.saved"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			handleClose(true);
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContribution', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleBackToDraft = (e) => {
		e.preventDefault()
		putWikiContributionMutation.mutate()
	}

	const handleClose = () => { navigate('/admin/pendingWikis') };

	useEffect(() => {
		const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
		if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

		const snackbarWikiContribution = ResponseSnackbarErrorHandler('wikiContribution', errorWikiContribution, failureWikiContribution, failureCountWikiContribution)
		if (snackbarWikiContribution) { setSnackbarObj(snackbarWikiContribution) }

		const snackbarGithubVersion = ResponseSnackbarErrorHandler('githubVersion', errorGithubVersion, failureGithubVersion, failureCountGithubVersion)
		if (snackbarGithubVersion) { setSnackbarObj(snackbarGithubVersion) }

	}, [errorGithubVersion, errorUser, errorWikiContribution, failureCountGithubVersion, failureCountUser, failureCountWikiContribution, failureGithubVersion, failureUser, failureWikiContribution, setSnackbarObj]);


	return (
		<div className='nhub-campaign-content-container'>
			<div className="menu-title">
				<h4>
					<span>
						<Link activeclassname={"active"} to='/admin/pendingWikis'>
							{/* Review Wiki */}
							{t('adminPages.ValidateWiki.head-title')}
						</Link>
					</span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} />{wikiFormValues.wiki_page_name}
				</h4>
				{!wikiFormValues.wiki_page_name ? <Skeleton width={'350px'} /> : null}
			</div>
			<div className='report-form'>
				<Grid container spacing={2} direction='row' alignItems="stretch">
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiGithubLine />}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-4)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-4)" }
							}}
							href={"https://github.com/nybble-security/sigma-rules-wiki/tree/main" + wikiFormValues.wiki_file_path}
							title={"https://github.com/nybble-security/sigma-rules-wiki/tree/main" + wikiFormValues.wiki_file_path}
							target="_blank"
							rel="noopener noreferrer"
							disabled={!wikiFormValues.wiki_file_path}
						>
							{t('button.github')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiArrowGoBackLine />}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-5)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-5)" }
							}}
							onClick={(e) => { handleBackToDraft(e) }}
						>
							{t('button.backDraft')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiThumbUpLine />}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-6)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400',
								':hover': { backgroundColor: "var(--button-background-hover-6)" }
							}}
							onClick={() => { handleClickOpenDial(false); }}
						>
							{t('button.accept')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiThumbDownLine />}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-2)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-2)" }
							}}
							onClick={() => { handleClickOpenDial(true) }}
						>
							{t('button.reject')}
						</Button>
					</Grid>
				</Grid>
				<div className='nhub-campaignSingle-content'>
					{!!githubVersion && (githubVersion !== wikiFormValues.current_version)
						? <Collapse in={openAlert}>
							<Alert severity="warning"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setOpenAlert(false);
										}}
									>
										<RiCloseLine fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								<AlertTitle>{t('adminPages.ValidateWiki.alert-updated')}</AlertTitle>
							</Alert>
						</Collapse>
						: null
					}
					{ownerName ?
						<Collapse in={openAlert}>
							<Alert severity="info"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setOpenAlert(false);
										}}
									>
										<RiCloseLine fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								<AlertTitle>{t('adminPages.ValidateWiki.alert-writtenBy')} {ownerName}</AlertTitle>
							</Alert>
						</Collapse>
						: null
					}
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<div className="widget">
								{/* <h5>Changes made</h5> */}
								<h5>{t('form.wiki-changes')}</h5>
								<div className="widgetFormValuesTextArea edit_wiki_content wikiDiff" >
									{(loadingWikiContribution && fetchingWikiContribution)
										? [...Array(10)].map((_, index) => (
											<Skeleton key={index} />
										))
										: parse(HtmlDiff.execute(wikiFormValues.current_version, wikiFormValues.new_version))
									}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className='widget'>
								{/* <h5>Preview</h5> */}
								<h5>{t('form.wiki-render')}</h5>
								<div className='invest-markdown edit_wiki_content'>
									{(loadingWikiContribution && fetchingWikiContribution)
										? <>
											<Skeleton variant="rounded" width={'100%'} height={'27px'} sx={{ margin: '0.67em 0' }} />
											{[...Array(9)].map((_, index) => (
												<Skeleton key={index} />
											))}
										</>
										: <ReactMarkdown children={wikiFormValues.new_version} />
									}
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
			<Dialog
				fullWidth
				maxWidth='xs'
				open={dialOpen}
				onClose={handleCloseDial}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{reviewRejected
						? t('adminPages.ValidateWiki.dialog-title-Reject')
						: t('adminPages.ValidateWiki.dialog-title-Accept')
					}
				</DialogTitle>
				<DialogContent>
					{reviewRejected
						? <TextField
							fullWidth
							required
							name="reject_reason"
							label={t('adminPages.ValidateWiki.field-RejectReason')}
							variant="standard"
							multiline
							rows={3}
							onChange={handleInputChange}
							onBlur={handleBlur}
							value={wikiFormValues.reject_reason}
							error={wikiFormTouches.reject_reason && wikiFormErrors.reject_reason}
							helperText={wikiFormTouches.reject_reason && wikiFormErrors.reject_reason && t('form.field-down-helper')}
						/>
						: <>
							<Typography component="legend">{t('adminPages.ValidateWiki.field-ValidatorRating')}</Typography>
							<Rating
								name="validator_rating"
								value={wikiFormValues.validator_rating}
								onChange={(event, newValue) => {
									setWikiFormValues({ ...wikiFormValues, validator_rating: newValue })
								}}
							/>
						</>
					}
				</DialogContent>
				<DialogActions>
					{reviewRejected
						? <Button onClick={(e) => { handlePostReject(e) }} disabled={!rejectConfirmIsEnabled()}>{t('button.confirm')}</Button>
						: <Button onClick={(e) => { handlePostAccept(e) }} disabled={!acceptConfirmIsEnabled()}>{t('button.confirm')}</Button>
					}
					<Button onClick={handleCloseDial}>{t('button.cancel')}</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ValidateWiki
