

// Function to reduce opacity
export const reduceOpacity = (color, percent) => {
  // Convert hexadecimal color to RGB
  const rgb = color.match(/\w\w/g).map(x => parseInt(x, 16));
  // Reduce opacity by given percentage
  const rgba = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${percent})`;
  return rgba;
};

export function darkenColor(color) {
  const mixedColor = `color-mix(in srgb, ${color} 70%, black)`;
  return mixedColor;
}
