import '../styles/Body.css'

import React from 'react';
import { useTranslation } from "react-i18next";

// QLS : disable no-unused-vars to remove the warning of variable Chart, which seems unused but needed for chartjs-2
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Doughnut, Bar, Line, Radar } from 'react-chartjs-2';


export function DoughnutChart(props) {
  const { t } = useTranslation();

  return (
    <div className='chart-item'>
      {JSON.stringify(props.data) === JSON.stringify([0, 0, 0, 0])
        ? <span className='chart-noData'>{t('chart.info-noData')}</span> : null}
      <Doughnut
        data={{
          labels: [
            t('chart.label-Low'),
            t('chart.label-Medium'),
            t('chart.label-High'),
            t('chart.label-Critical'),
          ],
          datasets: [
            {
              data: props.data,
              label: props.label,
              fill: true,
              backgroundColor: [
                '#2a9d8f',
                '#e9c46a',
                '#f4a261',
                '#db6550',
              ],
              hoverOffset: 4
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              bottom: 3
            },
          }
          // plugins: {
          //   legend: {
          //     position: 'top',
          //   },
          //   title: {
          //     display: true,
          //     text: 'Severity level'
          //   }
          // }          
        }}
      />
    </div>
  )
}

export function BarChart(props) {
  const { t } = useTranslation();

  return (
    <div className='chart-item'>
      <Bar
        data={{
          labels: props.label,
          datasets: [
            {
              label: t('chart.label-Low'),
              data: props.low,
              backgroundColor: '#2a9d8f',
              borderColor: '#F2F2F2',
              borderWidth: 2
            },
            {
              label: t('chart.label-Medium'),
              data: props.medium,
              backgroundColor: '#e9c46a',
              borderColor: '#F2F2F2',
              borderWidth: 2
            },
            {
              label: t('chart.label-High'),
              data: props.high,
              backgroundColor: '#f4a261',
              borderColor: '#F2F2F2',
              borderWidth: 2
            },
            {
              label: t('chart.label-Critical'),
              data: props.critical,
              backgroundColor: '#db6550',
              borderColor: '#F2F2F2',
              borderWidth: 2
            },
          ]
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { stacked: true, },
            y: { stacked: true }
          }
        }}
      />
    </div>
  )
}

export function LineChart({ labels, datasets, options }) {
  return (
      <Line
        data={{
          labels,
          datasets
        }}
        options={options}
        // redraw={true}
        // key={Math.random()}
        // ref={chartRef} 
        // redraw 
      />
  )
}

export function RadarChart({ labels, datasets, options }) {
  return (
      <Radar
        data={{
          labels,
          datasets
        }}
        options={options}
      />
  )
}
