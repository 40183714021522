import '../../styles/InvestigationsPage.css'

import { useOutletContext } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { Skeleton } from '@mui/material';

import mitreArrow from '../../assets/images/mitreArrow.png'

import { RiTreasureMapLine, RiFolderSharedLine, RiDoorOpenLine, RiFolderLockLine, RiFolderUnknowLine, RiShareForward2Line, RiRemoteControl2Line, RiFileSearchLine, RiFolderOpenLine, RiAdminLine, RiKey2Line, RiUserSearchLine, RiFileCodeLine, RiPlayLine } from "react-icons/ri";

function renderMitreElement({ dataTable, icon, text, mitreNumber, loadingData }) {
    var usedClass = "mitre-number , mitre-number-0"
    if (dataTable.length > 0) {
        usedClass = "mitre-number , mitre-number-1"
    }
    return (
        <>
            {loadingData
                ? <div className='mitre-number'>
                    <Skeleton variant='circular' width={30} height={30} animation="wave" />
                </div>
                : <div className={usedClass}>
                    {dataTable.length}
                </div>
            }
            <a
                href={"https://attack.mitre.org/tactics/" + mitreNumber}
                title="more infos on Mitre website"
                target="_blank"
                rel="noopener noreferrer"
            >
                {icon}
            </a>
            <span>{text}</span>
        </>
    )
}

function InvestMitreAttack() {
    const { t } = useTranslation();

    const { investigation, loadingInvestigationData } = useOutletContext();
    const mitre = investigation?.mitre;

    return (
        <div className='nhub-mitre-container'>
            <div className="mitreBox-header">
                <img src={mitreArrow} alt='mitreArrow' className='mitreArrow' />
                <span className='criticality-span'>{t('investigations.investMitre.chart-title')}</span>
            </div>
            <div className="mitreBox-body">
                <div className="mitreBox-col1">
                    {/* Col 0 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'reconnaissance'),
                                icon: <RiUserSearchLine />,
                                // text: 'Reconnaissance'
                                text: t('investigations.investMitre.mitre-Reconnaissance'),
                                mitreNumber: 'TA0043'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 2 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'resource-development'),
                                icon: <RiFileCodeLine />,
                                // text: 'Resource Development'
                                text: t('investigations.investMitre.mitre-ResourceDevelopment'),
                                mitreNumber: 'TA0042'
                            })
                        }
                    </div>
                </div>
                <div className="mitreBox-col1">
                    {/* Col 1 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'initial-access'),
                                icon: <RiFolderOpenLine />,
                                // text: 'Initial Access'
                                text: t('investigations.investMitre.mitre-InitialAccess'),
                                mitreNumber: 'TA0001'
                            })
                        }
                    </div>
                </div>
                <div className="mitreBox-col1">
                    {/* Col 2 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'execution'),
                                icon: <RiPlayLine />,
                                // text: 'Execution'
                                text: t('investigations.investMitre.mitre-Execution'),
                                mitreNumber: 'TA0002'
                            })
                        }
                    </div>
                </div>
                <div className="mitreBox-col1">
                    {/* Col 3 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'persistence'),
                                icon: <RiDoorOpenLine />,
                                // text: 'Persistence'
                                text: t('investigations.investMitre.mitre-Persistence'),
                                mitreNumber: 'TA0003'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 2 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'privilege-escalation'),
                                icon: <RiKey2Line />,
                                // text: 'Privilege escalation'
                                text: t('investigations.investMitre.mitre-PrivilegeEscalation'),
                                mitreNumber: 'TA0004'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 3 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'defense-evasion'),
                                icon: <RiFolderLockLine />,
                                // text: 'Defense Evasion'
                                text: t('investigations.investMitre.mitre-DefenseEvasion'),
                                mitreNumber: 'TA0005'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 4 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'credential-access'),
                                icon: <RiAdminLine />,
                                // text: 'Credential Access'
                                text: t('investigations.investMitre.mitre-CredentialAccess'),
                                mitreNumber: 'TA0006'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 5 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'discovery'),
                                icon: <RiTreasureMapLine />,
                                // text: 'Discovery'
                                text: t('investigations.investMitre.mitre-Discovery'),
                                mitreNumber: 'TA0007'
                            })
                        }
                    </div>
                </div>
                <div className="mitreBox-col1">
                    {/* Col 4 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'lateral-movement'),
                                icon: <RiShareForward2Line />,
                                // text: 'Lateral Movement'
                                text: t('investigations.investMitre.mitre-LateralMovement'),
                                mitreNumber: 'TA0008'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 2 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'collection'),
                                icon: <RiFileSearchLine />,
                                // text: 'Collection'
                                text: t('investigations.investMitre.mitre-Collection'),
                                mitreNumber: 'TA0009'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 3 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'command-and-control'),
                                icon: <RiRemoteControl2Line />,
                                // text: 'Command and Control'
                                text: t('investigations.investMitre.mitre-CommandControl'),
                                mitreNumber: 'TA0011'
                            })
                        }
                    </div>
                </div>
                <div className="mitreBox-col1">
                    {/* Col5 */}
                    <div className="mitreBox-row1">
                        {/* Row 1 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'exfiltration'),
                                icon: <RiFolderSharedLine />,
                                // text: 'Exfiltration'
                                text: t('investigations.investMitre.mitre-Exfiltration'),
                                mitreNumber: 'TA0010'
                            })
                        }
                    </div>
                    <div className="mitreBox-row1">
                        {/* Row 2 */}
                        {
                            renderMitreElement({
                                loadingData: loadingInvestigationData,
                                dataTable: loadingInvestigationData ? [] : mitre.filter(mitreEntry => mitreEntry.tactic === 'impact'),
                                icon: <RiFolderUnknowLine />,
                                // text: 'Impact'
                                text: t('investigations.investMitre.mitre-Impact'),
                                mitreNumber: 'TA0040'
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default InvestMitreAttack