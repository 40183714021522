import '../../App.css'
import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'
import { useParams, Link, useNavigate } from "react-router-dom"

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TextField, MenuItem, Grid, Button, Alert, AlertTitle, IconButton, Collapse, Skeleton } from '@mui/material';
import { CircularProgress, Box } from '@mui/material';

import { RiSendPlaneLine, RiSave2Line, RiEyeLine, RiCloseLine, RiArrowRightSLine, RiFileCopy2Line } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import PreviewReport from './PreviewReport';
import ValidateReport from '../Admin/ValidateReport';
import InfoTooltip from '../../data/tooltip';
import { getHuntReport, putHuntReport, postHuntReport } from '../../services/huntReport.service';
import { getHuntCampaign } from '../../services/huntCampaign.service.js';
import { getUser } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';
import { BreakLineRender } from '../../utils/formatText'

function EditReport({ reportMode, campaignDetails, setOpenCreateReport }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    let { campaignId, reportId } = useParams();
    const navigate = useNavigate();

    const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

    const [validatorName, setValidatorName] = useState('');
    const [openAlert, setOpenAlert] = useState(true);
    const [openPreviewReport, setOpenPreviewReport] = useState(false)
    const [openValidateReport, setOpenValidateReport] = useState(userInfos.profile === 'Admin')
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)

    const impactList = [
        {
            value: 'Low',
            label: t('form.impact-Low'),
        },
        {
            value: 'Medium',
            label: t('form.impact-Medium'),
        },
        {
            value: 'High',
            label: t('form.impact-High'),
        },
        {
            value: 'Critical',
            label: t('form.impact-Critical'),
        },
    ];

    // react state. used for field validation and initial display
    const [huntFormValues, setHuntFormValues] = useState({
        campaign_id: (reportMode === 'Create' ? campaignDetails._id : ''),
        summary: '**fill here**',
        description: '**fill here**',
        details: '**fill here**',
        proofs: '**fill here**',
        ioc: '**fill here**',
        cve: '**fill here**',
        vulnerability: '**fill here**',
        affected_systems: '**fill here**',
        remediation: '**fill here**',
        impact: impactList[0].value,
        status: 'Draft'
    });

    // validation failed indicators
    const [huntFormErrors, setHuntFormErrors] = useState({
        summary: false,
        description: false,
        details: false,
        proofs: false,
        ioc: false,
        cve: false,
        vulnerability: false,
        affected_systems: false,
        remediation: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [huntFormTouches, setHuntFormTouches] = useState({
        summary: false,
        description: false,
        details: false,
        proofs: false,
        ioc: false,
        cve: false,
        vulnerability: false,
        affected_systems: false,
        remediation: false
    });

    const {
        data: report,
        isLoading: loadingReport,
        error: errorReport,
        failureReason: failureReport,
        failureCount: failureCountReport,
        isFetching: fetchingReport
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!reportId && reportMode !== 'Create', // ensures the query is only executed when getAccessTokenSilently and campaignId are available.
        queryKey: ['huntReport', { _id: reportId }],
        queryFn: () => getAccessTokenSilently().then((token) => getHuntReport(token, reportId)),
    }
    );

    useEffect(() => {
        if (report) {
            setHuntFormValues(report);
        }
    }, [report])

    const {
        data: user,
        // isLoading: loadingUser,
        error: errorUser,
        failureReason: failureUser,
        failureCount: failureCountUser,
        // isFetching: fetchingUser
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!huntFormValues.validator_id, // ensures the query is only executed when these conditions are met.
        queryKey: ['user', { _id: huntFormValues.validator_id }],
        queryFn: () => getAccessTokenSilently().then((token) => getUser(token, huntFormValues.validator_id)),
    });
    useEffect(() => {
        if (user) { setValidatorName(user.displayName + ' <' + user.email + '>'); }
    }, [user])


    const {
        data: campaign,
        // isLoading: loadingCampaign,
        error: errorCampaign,
        failureReason: failureCampaign,
        failureCount: failureCountCampaign,
        // isFetching: fetchingCampaign
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!huntFormValues?.campaign_id, // ensures the query is only executed when these conditions are met.
        queryKey: ['huntCampaign', { _id: huntFormValues?.campaign_id }],
        queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaign(token, huntFormValues?.campaign_id)),
    });


    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setHuntFormValues({ ...huntFormValues, [name]: value })
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        huntFormValues[name] && huntFormValues[name].length > 2 ? setHuntFormErrors({ ...huntFormErrors, [name]: false }) : setHuntFormErrors({ ...huntFormErrors, [name]: true });
        setHuntFormTouches({ ...huntFormTouches, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (huntFormValues.summary &&
                huntFormValues.description &&
                huntFormValues.impact &&
                huntFormValues.details &&
                huntFormValues.proofs &&
                huntFormValues.ioc &&
                huntFormValues.cve &&
                huntFormValues.vulnerability &&
                huntFormValues.affected_systems &&
                huntFormValues.remediation
            )
            &&
            (huntFormValues.summary?.length > 2 &&
                huntFormValues.description?.length > 2 &&
                huntFormValues.details?.length > 2 &&
                huntFormValues.proofs?.length > 2 &&
                huntFormValues.ioc?.length > 2 &&
                huntFormValues.cve?.length > 2 &&
                huntFormValues.vulnerability?.length > 2 &&
                huntFormValues.affected_systems?.length > 2 &&
                huntFormValues.remediation?.length > 2
            )
            &&
            (!huntFormErrors.summary &&
                !huntFormErrors.description &&
                !huntFormErrors.details &&
                !huntFormErrors.proofs &&
                !huntFormErrors.ioc &&
                !huntFormErrors.cve &&
                !huntFormErrors.vulnerability &&
                !huntFormErrors.affected_systems &&
                !huntFormErrors.remediation
            )
            &&
            (huntFormValues.status === 'Draft')
            &&
            (campaign?.status === 'In Progress')
        )
    }

    const postReportMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => postHuntReport(token, content)),
        onSuccess: (data) => {
            // queryClient.setQueryData(['huntReport', { _id: data.report }], data) //update query data
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            navigate('/campaigns/' + campaignDetails._id + '/report/' + data.report)
            setLoadingSave(false)
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntReport', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const putReportMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!reportId, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putHuntReport(token, reportId, huntFormValues)),
        onSuccess: (data) => {
            queryClient.setQueryData(['huntReport', { _id: reportId }], data) //update query data 
            setSnackbarObj({
                message: data.status === 'Pending Review' ? t("snackbar.submitted") : t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            if (data.status === 'Pending Review') {
                navigate('/campaigns/' + campaignId)
            }
            setLoadingSave(false)
            setLoadingSubmit(false)
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntReport', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSave = (e) => {
        e.preventDefault()
        setLoadingSave(true)
        if (reportMode === 'Create') {
            postReportMutation.mutate({
                huntFormValues: huntFormValues
            })
        } else {
            putReportMutation.mutate({
                huntFormValues: huntFormValues
            })
        }
    }

    const handleDuplicate = (e) => {
        e.preventDefault()
        postReportMutation.mutate({
             huntFormValues
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoadingSubmit(true)
        huntFormValues.status = 'Pending Review'
        putReportMutation.mutate({ huntFormValues })
    }

    useEffect(() => {

        const snackbarCampaign = ResponseSnackbarErrorHandler('huntCampaign', errorCampaign, failureCampaign, failureCountCampaign)
        if (snackbarCampaign) { setSnackbarObj(snackbarCampaign) }

        const snackbarReport = ResponseSnackbarErrorHandler('huntReport', errorReport, failureReport, failureCountReport)
        if (snackbarReport) { setSnackbarObj(snackbarReport) }

        const snackbarUser = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
        if (snackbarUser) { setSnackbarObj(snackbarUser) }

    }, [errorCampaign, errorReport, errorUser, failureCampaign, failureCountCampaign, failureCountReport, failureCountUser, failureReport, failureUser, setSnackbarObj]);

    /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
    const loadingHuntReportData = (loadingReport && fetchingReport)

    return (
        <div className='report-container'>
            <div className="menu-title">
                {!campaign?.name ? <Skeleton width={'250px'} /> : null}
                <h4>
                    <span>
                        {reportMode === 'Create' ? (
                            <Link activeclassname={"active"} to={'/campaigns/' + campaignId} onClick={() => setOpenCreateReport(false)}>{campaignDetails.name}</Link>
                        ) : (
                            userInfos.profile === 'Admin'
                                ? <Link activeclassname={"active"} to={'/admin/campaign/' + campaignId}>{campaign?.name}</Link>
                                : <Link activeclassname={"active"} to={'/campaigns/' + campaignId}>{campaign?.name}</Link>
                        )}
                    </span>
                    <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} />
                    {reportMode === 'Create' ? t('hunts.createReport.head-subtitle') : t('hunts.editReport.head-subtitle')}
                </h4>
            </div>
            {openValidateReport && huntFormValues.status === 'Pending Review' && !loadingSubmit
                ? <ValidateReport setOpenValidateReport={setOpenValidateReport} openValidateReport={openValidateReport} currentReport={huntFormValues} />
                : null
            }
            {huntFormValues
                ? <>
                    <PreviewReport huntFormValues={huntFormValues} setOpenPreviewReport={setOpenPreviewReport} openPreviewReport={openPreviewReport} />
                    <div className='report-form'>
                        {!loadingSubmit && !loadingHuntReportData
                            ? <>
                                {huntFormValues.status === 'Accepted' && validatorName
                                    ? <Collapse in={openAlert}>
                                        <Alert severity="success"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <RiCloseLine fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {/* <AlertTitle>This report has been accepted by {validatorName}</AlertTitle> */}
                                            <AlertTitle>{t('hunts.editReport.alert-title-accepted')} {validatorName}</AlertTitle>
                                            {huntFormValues.price}{t('hunts.editReport.alert-title-accepted2')}
                                        </Alert>
                                    </Collapse>
                                    : null
                                }
                                {huntFormValues.status === 'Rejected' && validatorName
                                    ? <Collapse in={openAlert}>
                                        <Alert severity="error"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <RiCloseLine fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {/* <AlertTitle>This report has been rejected by {validatorName}</AlertTitle> */}
                                            <AlertTitle>{t('hunts.editReport.alert-title-rejected')} {validatorName}</AlertTitle>
                                            {t('hunts.editReport.alert-title-rejected2')} <BreakLineRender content={huntFormValues.reject_reason} />
                                        </Alert>
                                    </Collapse>
                                    : null
                                }
                                {huntFormValues.status === 'Pending Review'
                                    ? <Collapse in={openAlert}>
                                        <Alert severity="info"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <RiCloseLine fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {/* <AlertTitle>This report is pending review.</AlertTitle> */}
                                            <AlertTitle>{t('hunts.editReport.alert-title-pending')}</AlertTitle>
                                            {t('hunts.editReport.alert-title-pending2')}
                                        </Alert>
                                    </Collapse>
                                    : null
                                }
                                {campaign?.status === 'Not Started' || campaign?.status === 'Finished' || campaign?.status === 'On Hold'
                                    ? <Collapse in={openAlert}>
                                        <Alert severity="error"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAlert(false);
                                                    }}
                                                >
                                                    <RiCloseLine fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {/* <AlertTitle>This campaign is not active.</AlertTitle> */}
                                            <AlertTitle>{t('hunts.editReport.alert-title-campaignNotInProgress')}</AlertTitle>
                                            {t('hunts.editReport.alert-title-campaignNotInProgress2')}
                                        </Alert>
                                    </Collapse>
                                    : null
                                }
                            </>
                            : null
                        }
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Grid item xs='auto' >
                                <div className='report-button-container'>
                                    <Button
                                        className='submit-button'
                                        variant="contained"
                                        type="submit"
                                        startIcon={<RiEyeLine />}
                                        sx={{
                                            backgroundColor: "var(--button-background-5)",
                                            textTransform: 'none',
                                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                            ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                                        }}
                                        onClick={() => { setOpenPreviewReport(true) }}
                                        disabled={loadingHuntReportData}
                                    >
                                        {/* Preview */}
                                        {t('button.preview')}
                                    </Button>
                                    <Button
                                        className='submit-button'
                                        variant="contained"
                                        type="submit"
                                        startIcon={<RiFileCopy2Line />}
                                        sx={{
                                            backgroundColor: "var(--button-background-7)",
                                            textTransform: 'none',
                                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginTop: '10px',
                                            ':hover': { backgroundColor: "var(--button-background-hover-7)" }
                                        }}
                                        disabled={loadingHuntReportData}
                                        onClick={(e) => { handleDuplicate(e) }}
                                    >
                                        {/* Duplicate */}
                                        {t('button.duplicate')}
                                    </Button>
                                    <Box sx={{ position: 'relative', display: 'inline' }}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            startIcon={<RiSave2Line />}
                                            disabled={!isEnabled() || loadingSave}
                                            sx={{
                                                backgroundColor: "var(--button-background-6)",
                                                textTransform: 'none',
                                                fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '10px 0',
                                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                            }}
                                            onClick={(e) => { handleSave(e) }}
                                        >
                                            {/* Save */}
                                            {t('button.save')}
                                        </Button>
                                        {(loadingSave) && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "var(--button-background-6)",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Box>
                                    {reportMode !== 'Create'
                                        ? <Box sx={{ position: 'relative', display: 'inline' }}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                type="submit"
                                                startIcon={<RiSendPlaneLine />}
                                                disabled={!isEnabled() || loadingSubmit}
                                                sx={{
                                                    backgroundColor: "var(--button-background-4)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                                                }}
                                                onClick={(e) => { handleSubmit(e) }}
                                            >
                                                {/* Submit */}
                                                {t('button.submit')}
                                            </Button>
                                            {(loadingSubmit) && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: "var(--button-background-6)",
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        : null
                                    }

                                </div>
                            </Grid>
                            <Grid item xs sm={4} md={4}>
                                <div className="widget report-form-widget">
                                    {/* <h5>Report Title</h5> */}
                                    <h5>{t('form.report-title')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.summary')} />
                                    {loadingHuntReportData
                                        ? <Skeleton />
                                        : <TextField
                                            fullWidth
                                            required
                                            name="summary"
                                            variant="standard"
                                            multiline
                                            rows={7}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.summary}
                                            error={huntFormTouches.summary && huntFormErrors.summary}
                                            helperText={huntFormTouches.summary && huntFormErrors.summary && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.summary ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm md >
                                <div className="widget report-form-widget">
                                    {/* <h5>Description</h5> */}
                                    <h5>{t('form.report-description')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.description')} />
                                    {loadingHuntReportData
                                        ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            required
                                            name="description"
                                            variant="standard"
                                            multiline
                                            rows={7}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.description}
                                            error={huntFormTouches.description && huntFormErrors.description}
                                            helperText={huntFormTouches.description && huntFormErrors.description && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.description ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm='auto' >
                                <div className="widget report-form-widget">
                                    {/* <h5>Impact</h5> */}
                                    <h5>{t('form.report-impact')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.impact')} />
                                    {loadingHuntReportData ? <Skeleton /> :
                                        <TextField
                                            fullWidth
                                            required
                                            select
                                            name="impact"
                                            variant="standard"
                                            value={huntFormValues.impact}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            key={`TextField-${huntFormValues.impact}`}
                                            defaultValue={huntFormValues.impact}
                                            InputLabelProps={huntFormValues.impact ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        >
                                            {impactList.map((option) => (
                                                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px', fontWeight: '400' }}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="widget report-form-widget">
                                    {/* <h5>Details / Requests / Steps</h5> */}
                                    <h5>{t('form.report-details')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.details')} />
                                    {loadingHuntReportData
                                        ? [...Array(5)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            required
                                            name="details"
                                            variant="standard"
                                            multiline
                                            rows={17}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.details}
                                            error={huntFormTouches.details && huntFormErrors.details}
                                            helperText={huntFormTouches.details && huntFormErrors.details && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.summary ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="widget report-form-widget">
                                    {/* <h5>Supporting Materials / Proofs</h5> */}
                                    <h5>{t('form.report-proofs')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.proofs')} />
                                    {loadingHuntReportData
                                        ? [...Array(5)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            required
                                            name="proofs"
                                            variant="standard"
                                            multiline
                                            rows={17}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.proofs}
                                            error={huntFormTouches.proofs && huntFormErrors.proofs}
                                            helperText={huntFormTouches.proofs && huntFormErrors.proofs && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.summary ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="widget report-form-widget">
                                    {/* <h5>IOC</h5> */}
                                    <h5>{t('form.report-ioc')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.ioc')} />
                                    {loadingHuntReportData
                                        ? <Skeleton />
                                        : <TextField
                                            fullWidth
                                            name="ioc"
                                            variant="standard"
                                            multiline
                                            rows={3}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.ioc}
                                            error={huntFormTouches.ioc && huntFormErrors.ioc}
                                            helperText={huntFormTouches.ioc && huntFormErrors.ioc && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.ioc ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="widget report-form-widget">
                                    {/* <h5>CVE</h5> */}
                                    <h5>{t('form.report-cve')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.cve')} />
                                    {loadingHuntReportData
                                        ? <Skeleton />
                                        : <TextField
                                            fullWidth
                                            name="cve"
                                            variant="standard"
                                            multiline
                                            rows={3}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.cve}
                                            error={huntFormTouches.cve && huntFormErrors.cve}
                                            helperText={huntFormTouches.cve && huntFormErrors.cve && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.cve ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="widget report-form-widget">
                                    {/* <h5>Vulnerabilities</h5> */}
                                    <h5>{t('form.report-vulnerability')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.vulnerability')} />
                                    {loadingHuntReportData
                                        ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            name="vulnerability"
                                            variant="standard"
                                            multiline
                                            rows={3}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.vulnerability}
                                            error={huntFormTouches.vulnerability && huntFormErrors.vulnerability}
                                            helperText={huntFormTouches.vulnerability && huntFormErrors.vulnerability && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.vulnerability ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="widget report-form-widget">
                                    {/* <h5>Affected Systems</h5> */}
                                    <h5>{t('form.report-affectedSystems')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.affected_systems')} />
                                    {loadingHuntReportData
                                        ? [...Array(4)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            required
                                            name="affected_systems"
                                            variant="standard"
                                            multiline
                                            rows={10}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            InputLabelProps={huntFormValues.summary ? { shrink: true } : null}
                                            value={huntFormValues.affected_systems}
                                            error={huntFormTouches.affected_systems && huntFormErrors.affected_systems}
                                            helperText={huntFormTouches.affected_systems && huntFormErrors.affected_systems && t('form.field-down-helper')}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div className="widget report-form-widget">
                                    {/* <h5>Remediation</h5> */}
                                    <h5>{t('form.report-remediation')} </h5>
                                    <InfoTooltip text={t('hunts.tooltip.remediation')} />
                                    {loadingHuntReportData
                                        ? [...Array(4)].map((_, index) => (<Skeleton key={index} />))
                                        : <TextField
                                            fullWidth
                                            name="remediation"
                                            variant="standard"
                                            multiline
                                            rows={10}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            value={huntFormValues.remediation}
                                            error={huntFormTouches.remediation && huntFormErrors.remediation}
                                            helperText={huntFormTouches.remediation && huntFormErrors.remediation && t('form.field-down-helper')}
                                            InputLabelProps={huntFormValues.remediation ? { shrink: true } : null}
                                            InputProps={{ readOnly: huntFormValues.status === 'Draft' ? false : true, }}
                                        />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
                : null
            }
        </div >
    )
}

export default EditReport