import { useTranslation } from "react-i18next";

import '../../styles/AlertsPage.css'

function FieldsTable({ data }) {
  return (
    <table className="summary-table">
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td className="summary-titles">{key}</td>
            <td className="summary-values">
              {typeof value === 'object' ? JSON.stringify(value) : value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function CustomFields({ data }) {
  const { t } = useTranslation();

  if (!data || data.length === 0) { return null }
  else {
    return (
      <div>
        <h1> {t('alerts.wiki.additional-info')} </h1>
        <FieldsTable data={JSON.parse(data)} />
      </div>
    );
  }
}
export default CustomFields;