/*
    Everything to push / pull data from client API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_CLIENT_URL = `${calc_nhub_api()}/api/client/`

//Get all clients
export function getAllClient(token) {
    return axios
        .get(API_CLIENT_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get a client by his ID
export function getClient(token, clientId) {
    return axios
        .get(API_CLIENT_URL + clientId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get my client data
export function getClientMe(token) {
    return axios
        .get(API_CLIENT_URL + '/me', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Add a client
export function postClient(token, content) {
    return axios
        .post(
            API_CLIENT_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Edit a client
export function putClient(token, id, content) {

    return axios
        .put(
            API_CLIENT_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Delete a client by his ID
export function deleteClient(token, id) {
    return axios
        .delete(API_CLIENT_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

// Download a config
export function downloadConfig({ token, clientId, config }) {
    return axios
        .get(API_CLIENT_URL + '/' + clientId + '/config/' + config, {
            responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Add a client operation
export function postClientOperation(token, clientId, content) {
    return axios
        .post(
            API_CLIENT_URL + clientId + '/account_operation',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}
