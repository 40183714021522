import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { dateDisplay } from '../../utils/formatDate';
import { DefaultColumnFilter } from '../../components/TableFilters';


function ClientUsersTable({ users, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { header: t('tables.header-Name'), enableColumnFilter: true },
    { header: t('tables.header-Email') },
    { header: t('tables.header-Id'), width: '140px' },
    { header: t('tables.header-CreatedDate'), enableSorting: true },
    { header: t('tables.header-UpdateDate'), enableSorting: true },
  ]

  if (loadingData) return (
    <div className='tableFixHead report-table'>
      <SkeletonTable columns={headers} rows={3} />
    </div>
  )

  if (!users || users.length === 0) {
    return <p>{t('adminPages.clientUsersTable.info-noData')}</p>
  } else {
    return (<Table data={users} />);
  }
}


function Table({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


  const columns = React.useMemo(
    () => [

      {
        Header: t('tables.header-Name'),
        accessor: 'displayName',
        canSorter: false,
        Filter: DefaultColumnFilter,
      },
      {
        Header: t('tables.header-Email'),
        accessor: 'email',
        canSorter: false,
        Filter: false,
      },
      {
        Header: t('tables.header-Id'),
        accessor: '_id',
        canSorter: false,
        Filter: false,
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,

      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,

      },
    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead report-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(!data || data.length === 0)
            ? <p style={{ margin: '15px' }}>{t('adminPages.pendingWikisTable.info-noData')}</p>
            : rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default ClientUsersTable;