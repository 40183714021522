import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './utils/history';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


const queryClient = new QueryClient({
  // retry: 2, // number of retries for failed requests (custom logic based on why the request failed).
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true (refetch on every windows focus).
      retry: (failureCount, error) => {
        // Retry 2 times, only if the error message includes 'timeout'
        return failureCount <= 2 && error.message.includes('timeout');
      },
    },
  }
})

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <Auth0ProviderWithHistory>
      <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools /> 
      </QueryClientProvider>
    </Auth0ProviderWithHistory>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


// import React from 'react';
// import { render } from 'react-dom';

// import { App } from './components/App';

// // setup fake backend
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();

// render(
//     <App />,
//     document.getElementById('app')
// );