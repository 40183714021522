import { useState, useEffect, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import AddConnectorButton from "../../components/connectors/AddConnectorButton";
import ElasticConnector from "../../components/connectors/ElasticConnector";
import SekoiaConnector from "../../components/connectors/SekoiaConnector";
// import SplunkConnector from "../../components/connectors/SplunkConnector";
// import QRadarConnector from "../../components/connectors/QRadarConnector";
import { getAllConnectors } from "../../services/connector.service";
import { UserInfosContext } from '../../context/UserInfosContext';

function ClientConnectors({ client_id }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [connectorsList, setConnectorsList] = useState([]);
  const [connectorsAddList, setConnectorsAddList] = useState([]);

  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: connectors,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['connectorsList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllConnectors(token)),
  });
  useEffect(() => {
    if (connectors) {
      // filtering connectors from client_id only
      setConnectorsList(connectors.filter(connector => connector.client_id === client_id));
    }
  }, [client_id, connectors])

  function addConnector(e) {
    setConnectorsAddList(currentList => { return [...currentList, { _id: 'newConn-' + currentList.length, connector_type: e }] });
  }

  /* adding newly added connector to according state */
  function callback_addtoEditConnector(properties, idToRemove) {
    setConnectorsList(currentList => { return [...currentList, properties] }); // adding to current connectors
    setConnectorsAddList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the adding list, as it's done
  }

  function callback_cancelAdd(idToRemove) {
    setConnectorsAddList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the adding list, as it's canceled
  }

  function callback_deleteConnector(idToRemove) {
    setConnectorsList((current) =>
      current.filter((connector) => connector._id !== idToRemove)
    ); // removing from the current connector list, as it's deleted
  }

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('connectorsList' ,error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }

  }, [error, failureCount, failureReason, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingConnectorsData = (isLoading && isFetching)

  return (
    <div className='nhub-alert-body-container'>
      <div id="new-connectors-button-container">
        <AddConnectorButton label={t('clientSettings.connectorsButton.add-elastic')} onClick={() => addConnector('elastic')} disabled={loadingConnectorsData}/>
        <AddConnectorButton label={t('clientSettings.connectorsButton.add-sekoia')} onClick={() => addConnector('sekoia')} disabled={loadingConnectorsData}/>
        {/* <AddConnectorButton label={t('clientSettings.connectorsButton.add-splunk')} onClick={() => addConnector('splunk')} />
              <AddConnectorButton label={t('clientSettings.connectorsButton.add-qradar')} onClick={() => addConnector('qradar')} /> */}
      </div>
      <div id="adding-connectors" style={{ marginTop: '10px' }}>
        {connectorsAddList.map((connector) => {
          switch (connector.connector_type) {
            case 'elastic': return <ElasticConnector
              key={connector._id} tempId={connector._id} initialState={'add'}
              callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} client_id={client_id} />;
            case 'sekoia': return <SekoiaConnector
              key={connector._id} tempId={connector._id} initialState={'add'}
              callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} client_id={client_id} />;
            // case 'splunk': return <SplunkConnector
            //   key={connector._id} tempId={connector._id} initialState={'add'}
            //   callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} client_id={client_id} />;
            // case 'qradar': return <QRadarConnector
            //   key={connector._id} tempId={connector._id} initialState={'add'}
            //   callbackAdd={callback_addtoEditConnector} callbackCancelAdd={callback_cancelAdd} client_id={client_id} />;
            default: return <></>;
          }
        })}
      </div>
      <div id="configured-connectors">
        {connectorsList.length === 0
          ? (t('clientSettings.connectors.no-connectors'))
          : (connectorsList.map((connector) => {
            switch (connector.connector_type) {
              case 'elastic': return <ElasticConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
              case 'sekoia': return <SekoiaConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
              // case 'splunk': return <SplunkConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
              // case 'qradar': return <QRadarConnector connectorInfos={connector} key={connector._id} initialState={'view'} callbackDelete={callback_deleteConnector} />;
              default: return <></>;
            }
          }))
        }
      </div>
    </div>
  )
}
export default ClientConnectors