import { Alert, Grid } from '@mui/material'
import { useTranslation } from "react-i18next";
import { RiCheckboxFill } from "react-icons/ri";
import { MdDisabledByDefault } from "react-icons/md";
import { UserInfosContext } from '../../context/UserInfosContext';
import {useContext} from 'react'

export default function ProfilePageRegisterInfos() {
    const { t } = useTranslation();
    const { userInfos } = useContext(UserInfosContext);
    const registerInfos = userInfos.registerInfos;
    return (
        <div className="flexbox-summary">
            <div className="widget">
                <Grid
                    container
                    direction={{xs: "column",md:"row"}}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>
                    
                    <Grid item container xs={12} justifyContent='space-between'>
                        <Grid item xs={12} sm="auto"><h5 style={{display:'inline'}}>{t('profile.register-title')}</h5></Grid>
                        <Grid item xs={12} sm="auto"><Alert sx={{color:'#0067EE', border:'1px solid #0067EE',}} severity="info">{t('profile.register-info')}<a href='https://register.nybble-analytics.io' target='blank'>register platform</a></Alert></Grid>
                    </Grid>
                    

                    {/* personalInfo */}
                    <Grid item container xs={12} sm columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} textAlign="center" paddingBottom={"10px"}><h5>{t('profile.register-titlePersonalInfo')}</h5></Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.civility')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.civility}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.firstName')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.firstName}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.lastName')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.lastName}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.birthDate')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.birthDate ? new Date(registerInfos?.personalInfo?.birthDate).toLocaleDateString() : null}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.nationality')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.nationality}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.phoneNumber')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.phoneNumber}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.address')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.address}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.additionalAddress')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.additionalAddress || <i className="optionalValue"> {t('profile.register-optional')} </i>}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.city')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.city}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.postalCode')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.postalCode}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-personalInfo.country')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.personalInfo?.country}
                        </Grid>
                    </Grid>
                    {/* CompanyInfos */}
                    <Grid item container xs={12} sm columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} textAlign="center" paddingBottom={"10px"}><h5>{t('profile.register-titleCompanyInfo')}</h5></Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.identification')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.identification}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.number')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.number}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.address')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.address}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.additionalAddress')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.additionalAddress || <i className="optionalValue"> {t('profile.register-optional')} </i>}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.city')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.city}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.postalCode')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.postalCode}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.country')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.country}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.VAT')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.VAT}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.IBAN')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.finance_iban}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-companyInfo.BIC')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.companyInfo?.finance_bic}
                        </Grid>
                    </Grid>
                    {/* TestInfos */}
                    <Grid item container xs={12} sm columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} textAlign="center" paddingBottom={"10px"}><h5>{t('profile.register-titleTestInfo')}</h5></Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-testInfo.analyst')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                            {registerInfos?.testInfo?.analystTest?.status === 'Obtained' ? (<RiCheckboxFill fontSize="20px" color='var( --nhub-color-secondary-5)' />):(<MdDisabledByDefault fontSize="20px" color='var( --nhub-color-secondary-1)' />)}
                        </Grid>
                        <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                            {t('profile.register-testInfo.hunter')}
                        </Grid>
                        <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                        {registerInfos?.testInfo?.hunterTest?.status === 'Obtained' ? (<RiCheckboxFill fontSize="20px" color='var( --nhub-color-secondary-5)' />):(<MdDisabledByDefault fontSize="20px" color='var( --nhub-color-secondary-1)' />)}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
