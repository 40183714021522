import Button from '@mui/material/Button';
import { RiAddLine } from 'react-icons/ri';

export default function AddConnectorButton({ onClick, label, disabled }) {
    return (
        <Button
            variant="contained"
            startIcon={<RiAddLine />}
            sx={{
                backgroundColor: "var(--button-background-6)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 12px', margin: '10px 0',
                ':hover': { backgroundColor: "var(--button-background-hover-6)" },
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </Button>
    )
}