
import { dateDisplay } from '../../utils/formatDate';
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'

function Report(props) {

    return(
        <div className='report-preview-content' id="report-content" style={{ display: "none" }}>
            <div className='report-preview-header'>Nybble Threat Report <br></br>{dateDisplay(props.huntFormValues.updatedAt)}</div>
            <div className="report-preview-footer-left">
                <svg alt='NybbleHub' className='report-preview-nhub-logo' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 556.2 178.7" >

                <path className="st0" d="M82.4,74.9h18.6l9.1,15.5l-9.1,15.5H82.4l-9.1-15.5L82.4,74.9 M76.7,64.9l-15,25.5l15,25.5h30l15-25.5l-15-25.5
                            H76.7z"/>
                <path className="st0" d="M83.2,9.2h17l8.4,14.2l-8.4,14.2h-17l-8.4-14.2L83.2,9.2 M77.9,0L64.2,23.4l13.8,23.4h27.5l13.8-23.4L105.4,0
                            H77.9z"/>
                <path className="st0" d="M22.5,46.6l10.6-13.3l16.3,2.3L55.3,51L44.7,64.3L28.4,62L22.5,46.6 M12,45l9.7,25.3l26.8,3.8l17.2-21.5
                            L56,27.3l-26.8-3.8L12,45z"/>
                <path className="st0" d="M13.9,117.3l-3.8-16.6l12-11.3l15.7,5l3.8,16.6l-12,11.3L13.9,117.3 M6.1,124.4l25.8,8.2l19.7-18.6l-6.1-26.8
                            l-25.8-8.2L0,97.7L6.1,124.4z"/>
                <path className="st0" d="M63.8,168.2l-15.3-7.4l-1.4-16.4l13.7-9.2l15.3,7.4l1.4,16.4L63.8,168.2 M64.6,178.7l22.5-15.1l-2.2-27
                            l-24.8-11.9l-22.5,15.1l2.2,27L64.6,178.7z"/>
                <path className="st0" d="M134.7,160.9l-15.3,7.4l-13.7-9.2l1.4-16.4l15.3-7.4l13.7,9.2L134.7,160.9 M143.4,166.9l2.3-27l-22.5-15.1
                            l-24.8,11.9l-2.3,27l22.5,15L143.4,166.9z"/>
                <path className="st0" d="M173.2,100.9l-3.8,16.6l-15.7,5l-12-11.3l3.8-16.6l15.7-5L173.2,100.9 M183.3,97.8l-19.7-18.7l-25.8,8.2
                            l-6.2,26.8l19.7,18.6l25.8-8.2L183.3,97.8z"/>
                <path className="st0" d="M150.3,33.3l10.6,13.3L155,62l-16.3,2.3L128.1,51l5.9-15.4L150.3,33.3 M154.2,23.5l-26.8,3.8l-9.7,25.3
                            l17.1,21.5l26.9-3.8l9.7-25.3L154.2,23.5z"/>
                <g>
                    <path className="st1" d="M208.9,53.1h14l20.9,33.7V53.1h15.7V117h-13.9l-21-33.7V117h-15.7V53.1z" />
                    <path className="st1" d="M292.3,95.4l-21-42.3H288l12.3,25.5c2.7-5.7,6.2-13.6,12.4-25.5H329l-20.9,42.3V117h-15.8V95.4z" />
                    <path className="st1" d="M339.1,53.1h23.4c18.4,0,22.8,10.6,22.8,19.2c0,4-1.4,7.8-3.9,10.9c4.8,3.2,7.8,8.6,7.9,14.4
                                c0,8.7-3.5,19.3-26.8,19.3h-23.4V53.1z M355,78.3h6.5c6.8,0,8.1-3.7,8.1-6S369,66,362.5,66H355V78.3z M355,104.1h7.4
                                c9.7-0.2,11-3,11-6.5s-2.2-6.6-12-6.6H355V104.1z"/>
                    <path className="st1" d="M401.2,53.1h23.4c18.4,0,22.8,10.6,22.8,19.2c0,4-1.4,7.8-3.9,10.9c4.8,3.2,7.8,8.6,7.9,14.4
                                c0,8.7-3.5,19.3-26.8,19.3h-23.4V53.1z M417,78.3h6.5c6.8,0,8.1-3.7,8.1-6S431,66,424.5,66H417V78.3z M417,104.1h7.4
                                c9.7-0.2,11.1-3,11.1-6.5s-2.2-6.6-12-6.6H417V104.1z"/>
                    <path className="st1" d="M463.2,53.1h15.7v50.7h23.6V117h-39.3V53.1z" />
                    <path className="st1" d="M515.5,53.1h40V66h-24.2v13.3h20.2v12.9h-20.2v12h24.9V117h-40.7V53.1z" />
                </g>
                </svg>
            </div>

            <div className="report-preview-footer-right">
                <span className='report-preview-footer-right-placeholder'></span>
            </div>

            <div className='report-preview-content'>
                <p className='report-preview-report-title'>{props.huntFormValues.summary}</p>
                <span className='report-preview-chapters'>Description</span>
                <ReactMarkdown children={props.huntFormValues.description} />
                <span className='report-preview-chapters'>Details</span>
                <ReactMarkdown children={props.huntFormValues.details} remarkPlugins={[remarkGfm]} components={{
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                        <SyntaxHighlighter
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        />
                        ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                        )
                    }
                    }} />
                <span className='report-preview-chapters'>Proofs</span>
                <ReactMarkdown children={props.huntFormValues.proofs} remarkPlugins={[remarkGfm]} components={{
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                        <SyntaxHighlighter
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        />
                        ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                        )
                    }
                    }} />
                <span className='report-preview-chapters'>IOC</span>
                <ReactMarkdown children={props.huntFormValues.ioc} />
                <span className='report-preview-chapters'> CVE</span>
                <ReactMarkdown children={props.huntFormValues.cve} />
                <span className='report-preview-chapters'>Vulnerability</span>
                <ReactMarkdown children={props.huntFormValues.vulnerability} />
                <span className='report-preview-chapters'>Affected Systems</span>
                <ReactMarkdown children={props.huntFormValues.affected_systems} />
                {props.huntFormValues.remediation
                ? <>
                    <span className='report-preview-chapters'>Remediation</span>
                    <ReactMarkdown children={props.huntFormValues.remediation} remarkPlugins={[remarkGfm]} components={{
                        code({node, inline, className, children, ...props}) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                            ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                            )
                        }
                        }} />
                </>
                : null
                }
                <span className='report-preview-chapters'>Impact</span>
                <p>{props.huntFormValues.impact}</p>
            </div>
        </div>
    )
}

export default Report