import '../styles/Header.css'

import { useState} from "react";
import {  useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';

import { RiAccountCircleLine, RiLogoutBoxRLine, RiQuestionLine, RiLinksLine, RiNewspaperLine } from "react-icons/ri";

function Notifications({ setNotificationsIsOpen, styles }) {
  const { t } = useTranslation()

  const handleTooltipClose = () => {
    setNotificationsIsOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={styles.headerNavRelease}>
        <div className='sectionTitle'>
          <h5>{t('header.label-Notifications')}</h5>
        </div>
        <div className='headerNavMenuSection notificationItems '>
          {t('header.notifications-no-one')}
        </div>
      </div>
    </ClickAwayListener>
  )
}
function ReleaseInfo({ setReleaseInfoIsOpen, styles }) {
  const { t } = useTranslation()

  const release = process.env.REACT_APP_RELEASE_VERSION

  const handleTooltipClose = () => {
    setReleaseInfoIsOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={styles.headerNavRelease}>
        <div className={styles.releaseTitle}>
          <h5>
            Nybble Hub {t('header.label-Release')}
            {release}
          </h5>
        </div>
        <div
          className={(styles.headerNavMenuSection, styles.notificationItems)}
        >
          <Button
            startIcon={<RiLinksLine />}
            href={t('links.releaseNotes-link')}
            title={t('links.releaseNotes-link')}
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: '600',
              padding: '2px 4px'
            }}
          >
            {t('header.label-ReleaseNotes')}
          </Button>
        </div>
        <div
          className={(styles.headerNavMenuSection, styles.notificationItems)}
        >
          <Button
            startIcon={<RiLinksLine />}
            href={t('links.privacyPolicy-link')}
            title={t('links.privacyPolicy-link')}
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: '600',
              padding: '2px 4px'
            }}
          >
            {t('header.label-PrivacyPolicy')}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export const HeaderNavLinks = ({ styles }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
  const [releaseInfoIsOpen, setReleaseInfoIsOpen] = useState(false);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
    queryClient.invalidateQueries()
  }

  return (
    <>
      <span
        className={styles.headerNavLink}
        onClick={() => setReleaseInfoIsOpen(true)}
        title={t("header.label-ReleaseInfo")}
        aria-label={t("header.label-ReleaseInfo")}
        data-rel="tooltip"
        data-html="true"
        data-original-title="ReleaseInfo"
        data-placement="bottom"
      >
        <RiNewspaperLine />
      </span>
      <a href="https://docs.nybble-security.io/nybble-hub/overview/" title="docs Nybble hub" target="_blank" rel="noopener noreferrer">
        <span className={styles.headerNavLink}>
          <RiQuestionLine />
        </span>
      </a>
      {/* <span className={styles.headerNavLink} onClick={() => setNotificationsIsOpen(true)}
					title={t('header.label-Notifications')} aria-label={t('header.label-Notifications')} data-rel="tooltip" data-html="true" data-original-title="Notifications" data-placement="bottom"  >
					<RiNotification2Line />
				</span> */}
      <span
        className="headerNavLink"
        onClick={() => {
          navigate('/profile')
        }}
        title={t("header.label-Profile")}
        aria-label={t("header.label-Profile")}
        alt={t("header.label-Profile")}
      >
        <RiAccountCircleLine />
      </span>
      <span
        className={styles.headerNavLink}
        onClick={() => {
          handleLogout()
        }}
        title={t("header.label-Logout")}
        aria-label={t("header.label-Logout")}
        alt={t("header.label-Logout")}
      >
        <RiLogoutBoxRLine />
      </span>
      {notificationsIsOpen
        ? (
          <Notifications
            setNotificationsIsOpen={setNotificationsIsOpen}
            styles={styles}
          />
        )
        : null}
      {releaseInfoIsOpen
        ? (
          <ReleaseInfo
            setReleaseInfoIsOpen={setReleaseInfoIsOpen}
            styles={styles}
          />
        )
        : null}
    </>
  );
};
