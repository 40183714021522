import { useEffect, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Skeleton } from '@mui/material';

import { RiArrowRightSLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ScoringStats from '../../components/ScoringStats';
import { getUser } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function ScoringPage() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  let { userId } = useParams();

  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: user,
    error: errorUser,
    failureReason: failureUser,
    failureCount: failureCountUser,
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['user', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getUser(token, userId)),
  });

  useEffect(() => {
    const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }
  }, [errorUser, failureCountUser, failureUser, setSnackbarObj]);

  return (

    <div className='nhub-campaign-content-container'>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/users/'>
              {/* Users */}
              {t('adminPages.userSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {user?.displayName}
        </h4>
        {!user?.displayName ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='nhub-campaignSingle-content'>
        <ScoringStats userId={userId} />
      </div>
    </div>
  )
}
export default ScoringPage