import React, { useEffect, useState } from 'react'

export default function CampaignBalanceBar({currentCampaign}) {

    const [campaignBalanceBarPercent, SetCampaignBalanceBarPercent] = useState(0);
    const [campaignBalanceBarColor, SetCampaignBalanceBarColor] = useState('');

    useEffect(() => {
        SetCampaignBalanceBarPercent(currentCampaign.spent_money * 100 / currentCampaign.prize_money);
        GetBalanceBarColor(campaignBalanceBarPercent);
    },[currentCampaign, campaignBalanceBarPercent]);

    // set the color of the Campaign Balance Bar
    function GetBalanceBarColor(campaignBalanceBarPercent) {
        if (campaignBalanceBarPercent > 89) {
            SetCampaignBalanceBarColor('#FF4742')
        } else if (campaignBalanceBarPercent < 70) {
            SetCampaignBalanceBarColor('#29BDB8')
        } else {
            SetCampaignBalanceBarColor('#F19E33')
        }
    }

    const progressBarParentDiv = {
        position: 'relative',
        height: '20px',
        width: '100%',
        border: '1px solid #C4C4C4',
        borderRadius: 5,
        overflow: 'hidden',
    }

    const progressBarChildDiv = {
        height: '100%',
        width: `${campaignBalanceBarPercent}%`,
        backgroundColor: `${campaignBalanceBarColor}`,
        // textAlign: 'right'
    }

    const progressBarText = {
        position: 'absolute',
        top: '0',
        width: '100%',
        textAlign: 'center'
    }


    return (
        <div style={progressBarParentDiv}>
            <div style={progressBarChildDiv}></div>
            <span style={progressBarText}>{`€ ${currentCampaign.spent_money}/${currentCampaign.prize_money}`}</span>
        </div>
    )
}
