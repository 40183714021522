import '../../styles/InvestigationsPage.css'
import '../../styles/AlertsPage.css'

import { useEffect, useContext } from 'react'

import { useTranslation } from "react-i18next"
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Grid, Skeleton } from '@mui/material';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import InvestigationsTable from './InvestigationsTable';
import { getAllInvestigations } from '../../services/investigation.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function InvestigationsPage() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { setSnackbarObj } = useContext(UserInfosContext);

    const {
        data: investigationList,
        isLoading,
        error,
        failureReason,
        failureCount,
        isFetching
    } = useQuery({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
        queryKey: ['investigationsList'],
        queryFn: () => getAccessTokenSilently().then((token) => getAllInvestigations(token)),
    });

    useEffect(() => {
        const snackbar = ResponseSnackbarErrorHandler('investigationsList', error, failureReason, failureCount)
        if (snackbar) { setSnackbarObj(snackbar) }
    }, [error, failureCount, failureReason, setSnackbarObj]);

    /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
    const loadingInvestigationListData = (isLoading && isFetching)

    return (
        <div className='nhub-investigations-container'>
            <Grid container spacing={1}
                direction='row'
                justifyContent="space-evenly"
                alignItems="stretch"
                sx={{ paddingBottom: '10px' }}
            >
                <Grid item xs={6} sm={3} lg='auto'>
                    <div className='invest-flexbox box-invest-total'>
                        <span className='titleBox'>{t('investigations.investigationsPage.title-total')} </span>
                        <span className='valueBox'>{loadingInvestigationListData ? <Skeleton /> : investigationList.length} </span>
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} lg='auto'>
                    <div className='invest-flexbox box-invest-open'>
                        <span className='titleBox'>{t('investigations.investigationsPage.title-open')} </span>
                        <span className='valueBox'>{loadingInvestigationListData ? <Skeleton /> : investigationList.filter(investigationItem => investigationItem.status === 'Open').length} </span>
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} lg='auto'>
                    <div className='invest-flexbox box-invest-resolved'>
                        <span className='titleBox'>{t('investigations.investigationsPage.title-resolved')} </span>
                        <span className='valueBox'>{loadingInvestigationListData ? <Skeleton /> : investigationList.filter(investigationItem => investigationItem.status === 'Resolved').length} </span>
                    </div>
                </Grid>
                <Grid item xs={6} sm={3} lg='auto'>
                    <div className='invest-flexbox box-invest-pending'>
                        <span className='titleBox'>{t('investigations.investigationsPage.title-pending')} </span>
                        <span className='valueBox'>{loadingInvestigationListData ? <Skeleton /> : investigationList.filter(investigationItem => investigationItem.status === 'Pending Client').length} </span>
                    </div>
                </Grid>
            </Grid>
            <div className='invest-table-container'>
                <InvestigationsTable investigationList={investigationList} loadingData={loadingInvestigationListData} />
            </div>
        </div>
    )
}
export default InvestigationsPage