
import { useState, useEffect, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown'
import { useQuery } from '@tanstack/react-query';

import { Button, Grid, Skeleton } from '@mui/material';
import { RiAddLine, RiArrowRightSLine, RiSearchLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ReportsTable from './ReportsTable';
import EditReport from './EditReport';
import ReportsClientsTable from './ReportsClientsTable';
import CampaignBalanceBar from '../../components/CampaignBalanceBar';
import { dateDisplay } from '../../utils/formatDate';
import { UserInfosContext } from '../../context/UserInfosContext';
import { getHuntCampaign, getHuntCampaignReports } from '../../services/huntCampaign.service';


export default function CampaignSingle() {
  const { campaignId } = useParams();
  const [openCreateReport, setOpenCreateReport] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: campaignDetails,
    isLoading: loadingCampaign,
    error: errorCampaign,
    failureReason: failureCampaign,
    failureCount: failureCountCampaign,
    isFetching: fetchingCampaign
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when getAccessTokenSilently and campaignId are available.
    queryKey: ['huntCampaign', { _id: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaign(token, campaignId)),
  }
  );

  const {
    data: huntCampaignReports,
    isLoading: loadingHuntCampaignReports,
    error: errorHuntCampaignReports,
    failureReason: failureHuntCampaignReports,
    failureCount: failureCountHuntCampaignReports,
    isFetching: fetchingHuntCampaignReports
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when getAccessTokenSilently and campaignId are available.
    queryKey: ['huntCampaignReportsList', { campaignId: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaignReports(token, campaignId)),
  });


  useEffect(() => {
    const snackbarCampaign = ResponseSnackbarErrorHandler('huntCampaign', errorCampaign, failureCampaign, failureCountCampaign)
    if (snackbarCampaign) { setSnackbarObj(snackbarCampaign) }

    const snackbarReports = ResponseSnackbarErrorHandler('huntCampaignReportsList', errorHuntCampaignReports, failureHuntCampaignReports, failureCountHuntCampaignReports)
    if (snackbarReports) { setSnackbarObj(snackbarReports) }

  }, [errorCampaign, errorHuntCampaignReports, failureCampaign, failureCountCampaign, failureCountHuntCampaignReports, failureHuntCampaignReports, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingHuntCampaignData = (loadingCampaign && fetchingCampaign)

  if (openCreateReport) {
    return (
      <div>
        <EditReport campaignDetails={campaignDetails} reportMode={'Create'} setOpenCreateReport={setOpenCreateReport} />
      </div>
    )
  }
  else {
    return (
      <div className='nhub-hunt-container'>
        {campaignId
          ? <>
            <div className="menu-title">
              <h4>
                <span><Link activeclassname={"active"} to='/campaigns'>
                  {/* Campaigns */}
                  {t('hunts.huntsPage.head-title')}
                </Link></span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {campaignDetails?.name}
              </h4>
              {loadingHuntCampaignData ? <Skeleton width={'250px'} /> : null}
            </div>
            <Grid container spacing={2} alignItems="stretch" sx={{ marginTop: '27px' }}>
              <Grid item xs={12} sm='auto'>
                <div className="widget">
                  <div className="flexbox-summary">
                    {/* <h5>Information</h5> */}
                    <h5>{t('hunts.huntsPage.info-title')}</h5>
                    <div className="summary-content">
                      <div className="summary-items summary-titles">
                        <ul>
                          <li>{t('hunts.huntsPage.info-Status')}</li>
                          <li>{t('hunts.huntsPage.info-StartDate')}</li>
                          <li>{t('hunts.huntsPage.info-EndDate')}</li>
                          {userInfos.profile === 'Client' ? <li>{t('hunts.clientSide.huntsClientPage.info-Balance')}</li> : null}
                        </ul>
                      </div>
                      <div className="summary-items summary-values">
                        <ul>
                          <li>{loadingHuntCampaignData ? <Skeleton width={'114px'} /> : campaignDetails?.status}</li>
                          <li>{loadingHuntCampaignData ? <Skeleton /> : dateDisplay(campaignDetails?.start_date)}</li>
                          <li>{loadingHuntCampaignData ? <Skeleton /> : dateDisplay(campaignDetails?.end_date)}</li>
                          {userInfos.profile === 'Client'
                            ? <li>{loadingHuntCampaignData ? <Skeleton /> : <CampaignBalanceBar currentCampaign={campaignDetails} />}</li>
                            : null}
                        </ul>
                      </div>
                    </div>
                    <br />
                    {/* <h5>Bounties</h5> */}
                    <h5>{t('hunts.huntsPage.bounty-title')}</h5>
                    <div className="summary-content">
                      <div className="summary-items summary-titles">
                        <ul>
                          <li>{t('hunts.huntsPage.bounty-Low')}</li>
                          <li>{t('hunts.huntsPage.bounty-Medium')}</li>
                          <li>{t('hunts.huntsPage.bounty-High')}</li>
                          <li>{t('hunts.huntsPage.bounty-Critical')}</li>
                        </ul>
                      </div>
                      <div className="summary-items summary-values">
                        <ul>
                          <li>
                            {loadingHuntCampaignData ? <Skeleton width={'100px'} /> :
                              <>€ {campaignDetails?.price_low}</>
                            }
                          </li>
                          <li>
                            {loadingHuntCampaignData ? <Skeleton /> :
                              <>€ {campaignDetails?.price_medium}</>
                            }
                          </li>
                          <li>
                            {loadingHuntCampaignData ? <Skeleton /> :
                              <>€ {campaignDetails?.price_high}</>
                            }
                          </li>
                          <li>
                            {loadingHuntCampaignData ? <Skeleton /> :
                              <>€ {campaignDetails?.price_critical}</>
                            }
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm lg>
                <div className="widget">
                  {/* <h5> Campaign Policy </h5> */}
                  <h5>{t('hunts.huntsPage.policy-title')}</h5>
                  <div className="invest-markdown">
                    {loadingHuntCampaignData
                      ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                      : <ReactMarkdown children={campaignDetails?.policy} />
                    }
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg>
                <div className="widget">
                  {/* <h5> Do Not Report </h5> */}
                  <h5>{t('hunts.huntsPage.notReport-title')}</h5>
                  <div className="invest-markdown">
                    {loadingHuntCampaignData
                      ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                      : <ReactMarkdown children={campaignDetails?.do_not_report} />
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className='report-table-container'>
              {/* <h5>Reports</h5> */}
              <h5>{t('hunts.huntsPage.report-title')}</h5>
              {userInfos.profile === 'Client' ? (
                <ReportsClientsTable reports={huntCampaignReports} loadingData={(loadingHuntCampaignReports && fetchingHuntCampaignReports)} />
              ) : (<>
                <ReportsTable reports={huntCampaignReports} campaignDetails={campaignDetails} loadingData={loadingHuntCampaignData || (loadingHuntCampaignReports && fetchingHuntCampaignReports)} />
                <div className='hunt-button-container'>
                  <Button
                    variant="contained"
                    startIcon={<RiAddLine />}
                    sx={{
                      backgroundColor: "var(--button-background-6)",
                      textTransform: 'none',
                      fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '0 10px 10px 0',
                      ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                    }}
                    onClick={() => { setOpenCreateReport(true) }}
                    disabled={campaignDetails?.status !== 'In Progress'}
                  >
                    {/* Submit a new report */}
                    {t('button.submitReport')}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<RiSearchLine />}
                    href={campaignDetails?.siem_url}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      backgroundColor: "var(--button-background-5)",
                      textTransform: 'none',
                      fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '0 10px 10px 0',
                      ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                    }}
                    onClick={() => { }}
                    disabled={campaignDetails?.status !== 'In Progress'}
                  >
                    {/* Browse SIEM Logs */}
                    {t('button.browseLogs')}
                  </Button>
                </div>
              </>
              )}
            </div>
          </>
          : null}
      </div>
    )
  }
}
