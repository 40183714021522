import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import { Grid, Button, TextField, MenuItem } from '@mui/material';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { RiSave2Line } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { postHuntCampaign } from '../../services/huntCampaign.service';
import { AdminCampaignStatus } from '../../data/constants';
import { UserInfosContext } from '../../context/UserInfosContext';
import { CustomDialogTitle } from '../../components/CustomDialogTitle';


function CreateCampaign({ clientsList, setOpenCreateCampaign, openCreateCampaign }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    // const [loader, setLoader] = useState(false);

    const { setSnackbarObj } = useContext(UserInfosContext);

    let sortedClientList = clientsList

    sortedClientList.sort((a, b) => {
        let na = a.displayName.toLowerCase(),
            nb = b.displayName.toLowerCase();
        if (na < nb) { return -1; }
        if (na > nb) { return 1; }
        return 0;
    })

    let d = new Date();
    const todayDateIso = d.toISOString();
    const todayDateStr = d.toString();
    d.setMonth(d.getMonth() + 1);
    const nextMonthDateIso = d.toISOString();

    const [startDateFormValue, setStartDateFormValue] = useState(moment(todayDateIso));
    const [endDateFormValue, setEndDateFormValue] = useState(moment(nextMonthDateIso));

    const [minEndDate, setMinEndDate] = useState(todayDateStr);

    useEffect(() => {
        if (startDateFormValue._d && startDateFormValue._isValid) {
            setCampaignFormValues(campaignFormValues => ({ ...campaignFormValues, start_date: (startDateFormValue._d).toISOString() }));
            let d = new Date(startDateFormValue._d)
            d.setDate(d.getDate() + 1);
            setMinEndDate(d.toString())
        }
    }, [startDateFormValue])

    useEffect(() => {
        if (endDateFormValue._d && endDateFormValue._isValid) {
            setCampaignFormValues(campaignFormValues => ({ ...campaignFormValues, end_date: (endDateFormValue._d).toISOString() }));
        }
    }, [endDateFormValue])

    // react state. used for field validation and initial display
    const [campaignFormValues, setCampaignFormValues] = useState({
        name: '',
        client_id: '',
        status: 'Not Started',
        start_date: startDateFormValue,
        end_date: endDateFormValue,
        price_money: '',
        price_low: '',
        price_medium: '',
        price_high: '',
        price_critical: '',
        do_not_report: '',
        policy: '',
        logo_url: '',
        siem_url: ''
    });

    // validation failed indicators
    const [campaignFormErrors, setCampaignFormErrors] = useState({
        name: false,
        client_id: false,
        status: false,
        start_date: false,
        end_date: false,
        price_money: false,
        price_low: false,
        price_medium: false,
        price_high: false,
        price_critical: false,
        do_not_report: false,
        policy: false,
        siem_url: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [campaignFormTouches, setCampaignFormTouches] = useState({
        name: false,
        client_id: false,
        status: false,
        start_date: false,
        end_date: false,
        price_money: false,
        price_low: false,
        price_medium: false,
        price_high: false,
        price_critical: false,
        do_not_report: false,
        policy: false,
        siem_url: false
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setCampaignFormValues({ ...campaignFormValues, [name]: value })
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        campaignFormValues[name] ? setCampaignFormErrors({ ...campaignFormErrors, [name]: false }) : setCampaignFormErrors({ ...campaignFormErrors, [name]: true });
        setCampaignFormTouches({ ...campaignFormTouches, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (campaignFormValues.name && campaignFormValues.client_id && campaignFormValues.start_date && campaignFormValues.end_date && campaignFormValues.price_low && campaignFormValues.price_medium && campaignFormValues.price_high && campaignFormValues.price_critical && campaignFormValues.do_not_report && campaignFormValues.policy && campaignFormValues.siem_url)
            &&
            (campaignFormValues.start_date < campaignFormValues.end_date)
            &&
            (startDateFormValue._isValid || !startDateFormValue._d) && (endDateFormValue._isValid || !endDateFormValue._d)
            &&
            (!campaignFormErrors.name && !campaignFormErrors.client_id && !campaignFormErrors.price_low && !campaignFormErrors.price_medium && !campaignFormErrors.price_high && !campaignFormErrors.price_critical && !campaignFormErrors.do_not_report && !campaignFormErrors.policy && !campaignFormErrors.siem_url)
        )
    }

    const postHuntCampaignMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postHuntCampaign(token, campaignFormValues)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['huntCampaignsList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntCampaign', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSave = (e) => {
        e.preventDefault()
        postHuntCampaignMutation.mutate()
    }


    const handleClose = () => { setOpenCreateCampaign(false) };


    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='md'
                open={openCreateCampaign}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
                    {t('adminPages.createCampaign.head-title')}
                </CustomDialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                select
                                name="client_id"
                                label={t('adminPages.createCampaign.field-Client')}
                                variant="standard"
                                // value={campaignFormValues.client_id}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                key={`TextField-${campaignFormValues.client_id}`}
                                defaultValue={campaignFormValues.client_id}
                                InputLabelProps={campaignFormValues.client_id ? { shrink: true } : null}
                            >
                                {sortedClientList.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.displayName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                name="name"
                                label={t('adminPages.createCampaign.field-CampaignName')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.name}
                                error={campaignFormTouches.name && campaignFormErrors.name}
                                helperText={campaignFormTouches.name && campaignFormErrors.name && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.name ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                select
                                name="status"
                                label={t('adminPages.createCampaign.field-Status')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                key={`TextField-${campaignFormValues.status}`}
                                defaultValue={campaignFormValues.status}
                                InputLabelProps={campaignFormValues.status ? { shrink: true } : null}
                            >
                                {AdminCampaignStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                name="siem_url"
                                label={t('adminPages.createCampaign.field-SIEM')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.siem_url}
                                type='url'
                                pattern="http(s*)://.*"
                                error={campaignFormTouches.siem_url && campaignFormErrors.siem_url}
                                helperText={campaignFormTouches.siem_url && campaignFormErrors.siem_url && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.siem_url ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="logo_url"
                                label={t('adminPages.createCampaign.field-LogoURL')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.logo_url}
                                type='url'
                                pattern="http(s*)://.*"
                                InputLabelProps={campaignFormValues.logo_url ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                name="prize_money"
                                label={t('adminPages.createCampaign.field-PrizeMoney')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.prize_money}
                                InputLabelProps={campaignFormValues.prize_money ? { shrink: true } : null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid item xs={3}>
                                <DesktopDatePicker
                                    label={t('adminPages.createCampaign.field-StartDate')}
                                    format="DD/MM/YYYY"
                                    value={startDateFormValue}
                                    onChange={(newValue) => {
                                        setStartDateFormValue(newValue)
                                    }}
                                    minDate={moment(todayDateStr)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true, size: 'small',
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                            "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                            "& .MuiButtonBase-root": { padding: "0" },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DesktopDatePicker
                                    label={t('adminPages.createCampaign.field-EndDate')}
                                    format="DD/MM/YYYY"
                                    value={endDateFormValue}
                                    onChange={(newValue) => {
                                        setEndDateFormValue(newValue)
                                    }}
                                    minDate={moment(minEndDate)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true, size: 'small',
                                            helperText: campaignFormValues.start_date < campaignFormValues.end_date
                                                ? null
                                                : t('adminPages.createCampaign.helper-dateRangeError')
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                            "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                            "& .MuiButtonBase-root": { padding: "0" },
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                name="price_low"
                                label={t('adminPages.createCampaign.field-PriceLow')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.price_low}
                                error={campaignFormTouches.price_low && campaignFormErrors.price_low}
                                helperText={campaignFormTouches.price_low && campaignFormErrors.price_low && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.price_low ? { shrink: true } : null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                name="price_medium"
                                label={t('adminPages.createCampaign.field-PriceMedium')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.price_medium}
                                error={campaignFormTouches.price_medium && campaignFormErrors.price_medium}
                                helperText={campaignFormTouches.price_medium && campaignFormErrors.price_medium && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.price_medium ? { shrink: true } : null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                name="price_high"
                                label={t('adminPages.createCampaign.field-PriceHigh')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.price_high}
                                error={campaignFormTouches.price_high && campaignFormErrors.price_high}
                                helperText={campaignFormTouches.price_high && campaignFormErrors.price_high && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.price_high ? { shrink: true } : null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                name="price_critical"
                                label={t('adminPages.createCampaign.field-PriceCritical')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.price_critical}
                                error={campaignFormTouches.price_critical && campaignFormErrors.price_critical}
                                helperText={campaignFormTouches.price_critical && campaignFormErrors.price_critical && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.price_critical ? { shrink: true } : null}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                fullWidth
                                required
                                name="policy"
                                label={t('adminPages.createCampaign.field-CampaignPolicy')}
                                variant="standard"
                                multiline
                                maxRows={50}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.policy}
                                error={campaignFormTouches.policy && campaignFormErrors.policy}
                                helperText={campaignFormTouches.policy && campaignFormErrors.policy && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.policy ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-multiline-flexible"
                                fullWidth
                                required
                                name="do_not_report"
                                label={t('adminPages.createCampaign.field-NotReport')}
                                variant="standard"
                                multiline
                                maxRows={50}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={campaignFormValues.do_not_report}
                                error={campaignFormTouches.do_not_report && campaignFormErrors.do_not_report}
                                helperText={campaignFormTouches.do_not_report && campaignFormErrors.do_not_report && t('form.field-down-helper')}
                                InputLabelProps={campaignFormValues.do_not_report ? { shrink: true } : null}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isEnabled()}
                        onClick={(e) => { handleSave(e) }}
                        startIcon={<RiSave2Line />}
                        sx={{
                            my: 10,
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                    >
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CreateCampaign