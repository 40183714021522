function cryptoRandom() {
  // return a crypto generated number
  var cryptoObj = window.crypto || window.msCrypto; // for IE 11
  var array = new Uint32Array(1),
    max = Math.pow(2, 32), // normally the max is 2^32 -1 but we remove the -1 so that the max is exclusive
    randomValue = cryptoObj.getRandomValues(array)[0] / max;
  return randomValue;
}

export function randomPassword() {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  var pwd = "";
  for (var i = 0; i < 20; i++) {
    var rand = Math.floor(cryptoRandom() * characters.length);
    pwd += characters.charAt(rand);
  }
  return pwd;
}

export function strengthPassword(passwordValue) {

  const strengthChecks = {
    length: 0,
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  };

  strengthChecks.length = passwordValue.length >= 8 ? true : false;
  strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
  strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
  strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
  strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

  //   let verifiedList = Object.values(strengthChecks).filter((value) => value);

  //     let strength =
  //       verifiedList.length == 5
  //         ? "Strong"
  //         : verifiedList.length >= 2
  //         ? "Medium"
  //         : "Weak";

  //     setPassword(passwordValue);
  //     // setProgress(`${(verifiedList.length / 5) * 100}%`);
  //     // setMessage(strength);

  //     console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);

  return strengthChecks
}