import { useContext } from 'react'
import "../../styles/CampaignItem.css"
import { dateDisplay } from '../../utils/formatDate';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserInfosContext } from '../../context/UserInfosContext';

export default function CampaignItem({ name, prices, end_date, status, id, logo }) {

    function CampaignStatus({ status }) {
        switch (status) {
            case "On Hold": return <span className='campaignItem-status on-hold'>{status}</span>

            case "In Progress": return <span className='campaignItem-status'>{status}</span>

            case "Not Started": return <span className='campaignItem-status not-started'>{status}</span>

            case "Finished": return <span className='campaignItem-status finished'>{status}</span>

            default: return <span className='campaignItem-status'>{status}</span>
        }

    }
    const { t } = useTranslation();
    prices.sort((a, b) => a - b);
    const { userInfos } = useContext(UserInfosContext);
    const locked = (status === "On Hold" || status === "Finished") && userInfos.profile !== 'Admin'

    return (
        <Link
            to={{ pathname: '/campaigns/' + id }}
            className={locked ? "campaignItem-title campaignItem-disabled" : "campaignItem-title"}
        >
            <div className='campaignItem-container widget'>
                <div className='campaignItem-identity'>
                    <div className='campaignItem-logo'>
                        {/* logo or colored font with few letters */}
                        {logo ? <img src={logo} alt={name} /> : name.substring(0, 3)}

                    </div>
                    <div className='campaignItem-contentCenter'>
                        {/* Name of client, name of campaign, status, dates */}
                        {name}
                        <div className='campaignItem-statusDates'>
                            <CampaignStatus status={status} />
                            <span className='campaignItem-dates'>{t('hunts.campaigns.until') + " " + dateDisplay(end_date)}</span>
                        </div>
                    </div>
                </div>
                <div className='campaignItem-contentRight'>
                    {/* bounty range, submit button or ask for join */}
                    <div className='campaignItem-bounties'>
                        <h3>{t('hunts.campaigns.bounty-range')}</h3>
                        <span>{prices.at(0)}€ - {prices.at(-1)}€</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
