import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, Grid, Skeleton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { RiArrowRightSLine, RiSendPlane2Line, RiDeleteBin2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ClientUsersTable from './ClientUsersTable'
import ClientInvoicePeriods from './ClientInvoicePeriods';
import ClientConnectors from './ClientConnectors';
import { AdminClientInstances, AdminClientStatus } from '../../data/constants';
import { dateDisplay } from '../../utils/formatDate';
import { getClient, putClient, deleteClient } from '../../services/client.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function ClientSingle({ usersList, loadingData }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  let { clientId } = useParams();
  const navigate = useNavigate();

  const [usersData, setUsersData] = useState([]);

  const [dialOpen, setDialOpen] = useState(false);
  const handleClickOpen = () => { setDialOpen(true); };
  const handleClose = () => { setDialOpen(false) };

  const { setSnackbarObj } = useContext(UserInfosContext);

  // react state. used for field validation and initial display
  const [clientFormValues, setClientFormValues] = useState({
    displayName: '',
    status: '',
    instance: '',
    instance_size: 0,
    alerts_outgoing_queue_name: 'alerts_outgoing_nybble'
  });

  const {
    data: client,
    isLoading: loadingClient,
    error: errorClient,
    failureReason: failureClient,
    failureCount: failureCountClient,
    isFetching: fetchingClient
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    queryKey: ['client', { _id: clientId }],
    queryFn: () => getAccessTokenSilently().then((token) => getClient(token, clientId)),
  });
  useEffect(() => {
    if (client) { setClientFormValues(client); }
  }, [client])

  useEffect(() => {
    if (clientId && usersList) {
      setUsersData(usersList.filter(user => (user.client_id[0] === clientId)))
    }
  }, [clientId, usersList])


  // validation failed indicators
  const [clientFormErrors, setClientFormErrors] = useState({
    displayName: false,
    status: false,
    instance: false,
    instance_size: false,
    alerts_outgoing_queue_name: false
  });

  // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
  const [clientFormTouches, setClientFormTouches] = useState({
    displayName: false,
    status: false,
    instance: false,
    instance_size: false,
    alerts_outgoing_queue_name: false
  });

  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [top, nested] = name.split('.');
      setClientFormValues({ ...clientFormValues, [top]: { [nested]: value } })
    } else {
      setClientFormValues({ ...clientFormValues, [name]: value })
    }
  }

  // update the validation infos when user leaves the field (used only on fields requiring validation)
  const handleBlur = e => {
    const { name,
      // value
    } = e.target
    clientFormValues[name] ? setClientFormErrors({ ...clientFormErrors, [name]: false }) : setClientFormErrors({ ...clientFormErrors, [name]: true });
    setClientFormTouches({ ...clientFormTouches, [name]: true });
  }

  // submit button: enabled only when everything is alright
  function isEnabled() {
    return (
      (clientFormValues.displayName && clientFormValues.status)
      &&
      (!clientFormErrors.displayName && !clientFormErrors.status)
    )
  }

  const putClientMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => putClient(token, clientId, clientFormValues)),
    onSuccess: (data) => {
      queryClient.setQueryData(['client', { _id: clientId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['clientsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('client', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    putClientMutation.mutate()
  }

  const deleteClientMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => deleteClient(token, clientId)),
    onSuccess: () => {
      // result.message === "Successfully deleted. "
      queryClient.invalidateQueries({ queryKey: ['client', { _id: clientId }] }) //invalid query data, need refetch
      queryClient.invalidateQueries({ queryKey: ['clientsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/clients/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('client', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleDelete = (e) => {
    e.preventDefault()
    deleteClientMutation.mutate()
  }

  useEffect(() => {
    const snackbarClientInfos = ResponseSnackbarErrorHandler('client', errorClient, failureClient, failureCountClient)
    if (snackbarClientInfos) { setSnackbarObj(snackbarClientInfos) }

  }, [errorClient, failureClient, failureCountClient, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingClientData = (loadingClient && fetchingClient)

  return (
    <div className='nhub-campaign-content-container'>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/clients/'>
              {/* Clients */}
              {t('adminPages.clientSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {clientFormValues.displayName}
        </h4>
        {!clientFormValues.displayName ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='nhub-campaignSingle-content'>
        <Grid container spacing={2}>
          <Grid item xs sm="auto">
            <div>
              <div className="widget">
                {/* General Informations */}
                <h5>{t('adminPages.clientSingle.info-title')}</h5>
                <div className="summary-content">
                  <div className="summary-items summary-titles">
                    <ul>
                      <li>{t('adminPages.clientSingle.info-Id')}</li>
                      <li>{t('adminPages.clientSingle.info-DisplayName')}</li>
                      <li>{t('adminPages.clientSingle.info-ShortName')}</li>
                      <li>{t('adminPages.clientSingle.info-Status')}</li>
                      <li>{t('adminPages.clientSingle.info-Instance')}</li>
                      <li>{t('adminPages.clientSingle.info-InstanceSize')}</li>
                      <li>{t('adminPages.clientSingle.info-OutgoingQueueName')}</li>
                      <li>{t('adminPages.clientSingle.info-CreatedDate')}</li>
                      <li>{t('adminPages.clientSingle.info-UpdateDate')}</li>
                    </ul>
                  </div>
                  <div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
                    <ul>
                      <li>{loadingClientData ? <Skeleton width={'220px'} /> : clientFormValues._id}</li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="displayName"
                            value={clientFormValues.displayName}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                          ></input>
                        }
                      </li>
                      <li>{loadingClientData ? <Skeleton /> : clientFormValues.shortName}</li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <select
                            required
                            name="status"
                            value={clientFormValues.status}
                            onChange={handleInputChange}
                          >
                            {AdminClientStatus.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <select
                            required
                            name="instance"
                            value={clientFormValues.instance}
                            onChange={handleInputChange}
                          >
                            {AdminClientInstances.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="instance_size"
                            value={clientFormValues.instance_size}
                            onChange={handleInputChange}
                          ></input>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="alerts_outgoing_queue_name"
                            value={clientFormValues.alerts_outgoing_queue_name}
                            onChange={handleInputChange}
                          ></input>
                        }
                      </li>
                      <li>{loadingClientData ? <Skeleton /> : dateDisplay(clientFormValues.createdAt)}</li>
                      <li>{loadingClientData ? <Skeleton /> : dateDisplay(clientFormValues.updatedAt)}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="campaignBox-3">
                <Button
                  variant="contained"
                  startIcon={<RiSendPlane2Line />}
                  disabled={!isEnabled()}
                  sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                  }}
                  onClick={(e) => { handleSubmit(e) }}
                >
                  {t('button.submit')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<RiDeleteBin2Line />}
                  disabled={!isEnabled()}
                  sx={{
                    backgroundColor: "var(--button-background-2)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                    ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                  }}
                  onClick={() => { handleClickOpen() }}
                >
                  {t('button.delete')}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs sm="auto">
            {/* Client Operations */}
            <ClientInvoicePeriods invoice_periods={clientFormValues.invoice_periods} loadingData={loadingClientData} />
          </Grid>
          <Grid item xs={12}>
            {/* <h5>Connectors</h5> */}
            <h5>{t('adminPages.clientSingle.connectors-title')}</h5>
            <ClientConnectors client_id={clientId} />
          </Grid>
          <Grid item xs={12}>
            {/* <h5>Users</h5> */}
            <h5>{t('adminPages.clientSingle.users-title')}</h5>
            <ClientUsersTable users={usersData} loadingData={loadingData} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Dialog
          fullWidth
          maxWidth='sm'
          open={dialOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* <h5>Delete ?</h5> */}
            <h5>{t('adminPages.userSingle.dialog-title')}</h5>
          </DialogTitle>
          <DialogContent>
            {t('adminPages.userSingle.dialog-content')} "{clientFormValues.displayName}" ?
            <br />
            {t('adminPages.userSingle.dialog-content2')}
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => { handleDelete(e) }}>{t('button.confirm')}</Button>
            <Button onClick={handleClose}>{t('button.cancel')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
}

export default ClientSingle;