import '../../styles/HuntsPage.css'
import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useReactToPrint } from "react-to-print";
import { Previewer } from "pagedjs";

import { Button, Skeleton, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';

import { RiArrowGoBackLine, RiThumbUpLine, RiThumbDownLine, RiCloseLine, RiPrinterLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getHuntReport, putHuntReport, postHuntReportAccept, postHuntReportReject } from '../../services/huntReport.service';
import { getUser } from '../../services/user.service';
import Report from '../Hunts/Report';
import { UserInfosContext } from '../../context/UserInfosContext';
import { CustomDialogTitle } from '../../components/CustomDialogTitle';

const ComponentToPrint = React.forwardRef((props, ref) => {

    useLayoutEffect(() => {
        const previewer = new Previewer();
        previewer
            .preview(
                document.querySelector("#report-content").innerHTML,
                ['/report.css'],
                document.querySelector("#previewer")
            )
            .then(flow => {
                //  console.log("preview rendered, total pages", flow.total, { flow });
            });
    }, []);
    return (
        <>
            <Report huntFormValues={props.huntFormValues} />
            <div ref={ref} id='previewer'></div>
        </>)
});


function ValidateReport({ currentReport, openValidateReport, setOpenValidateReport }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    // const [loader, setLoader] = useState(true);
    // const [loader2, setLoader2] = useState(false);
    const [openAlert, setOpenAlert] = useState(true);
    const [ownerName, setOwnerName] = useState('');
    const [dialOpen, setDialOpen] = useState(false);
    const [reviewRejected, setReviewRejected] = useState(undefined);

    const { setSnackbarObj } = useContext(UserInfosContext);

    const [huntFormValues, setHuntFormValues] = useState([]);

    // validation failed indicators
    const [huntFormErrors, setHuntFormErrors] = useState({
        status: false,
        summary: false,
        description: false,
        details: false,
        proofs: false,
        ioc: false,
        cve: false,
        vulnerability: false,
        affected_systems: false,
        remediation: false,
        reject_reason: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [huntFormTouches, setHuntFormTouches] = useState({
        status: false,
        summary: false,
        description: false,
        details: false,
        proofs: false,
        ioc: false,
        cve: false,
        vulnerability: false,
        affected_systems: false,
        remediation: false,
        reject_reason: false
    });

    const {
        data: huntReport,
        isLoading: loadingHuntReport,
        error: errorHuntReport,
        failureReason: failureHuntReport,
        failureCount: failureCountHuntReport,
        isFetching: fetchingHuntReport
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!currentReport._id, // ensures the query is only executed when these conditions are met.
        queryKey: ['huntReport', { _id: currentReport._id }],
        queryFn: () => getAccessTokenSilently().then((token) => getHuntReport(token, currentReport._id)),
    });
    useEffect(() => {
        if (huntReport) { setHuntFormValues(huntReport); }
    }, [huntReport])

    const {
        data: user,
        // isLoading: loadingUser,
        error: errorUser,
        failureReason: failureUser,
        failureCount: failureCountUser,
        // isFetching: fetchingUser
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!huntFormValues.owner_id, // ensures the query is only executed when these conditions are met.
        queryKey: ['user', { _id: huntFormValues.owner_id }],
        queryFn: () => getAccessTokenSilently().then((token) => getUser(token, huntFormValues.owner_id)),
    });
    useEffect(() => {
        if (user) { setOwnerName(user.displayName + ' <' + user.email + '>'); }
    }, [user])


    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setHuntFormValues({ ...huntFormValues, [name]: value })
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        huntFormValues[name] ? setHuntFormErrors({ ...huntFormErrors, [name]: false }) : setHuntFormErrors({ ...huntFormErrors, [name]: true });
        setHuntFormTouches({ ...huntFormTouches, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {

        return (
            (huntFormValues.summary && huntFormValues.description && huntFormValues.details && huntFormValues.proofs && huntFormValues.affected_systems && huntFormValues.impact)
            &&
            // (huntFormTouches.summary && huntFormTouches.description && huntFormTouches.details && huntFormTouches.proofs && huntFormTouches.ioc && huntFormTouches.cve && huntFormTouches.vulnerability && huntFormTouches.affected_systems )
            // &&
            (!huntFormErrors.summary && !huntFormErrors.description && !huntFormErrors.details && !huntFormErrors.proofs && !huntFormErrors.affected_systems)
        )
    }

    // submit button: enabled only when everything is alright
    function rejectConfirmIsEnabled() {
        return (
            (huntFormValues.reject_reason) && (!huntFormErrors.reject_reason)
        )
    }

    const handleClickOpenDial = (ReviewChoice) => {
        setReviewRejected(ReviewChoice);
        setDialOpen(true);
    };
    const handleCloseDial = () => { setDialOpen(false) };

    const postHuntReportRejectMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!currentReport._id, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postHuntReportReject(token, currentReport._id, { reject_reason: huntFormValues.reject_reason })),
        onSuccess: (data) => {
            queryClient.setQueryData(['huntReport', { _id: currentReport._id }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);

        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntReport', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handlePostReject = (e) => {
        e.preventDefault()
        postHuntReportRejectMutation.mutate()
    }

    const postHuntReportAcceptMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!currentReport._id, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postHuntReportAccept(token, currentReport._id)),
        onSuccess: (data) => {
            queryClient.setQueryData(['huntReport', { _id: currentReport._id }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntReport', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handlePostAccept = (e) => {
        e.preventDefault()
        postHuntReportAcceptMutation.mutate()
    }

    const putHuntReportMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!currentReport._id, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => putHuntReport(token, currentReport._id, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['huntReport', { _id: currentReport._id }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('huntReport', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleBackToDraft = (e) => {
        e.preventDefault()
        putHuntReportMutation.mutate({ status: 'Draft' })
    }

    const documentPrintTitle = huntFormValues.summary;

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: documentPrintTitle
    });
    const handleClose = () => { setOpenValidateReport(false) };

    useEffect(() => {
        const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
        if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

        const snackbarHuntReport = ResponseSnackbarErrorHandler('huntReport', errorHuntReport, failureHuntReport, failureCountHuntReport)
        if (snackbarHuntReport) { setSnackbarObj(snackbarHuntReport) }

    }, [errorHuntReport, errorUser, failureCountHuntReport, failureCountUser, failureHuntReport, failureUser, setSnackbarObj]);


    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='lg'
                open={openValidateReport}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
                    {t('adminPages.ValidateReport.head-title')}
                    {ownerName ?
                        <Collapse in={openAlert}>
                            <Alert severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                        }}
                                    >
                                        <RiCloseLine fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <AlertTitle>{t('adminPages.ValidateReport.alert-writtenBy')} {ownerName}</AlertTitle>
                            </Alert>
                        </Collapse>
                        : null
                    }
                </CustomDialogTitle>
                <DialogContent>
                    {(loadingHuntReport && fetchingHuntReport) || !huntFormValues || huntFormValues.length === 0
                        ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={'60vh'} />
                        : <ComponentToPrint ref={componentRef} huntFormValues={huntFormValues} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        className='submit-button'
                        variant="contained"
                        type="submit"
                        startIcon={<RiPrinterLine />}
                        onClick={() => { handlePrint() }}
                        sx={{
                            backgroundColor: "var(--button-background-5)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                        }}
                    >
                        {t('button.print')}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        startIcon={<RiArrowGoBackLine />}
                        disabled={!isEnabled()}
                        onClick={(e) => { handleBackToDraft(e) }}
                        sx={{
                            backgroundColor: "var(--button-background-4)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                        }}
                    >
                        {t('button.backDraft')}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        startIcon={<RiThumbUpLine />}
                        disabled={!isEnabled()}
                        onClick={() => { handleClickOpenDial(false); }}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                    >
                        {t('button.accept')}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        startIcon={<RiThumbDownLine />}
                        disabled={!isEnabled()}
                        onClick={() => { handleClickOpenDial(true) }}
                        sx={{
                            backgroundColor: "var(--button-background-2)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                        }}
                    >
                        {t('button.reject')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth='xs'
                open={dialOpen}
                onClose={handleCloseDial}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {reviewRejected
                        ? t('adminPages.ValidateReport.dialog-title-Reject')
                        : t('adminPages.ValidateReport.dialog-title-Accept')
                    }
                </DialogTitle>
                {reviewRejected
                    ? <DialogContent>
                        <TextField
                            fullWidth
                            required
                            name="reject_reason"
                            label={t('adminPages.ValidateReport.field-RejectReason')}
                            variant="standard"
                            multiline
                            rows={3}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={huntFormValues.reject_reason}
                            error={huntFormTouches.reject_reason && huntFormErrors.reject_reason}
                            helperText={huntFormTouches.reject_reason && huntFormErrors.reject_reason && t('form.field-down-helper')}
                        />
                    </DialogContent>
                    : null
                }
                <DialogActions>
                    {reviewRejected
                        ? <Button onClick={(e) => { handlePostReject(e) }} disabled={!rejectConfirmIsEnabled()}>{t('button.confirm')}</Button>
                        : <Button onClick={(e) => { handlePostAccept(e) }}>{t('button.confirm')}</Button>
                    }
                    <Button onClick={handleCloseDial}>{t('button.cancel')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ValidateReport