import '../../styles/AlertsPage.css'

import { useEffect, useState, useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import AlertsTable from './AlertsTable';
import { getAllAlerts } from '../../services/alert.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function AlertsPage() {
    const { getAccessTokenSilently } = useAuth0();
    const { userInfos, setSnackbarObj } = useContext(UserInfosContext);
    const userID = userInfos._id;

    const [filteredAlertsList, setFilteredAlertsList] = useState([]);
    const [analystHasAlreadyWorkInProgress, setAnalystHasAlreadyWorkInProgress] = useState(false);
    
    const {
        data: alertsList,
        isLoading,
        error,
        failureReason,
        failureCount,
        isFetching
    } = useQuery({
        refetchInterval: !analystHasAlreadyWorkInProgress ? 5000 : false, // refresh the data every 5000ms but no need to refresh the data in case of work in progress
        enabled: !!getAccessTokenSilently && !analystHasAlreadyWorkInProgress, // ensures the query is only executed when these conditions are met.
        queryKey: ['alertsList'],
        queryFn: () => getAccessTokenSilently().then((token) => getAllAlerts(token)),
    });

    useEffect(() => {
        if (alertsList) {
            const filteredItems = alertsList.filter(item => {
                if ((item.locked) && (item.tech_id !== userID)) {
                    // Locked by other user, discarding
                    return false;
                }
                else return true;
            })
            setFilteredAlertsList(filteredItems);
        }

        if (alertsList?.find(alert => ((alert.locked) && (alert.tech_id === userID))) !== undefined) {
            // user has at least one alert locked by himself
            setAnalystHasAlreadyWorkInProgress(true);
        }else{
            setAnalystHasAlreadyWorkInProgress(false);
        }
    }, [alertsList, userID])

    useEffect(() => {
        const snackbar = ResponseSnackbarErrorHandler('alertsList',error, failureReason, failureCount)
        if (snackbar) { setSnackbarObj(snackbar) }

    }, [error, failureCount, failureReason, setSnackbarObj]);

    return (
        <div className='nhub-alert-container'>
            <div className='alert-table-container'>
                <AlertsTable alertsList={filteredAlertsList} loadingData={(isLoading && isFetching)} fetchingData={isFetching}/>
            </div>
        </div>
    )
}


export default AlertsPage