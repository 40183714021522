import nybbleLogo from '../../assets/nybble-logo/Nybble-Logo-White.svg'
import '../../styles/InvestigationsPage.css'

import { useState, useContext } from 'react'
import { useParams, useOutletContext } from 'react-router-dom'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Grid, TextField, IconButton, InputAdornment } from '@mui/material';

import { RiSendPlane2Line } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { postInvestigationComment } from '../../services/investigation.service';
import { getInitials, GetRelativeTime } from '../../utils/formatDate';
import { UserInfosContext } from '../../context/UserInfosContext';
import SkeletonInvestComments from '../../components/SkeletonInvestComments';


function InvestComments() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { investigation, loadingInvestigationData } = useOutletContext();
    const { setSnackbarObj } = useContext(UserInfosContext);

    const nl2br = require('react-nl2br');

    let { investId } = useParams();
    const [investCommentFieldValue, setInvestCommentFieldValue] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(false);
    let listOfComments = investigation?.comments || {};

    const handleChange = (event) => {
        setInvestCommentFieldValue(event.target.value);
        if (event.target.value.length > 0) {
            setButtonEnabled(true)
        }
        else {
            setButtonEnabled(false)
        }
    };

    const postInvestigationCommentMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postInvestigationComment(token, investId, { comment: investCommentFieldValue })),
        onSuccess: (data) => {
            queryClient.setQueryData(['investigation', { _id: investigation._id }], (oldData) => ({ ...oldData ?? {}, comments: [...(oldData?.comments ?? []), data.comment] })); //update query data by adding comment to the array 'comments' 
            if (data.message === "Comment added successfully") {
                setInvestCommentFieldValue('');
                // adding the last comment to the state, to trigg display
                let newListOfComments = [...listOfComments]
                newListOfComments.push(data.comment);
                listOfComments = newListOfComments;
                document.getElementById('comments-container').scrollTo(0, 0);
            }
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('investigation', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }

            document.getElementById('comments-container').scrollTo(0, 0);
        }
    })
    const postComment = (e) => {
        e.preventDefault()
        postInvestigationCommentMutation.mutate()
    }


    return (
        <div>
            <div className="comments-container" id='comments-container'>
                {loadingInvestigationData
                    ? <SkeletonInvestComments rows={10}/>
                    : (!listOfComments || listOfComments.length === 0)
                        ? <p>{t('investigations.investComments.info-noData')}</p>
                        : listOfComments.slice(0).reverse().map((element, i) => (
                                <div key={i} className='comment-box widget'>

                                    {(element.profile === 'Admin')
                                        ? (
                                            <div className='avatar'>
                                                <img src={nybbleLogo} alt='nybbleLogo' />
                                            </div>
                                        ) : (
                                            <div className='avatar'>
                                                {getInitials(element.author)}
                                            </div>
                                        )}

                                    <div>
                                        <span className='comment-user'>
                                            {element.author}
                                        </span>
                                        <span className='comment-date'>
                                            {t('investigations.investComments.posted')} {GetRelativeTime(element.timestamp, t('lang'))}
                                        </span>
                                    </div>
                                    <p className='comment-value'>
                                        {nl2br(element.content)}
                                    </p>
                                </div>
                            ))
                        
                }
            </div>
            <div className='comment-input'>
                <Grid container spacing={0}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label={t('investigations.investComments.sendComment-label')}
                            fullWidth
                            multiline
                            maxRows={4}
                            value={investCommentFieldValue}
                            onChange={handleChange}
                            sx={{ backgroundColor: "var(--box-background)" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ paddingRight: '1em' }}>
                                        <IconButton
                                            aria-label="send a comment"
                                            title={t('investigations.investComments.sendComment-helper')}
                                            edge="end"
                                            disabled={!buttonEnabled}
                                            onClick={(e) => { postComment(e); }}
                                        >
                                            <RiSendPlane2Line color={buttonEnabled ? 'var(--nhub-color1)' : 'lightgrey'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
        </div>
    )
}

export default InvestComments