import { Skeleton } from '@mui/material';

function SkeletonInvestComments({ rows }) {
  return (
    Array.from(Array(rows), (e, i) => {
      return (
        <div key={i} className='comment-box widget'>
          <div className='avatar avatarSkeleton'>
            <Skeleton variant='img' width={50} height={50} />
          </div>
          <div>
            <span className='comment-user'>
              <Skeleton width={'150px'} />
            </span>
            <span className='comment-date'>
              <Skeleton width={'80px'} />
            </span>
          </div>
          <p className='comment-value'>
            <Skeleton  />
          </p>
        </div>
      )
    })
  );
};
export default SkeletonInvestComments;