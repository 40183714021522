/*
    Everything to push / pull data from HuntCampaign API
*/


import axios from "axios";
import { calc_nhub_api } from '../context/envURL'

const API_HUNT_CAMPAIGN_URL = `${calc_nhub_api()}/api/hunt-campaign/`

export function getAllHuntCampaign(token) {
    return axios
        .get(API_HUNT_CAMPAIGN_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

//Save a campaign
export function postHuntCampaign(token, content) {
    return axios
        .post(
            API_HUNT_CAMPAIGN_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
}


export function getHuntCampaign(token, id) {
    return axios
        .get(API_HUNT_CAMPAIGN_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

//Edit and Submit a report
export function putHuntCampaign(token, id, content) {
    return axios
        .put(
            API_HUNT_CAMPAIGN_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
        .catch(error => { throw error });
}

export function getHuntCampaignReports(token, id) {
    return axios
        .get(API_HUNT_CAMPAIGN_URL + id + '/reports', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}


