import '../../styles/HuntsPage.css'
import '../../styles/PreviewReport.css'
// import logoText3 from '../../assets/nybble-logo/Nybble-Logo-Text3.svg'

import React, { useRef, useLayoutEffect } from 'react'

import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Previewer } from "pagedjs";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { RiPrinterLine } from "react-icons/ri";

import Report from './Report';
import { CustomDialogTitle } from '../../components/CustomDialogTitle';

// hook react-to-print --> https://opensourcelibs.com/lib/react-to-print
const ComponentToPrint = React.forwardRef((props, ref) => {

  useLayoutEffect(() => {
    const previewer = new Previewer();
    previewer
      .preview(
        document.querySelector("#report-content").innerHTML,
        ['/report.css'],
        document.querySelector("#previewer")
      )
      .then(flow => {
        //console.log("preview rendered, total pages", flow.total, { flow });
      });
  }, []);
  return (
    <>
      <Report huntFormValues={props.huntFormValues} />
      <div ref={ref} id='previewer'></div>
    </>)
});




function PreviewReport({huntFormValues, setOpenPreviewReport, openPreviewReport }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const documentPrintTitle = huntFormValues.summary;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: documentPrintTitle
  });

  const handleClose = () => { setOpenPreviewReport(false) };

  return (

    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='lg'
        open={openPreviewReport}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
          {/* Preview Report */}
          {t('button.previewReport')}
        </CustomDialogTitle>
        <DialogContent className='report-preview-content'>
          <ComponentToPrint ref={componentRef} huntFormValues={huntFormValues} />
        </DialogContent>
        <DialogActions>
          <Button
            className='submit-button'
            variant="contained"
            type="submit"
            startIcon={<RiPrinterLine />}
            onClick={() => { handlePrint() }}
            sx={{
              backgroundColor: "var(--button-background-5)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px',
              ':hover': { backgroundColor: "var(--button-background-hover-5)" }
            }}
          >
            {/* Print */}
            {t('button.print')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}



export default PreviewReport