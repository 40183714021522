import { useEffect, useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getUser } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';


export function useGetTechDetails(userId) {
    const { getAccessTokenSilently } = useAuth0();
    const { setSnackbarObj } = useContext(UserInfosContext);

    const {
        data: user,
        error,
        failureReason,
        failureCount,
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!userId,
        queryKey: ['user', { _id: userId }],
        queryFn: () => getAccessTokenSilently().then((token) => getUser(token, userId)),
    });

    useEffect(() => {
        const snackbar = ResponseSnackbarErrorHandler('user', error, failureReason, failureCount);
        if (snackbar) {
            setSnackbarObj(snackbar);
        }
    }, [error, failureCount, failureReason, setSnackbarObj]);

    return user;
}
