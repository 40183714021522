/*
    Everything to push / pull data from investigation API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_INVESTIGATIONS_URL = `${calc_nhub_api()}/api/investigation/`

export function getAllInvestigations(token) {
    return axios
        .get(API_INVESTIGATIONS_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

export function getInvestigation(token, investId) {
    return axios
        .get(API_INVESTIGATIONS_URL + '/' + investId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

export function postInvestigationComment(token, investId, investComment) {
    return axios
        .post(
            API_INVESTIGATIONS_URL + 'comment/' + investId,
            investComment,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

// Download a config
export function downloadReport({ token, investId }) {
    return axios
        .get(API_INVESTIGATIONS_URL + '/' + investId + '/report', {
            responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}