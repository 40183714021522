import '../styles/HuntsPage.css'

import { useState } from 'react'

import { Tooltip, IconButton, ClickAwayListener } from '@mui/material';

import { RiInformationLine } from "react-icons/ri";


function InfoTooltip({ text }) {

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };


  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          title={text}
          arrow
          onOpen={handleTooltipOpen}
          open={open}
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            onClick={handleTooltipOpen}
          >
            <RiInformationLine />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}
export default InfoTooltip;