import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';

import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { dateDisplay } from '../../utils/formatDate';
import { DefaultColumnFilter } from '../../components/TableFilters';
import { getPendingWikiContributions } from '../../services/wikiContribution.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function WikisTable({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Title'),
        accessor: 'wiki_page_name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
        Cell: ({ row, cell: { value } }) =>
          <div className="row-title">
            <Link to={{
              pathname: '/admin/pendingWikis/' + row.original._id
              // state: { value },
            }}
              values={value} title="Contribution">{value} </Link>
          </div>
      },
      {
        Header: t('tables.header-Path'),
        accessor: 'wiki_file_path',
        Filter: false,
        canSorter: false,
      },
      {
        Header: t('tables.header-Owner'),
        accessor: 'owner_display_name',
        Filter: false,
        canSorter: false,
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },

    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead invest-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(!data || data.length === 0)
            ? <p style={{ margin: '15px' }}>{t('adminPages.pendingWikisTable.info-noData')}</p>
            : rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}


function PendingWikisTable() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: pendingWikisList,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['pendingWikisList'],
    queryFn: () => getAccessTokenSilently().then((token) => getPendingWikiContributions(token)),
  });

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('pendingWikisList', error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }
  }, [error, failureCount, failureReason, setSnackbarObj]);

  const headers = [
    { header: t('tables.header-Title'), },
    { header: t('tables.header-Path') },
    { header: t('tables.header-Owner') },
    { header: t('tables.header-CreatedDate'), enableSorting:true  },
    { header: t('tables.header-UpdateDate'), enableSorting:true  },
  ]

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if (isLoading && isFetching) {
    return (
      <div className='tableFixHead invest-table'>
        <SkeletonTable columns={headers} rows={5} />
      </div>
    )
  }

  return (<WikisTable data={pendingWikisList} />);
}


export default PendingWikisTable;