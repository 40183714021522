import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { dateDisplay } from '../../utils/formatDate';
import { SelectColumnFilter } from '../../components/TableFilters';


function ReportsTable({ reports, campaignDetails, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { header: t('tables.header-Title') },
    { header: t('tables.header-CreatedDate'), enableSorting:true },
    { header: t('tables.header-Impact'), enableColumnFilter:true },
    { header: t('tables.header-Status'), enableColumnFilter:true },
    { header: t('tables.header-RejectReason') },
  ]

  if (loadingData) return (
    <div className='tableFixHead report-table'>
      <SkeletonTable columns={headers} rows={10} />
    </div>
  )

  if (!reports || reports.length === 0) {
    return <p>{t('hunts.reportsTable.info-noData')}</p>
  } else {
    return (<Table campaignId={campaignDetails?._id} data={reports} />);
  }
}

function Table({ data, campaignId }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Title'),
        accessor: 'summary', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
        Cell: ({ row, cell: { value } }) =>
          <div className="row-title">
            <Link to={{
              pathname: '/campaigns/' + campaignId + '/report/' + row.original._id
              // state: { value },
            }}
              values={value} title="Report">{value} </Link>
          </div>
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
      {
        Header: t('tables.header-Impact'),
        accessor: 'impact',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
      },
      {
        Header: t('tables.header-RejectReason'),
        accessor: 'reject_reason',
        Filter: false,
        canSorter: false,
      },
    ],
    [t, campaignId]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      //  defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead report-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id}{...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td key={cell.id}{...cell.getCellProps()} >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ReportsTable;