import '../../styles/Tables.css'

import { useState, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Grid, Button, TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { Dialog, DialogContent, DialogActions } from '@mui/material';

import { RiSave2Line, RiEyeLine, RiEyeOffLine, RiRestartLine, RiFileCopyLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { postUser } from '../../services/user.service';
import { AdminUserLanguage, AdminUserProfile } from '../../data/constants';
import { UserInfosContext } from '../../context/UserInfosContext';
import { CustomDialogTitle } from '../../components/CustomDialogTitle';
import { randomPassword } from '../../utils/generatePassword';

function CreateUser(props) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { setSnackbarObj } = useContext(UserInfosContext);
    const [showPassword, setShowPassword] = useState(false);

    // react state. used for field validation and initial display
    const [userFormValues, setUserFormValues] = useState({
        displayName: '',
        email: '',
        password: '',
        profile: '',
        client_id: ['*'],
        language: 'en'
    });

    // validation failed indicators
    const [userFormErrors, setUserFormErrors] = useState({
        displayName: false,
        email: false,
        password: false,
        profile: false,
        client_id: false,
        language: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [userFormTouches, setUserFormTouches] = useState({
        displayName: false,
        email: false,
        password: false,
        profile: false,
        client_id: false,
        language: false
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setUserFormValues({ ...userFormValues, [name]: value })
    }
    // update the react state with user input
    // QLS :  set userFormValues.client_id's value to ['*'] when the user profile is different from 'Client'
    const handleInputChangeProfile = e => {
        const { name, value } = e.target
        setUserFormValues({ ...userFormValues, [name]: value })
        setUserFormValues(userFormValues => ({ ...userFormValues, client_id: ['*'] }));
    }
    // update the react state with user input
    // QLS :  set userFormValues.client_id's value in an array
    const handleInputChangeClient_id = e => {
        const { name, value } = e.target
        setUserFormValues({ ...userFormValues, [name]: [value] })
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        userFormValues[name] ? setUserFormErrors({ ...userFormErrors, [name]: false }) : setUserFormErrors({ ...userFormErrors, [name]: true });
        setUserFormTouches({ ...userFormTouches, [name]: true });
    }

    // generate random password
    const generatePassword = () => {
      const pwd = randomPassword()
      setUserFormValues(userFormValues => ({ ...userFormValues, password: pwd }));
    }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    function handlePasswordCopy() {
      setSnackbarObj({
          message: t("snackbar.copied"),
          status: 'success',
          timestamp: new Date().getTime()
      })
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (userFormValues.displayName && userFormValues.email && userFormValues.password && userFormValues.profile)
            &&
            (!userFormErrors.displayName && !userFormErrors.email && !userFormErrors.password && !userFormErrors.profile)
        )
    }

    const postUserMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postUser(token, userFormValues)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('user', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSave = (e) => {
        e.preventDefault()
        postUserMutation.mutate()
    }

    const handleClose = () => { props.setOpenCreateUser(false) };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={props.openCreateUser}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
                    {/* New user */}
                    {t('adminPages.createUser.head-title')}
                </CustomDialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                name="displayName"
                                label={t('adminPages.createUser.field-DisplayName')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={userFormValues.displayName}
                                error={userFormTouches.displayName && userFormErrors.displayName}
                                helperText={userFormTouches.displayName && userFormErrors.displayName && t('form.field-down-helper')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                name="email"
                                label={t('adminPages.createUser.field-Email')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={userFormValues.email}
                                error={userFormTouches.email && userFormErrors.email}
                                helperText={userFormTouches.email && userFormErrors.email && t('form.field-down-helper')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                name="password"
                                label={t('adminPages.createUser.field-Password')}
                                type={showPassword ? 'text' : 'password'}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={userFormValues.password}
                                error={userFormTouches.password && userFormErrors.password}
                                helperText={userFormTouches.password && userFormErrors.password && t('form.field-down-helper')}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <CopyToClipboard text={userFormValues.password}
                                            onCopy={() => handlePasswordCopy()}
                                          >
                                            <IconButton
                                                title={t('button.label-PasswordCopy')}
                                                aria-label={t('button.label-PasswordCopy')}
                                            >
                                                <RiFileCopyLine />
                                            </IconButton>
                                          </CopyToClipboard>
                                          <IconButton
                                              title={t('adminPages.createUser.label-PasswordVisibility')}
                                              aria-label={t('adminPages.createUser.label-PasswordVisibility')}
                                              onClick={handleClickShowPassword}
                                          >
                                              {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                          </IconButton>
                                          <IconButton
                                              title={t('adminPages.createUser.label-GeneratePassword')}
                                              aria-label={t('adminPages.createUser.label-GeneratePassword')}
                                              onClick={generatePassword}
                                          >
                                              <RiRestartLine />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                required
                                select
                                name="profile"
                                label={t('adminPages.createUser.field-Profile')}
                                variant="standard"
                                onChange={handleInputChangeProfile}
                                onBlur={handleBlur}
                                key={`TextField-${userFormValues.profile}`}
                                defaultValue={userFormValues.profile}
                                error={userFormTouches.profile && userFormErrors.profile}
                                helperText={userFormTouches.profile && userFormErrors.profile && t('form.field-down-helper')}
                            >
                                {AdminUserProfile.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                select
                                name="client_id"
                                label={t('adminPages.createUser.field-Client')}
                                variant="standard"
                                onChange={handleInputChangeClient_id}
                                onBlur={handleBlur}
                                key={`TextField-${userFormValues.client_id}`}
                                defaultValue={userFormValues.client_id}
                                disabled={userFormValues.profile !== 'Client'}
                            >
                                {props.clientsList.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.displayName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                select
                                name="language"
                                label={t('adminPages.createUser.field-Language')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                key={`TextField-${userFormValues.language}`}
                                defaultValue='en'
                            >
                                {AdminUserLanguage.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isEnabled()}
                        onClick={(e) => { handleSave(e) }}
                        startIcon={<RiSave2Line />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                    >
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CreateUser;