import { useState, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, Alert, AlertTitle, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { RiRestartLine, RiDeleteBin2Line, RiAddLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { dateDisplay } from '../../utils/formatDate';
import { postApikey, deleteApikey } from '../../services/user.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function ProfilePageApiKey() {

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient()

	const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

	const apikeyInfo = userInfos.apikey
	const [apikeyValue, setApikeyValue] = useState('');
	const [apikeyCopied, setApikeyCopied] = useState(false);
	const [apikeyVisible, setApikeyVisible] = useState(false);
	const [apikeyDialOpen, setApikeyDialOpen] = useState(false);

	const [renewInitiated, setRenewInitiated] = useState(false);
	const [deleteInitiated, setDeleteInitiated] = useState(false);

	const handleCloseApikeyDial = () => {
		setApikeyDialOpen(false)
		setApikeyVisible(false)
		setApikeyCopied(false)
	};

	const handleShowApikey = () => { setApikeyVisible(!apikeyVisible) };

	/* Confirmation before renew */
	const handleRenewConfirmationClose = () => { setRenewInitiated(false) };
	const handleRenewConfirmationAccepted = (e) => { handleRenewConfirmationClose(); handleCreateApikey(e) };

	/* Confirmation before delete */
	const handleDeleteConfirmationClose = () => { setDeleteInitiated(false) };
	const handleDeleteConfirmationAccepted = (e) => { handleDeleteConfirmationClose(); handleDeleteApikey(e) };

	const postApikeyMutation = useMutation({
		enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => postApikey(token)),
		onSuccess: (data) => {
			queryClient.setQueryData(['myUserInfo'], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data
			setSnackbarObj({
				message: t("snackbar.created"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			setApikeyValue(data.apikey.value)
			setApikeyDialOpen(true)
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleCreateApikey = (e) => {
		e.preventDefault()
		postApikeyMutation.mutate()
	}

	const deleteApikeyMutation = useMutation({
		enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => deleteApikey(token)),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['myUserInfo'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.deleted"),
				status: 'success',
				timestamp: new Date().getTime()
			})
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleDeleteApikey = (e) => {
		e.preventDefault()
		deleteApikeyMutation.mutate()
	}


	return (
		<>
			<div className="flexbox-summary">
				<div className="widget">
					<h5>{t('profile.apikey-title')}</h5>
					{apikeyInfo && apikeyInfo != null
						? <div className="summary-content">
							<div className="summary-items summary-titles">
								<ul>
									<li>{t('profile.apikey-createdAt')}</li>
									<li>{t('profile.apikey-expiringAt')}</li>
									<li>{t('profile.apikey-lastUsed')}</li>
								</ul>
							</div>
							<div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
								<ul>
									<li>
										{dateDisplay(apikeyInfo.createdAt)}
									</li>
									<li>
										{dateDisplay(apikeyInfo.expiringAt)}
									</li>
									<li>
										{apikeyInfo.lastUsedAt ? dateDisplay(apikeyInfo.lastUsedAt) : 'Never'}
									</li>
								</ul>
							</div>
						</div>
						: null
					}
					<div className='widget-submit-btn'>
						{apikeyInfo && apikeyInfo != null
							?
							<>
								<Button
									variant="contained"
									startIcon={<RiRestartLine />}
									sx={{
										backgroundColor: "var(--button-background-4)",
										textTransform: 'none',
										fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
										':hover': { backgroundColor: "var(--button-background-hover-4)" }
									}}
									onClick={() => { setRenewInitiated(true); }}
								>
									{t('button.renew')}
								</Button>
								<Button
									variant="contained"
									startIcon={<RiDeleteBin2Line />}
									sx={{
										backgroundColor: "var(--button-background-2)",
										textTransform: 'none',
										fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
										':hover': { backgroundColor: "var(--button-background-hover-2)" }
									}}
									onClick={() => { setDeleteInitiated(true); }}
								>
									{t('button.delete')}
								</Button>
							</>
							:
							<Button
								variant="contained"
								startIcon={<RiAddLine />}
								sx={{
									backgroundColor: "var(--button-background-6)",
									textTransform: 'none',
									fontSize: '14px', fontWeight: '400', padding: '6px 10px',
									':hover': { backgroundColor: "var(--button-background-hover-6)" }
								}}
								onClick={(e) => { handleCreateApikey(e) }}
							>
								{t('button.create')}
							</Button>
						}
					</div>
				</div>
			</div>
			<Dialog
				fullWidth
				maxWidth='md'
				open={apikeyDialOpen}
				scroll={'paper'}
				onClose={handleCloseApikeyDial}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<h5>{t('profile.apikey-title')}</h5>
				</DialogTitle>
				<DialogContent>
					<Alert severity="info" sx={{ mb: 2 }}>
						<AlertTitle>{t('profile.apikeyAlert-text')}</AlertTitle>
					</Alert>
					<pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
						{apikeyVisible
							? apikeyValue
							: '********************'
						}
					</pre>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleShowApikey}>
						{apikeyVisible
							? t('profile.apikeyAlert-button-hide')
							: t('profile.apikeyAlert-button-show')
						}
					</Button>
					<CopyToClipboard text={apikeyValue}
						onCopy={() => setApikeyCopied(true)}>
						<Button>
							{t('profile.apikeyAlert-button-copy')}
						</Button>
					</CopyToClipboard>
					<Button
						onClick={handleCloseApikeyDial}
						disabled={!apikeyCopied}
					>
						{t('profile.apikeyAlert-button-close')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth
				maxWidth='sm'
				open={renewInitiated}
				onClose={handleRenewConfirmationClose}
				aria-labelledby="apikey-renew-confirm"
				aria-describedby="apikey-renew-confirm"
			>
				<DialogTitle id="apikey-renew-confirm-title">
					<h5>{t('profile.apikeyRenew-Confirm-title')}</h5>
				</DialogTitle>
				<DialogContent>
					{t('profile.apikeyRenew-Confirm-text-line1')} <br /> {t('profile.apikeyRenew-Confirm-text-line2')}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRenewConfirmationAccepted}>
						{t('profile.apikeyRenew-Confirm-yes')}
					</Button>
					<Button onClick={handleRenewConfirmationClose}>
						{t('profile.apikeyRenew-Confirm-no')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth
				maxWidth='sm'
				open={deleteInitiated}
				onClose={handleDeleteConfirmationClose}
				aria-labelledby="apikey-delete-confirm"
				aria-describedby="apikey-delete-confirm"
			>
				<DialogTitle id="apikey-delete-confirm-title">
					<h5>{t('profile.apikeyDelete-Confirm-title')}</h5>
				</DialogTitle>
				<DialogContent>
					{t('profile.apikeyDelete-Confirm-text-line1')} <br /> {t('profile.apikeyDelete-Confirm-text-line2')}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteConfirmationAccepted}>
						{t('profile.apikeyDelete-Confirm-yes')}
					</Button>
					<Button onClick={handleDeleteConfirmationClose}>
						{t('profile.apikeyDelete-Confirm-no')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ProfilePageApiKey