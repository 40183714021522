/**
 * @description Set api URL in function of which env we are.
 * We use this block of code in order to manage the calls API.
 * @returns string
 */

export function calc_nhub_api(){
    var __nhub_api_rooturl;
    if(process.env.REACT_APP_NHUB_API){
        // defined, let's look at
        switch(process.env.REACT_APP_NHUB_API){
            case "production":
                __nhub_api_rooturl = "https://api.hub.nybble-analytics.io"
                break;
            case "staging":
                __nhub_api_rooturl = "https://api.hub-staging.nybble-analytics.io"
                break;
            case "demo":
                __nhub_api_rooturl = "https://api.hub-demo.nybble-analytics.io"
                break;
            default:
                __nhub_api_rooturl = "https://api.hub-dev.nybble-analytics.io"
                break;
        }
    }
    else {
        // assuming dev     
        __nhub_api_rooturl = "https://api.hub-dev.nybble-analytics.io"
    }

    return __nhub_api_rooturl;
}