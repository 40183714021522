import React, { useState } from "react";

import { priorityTranslator } from "../utils/formatDate";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import { RiFilter2Line } from "react-icons/ri";

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <TextField
              value={filterValue || ""}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                color: "var(--table-th-text",
              }}
              variant="standard"
              placeholder={`Search ${count} records...`}
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  // Render a multi-select box
  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <Select
              MenuProps={{
                disablePortal: true
              }}
              value={filterValue}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined);
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                backgroundColor: "var(--nhub-color-background-1)",
              }}
              variant="outlined"
              autoWidth
            >
              <MenuItem value="" sx={{ fontSize: 12 }}>
                <em>All</em>
              </MenuItem>
              {options.map((option, i) => (
                <MenuItem key={i} value={option} sx={{ fontSize: 12 }} >
                  <em>{option}</em>
                </MenuItem>
              ))}
            </Select>
          </div>
        </ClickAwayListener>

      ) : null}

    </>
  );
}

// This is a custom filter UI for selecting priority by number
// and render a priority name instead
export function SelectPriorityColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  // Render a multi-select box0
  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <Select
              MenuProps={{
                disablePortal: true
              }}
              value={filterValue}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined);
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                backgroundColor: "var(--nhub-color-background-1)",
              }}
              variant="outlined"
              autoWidth
            >
              <MenuItem value={undefined} sx={{ fontSize: 12 }}>
                <em>All</em>
              </MenuItem>
              {options.map((option, i) => (
                <MenuItem key={i} value={option} sx={{ fontSize: 12 }}>
                  <em>{priorityTranslator(option)}</em>
                </MenuItem>
              ))}
            </Select>
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
}