import '../../styles/Tables.css'
import '../../styles/InvestigationsPage.css'

import React from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { dateDisplay, priorityTranslator } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter, SelectPriorityColumnFilter } from '../../components/TableFilters';


function InvestigationsTable({ investigationList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { header: t('tables.header-Name'), width: '210px' },
    { header: t('tables.header-Priority'), enableColumnFilter:true },
    { header: t('tables.header-Status'), enableColumnFilter:true },
    { header: t('tables.header-CreatedDate'), enableSorting:true },
    { header: t('tables.header-UpdateDate'), enableSorting:true }
  ]

  if (loadingData) return (
    <div className='tableFixHead invest-table'>
      <SkeletonTable columns={headers} rows={20} />
    </div>
  )

  return (<Table data={investigationList} />);
}



function Table({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Name'),
        accessor: 'name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        className: 'col-name',
        Cell: ({ row, cell: { value } }) =>
          <div className="row-title">
            <Link to={{
              pathname: '/investigations/' + row.original._id,
              state: { value },
            }}
              values={value} title="Summary">{value} </Link>
          </div>
      },
      {
        Header: t('tables.header-Priority'),
        accessor: 'priority',
        Filter: SelectPriorityColumnFilter,
        filter: 'equals',
        canSorter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{priorityTranslator(value)}</>
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>
      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead invest-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? < FaSortDown color='var(--table-th-filter-active)' />
                            : < FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id}{...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td key={cell.id}{...cell.getCellProps({
                    }
                    )} >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
export default InvestigationsTable;