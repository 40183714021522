/*
    Everything to push / pull data from connector API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_CONNECTOR_URL = `${calc_nhub_api()}/api/connector/`

//Get all clients
export function getAllConnectors(token) {
    return axios
        .get(API_CONNECTOR_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Delete a connector by his ID
export function deleteConnector(token, connectorId) {
    return axios
        .delete(API_CONNECTOR_URL + connectorId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Add a connector
export function postConnector(token, content) {
    return axios
        .post(
            API_CONNECTOR_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//reset a connector
export function resetConnector(token, connector_id) {
    return axios
        .post(
            API_CONNECTOR_URL + connector_id + '/reset',
            connector_id,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Edit a connector
export function putConnector(token, id, content) {

    return axios
        .put(
            API_CONNECTOR_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

// Download yaml for elastic
export function downloadElasticConfig({ token, connectorId }) {
    return axios
        .get(API_CONNECTOR_URL + '/' + connectorId + '/elastic_sso', {
            responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

// Download yaml for sekoia
export function downloadSekoiaConfig({ token, connectorId }) {
    return axios
        .get(API_CONNECTOR_URL + '/' + connectorId + '/sekoia_sso', {
            responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}