import '../../styles/Connectors.css'
//import sekoialogo from '../../assets/images/connectors/sekoia-logo.svg';

import { useState, useRef, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { deleteConnector, putConnector, postConnector, resetConnector } from "../../services/connector.service";
import { UserInfosContext } from '../../context/UserInfosContext';


export default function Connector({ ConnectorForm, logo, connector_type, initialState, callbackAdd, callbackDelete, tempId, connectorInfos, client_id, callbackCancelAdd }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const displayNameRef = useRef();
    const siemUrlRef = useRef();
    const ssoEnabledRef = useRef();

    const addMode = (initialState === 'add');
    const [addedId, setAddedId] = useState(undefined);
    const [editMode, setEditMode] = useState((initialState === 'edit'));
    const [viewMode, setViewMode] = useState((initialState === 'view'));
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [resetPasswordConfirmationDialog, setResetPasswordConfirmationDialog] = useState(false);

    const [passwordDisplayDialog, setPasswordDisplayDialog] = useState(false);
    const [passwordValue, setPasswordValue] = useState(undefined);
    const [passwordCopied, setPasswordCopied] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { setSnackbarObj } = useContext(UserInfosContext);

    /* password display closure */
    function handlePasswordDisplayClosure(e) {
        if (addMode) {
            callbackAdd({
                _id: addedId,
                displayName: displayNameRef.current.value,
                siem_url: (ssoEnabledRef.current.checked ? siemUrlRef.current.value : ''),
                sso_enabled: ssoEnabledRef.current.checked,
                connector_type: connector_type
            }, tempId); // time to move the connector from addedList to currentList
        }
        // view mode, just close the dialog
        setPasswordValue(undefined);
        setIsPasswordVisible(false);
        setPasswordCopied(false);
        setPasswordDisplayDialog(false);
    }

    const postConnectorMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postConnector(token, {
            displayName: displayNameRef.current.value,
            siem_url: (ssoEnabledRef.current.checked ? siemUrlRef.current.value : ''),
            sso_enabled: ssoEnabledRef.current.checked,
            connector_type: connector_type,
            client_id: client_id
        })),
        onSuccess: (data) => {
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setPasswordValue(data.password);
            setAddedId(data.connector);
            setPasswordDisplayDialog(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('connector', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    /* save of an add -> password display */
    const handleAdd = (e) => {
        e.preventDefault()
        postConnectorMutation.mutate()
    }

    const putConnectorMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!connectorInfos?._id, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putConnector(token, connectorInfos?._id, { displayName: displayNameRef.current.value, siem_url: (ssoEnabledRef.current.checked ? siemUrlRef.current.value : ''), sso_enabled: ssoEnabledRef.current.checked })),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['connectorsList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setEditMode(false);
            setViewMode(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('connector', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    /* save of an update */
    const handleUpdate = (e) => {
        e.preventDefault()
        putConnectorMutation.mutate()
    }

    function cancelUpdate(e) {
        setEditMode(false);
        setViewMode(true);
        setDeleteDialog(false);
        setResetPasswordConfirmationDialog(false);
    }

    function startUpdate(e) {
        setEditMode(true);
        setViewMode(false);
    }

    const resetConnectorMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!connectorInfos?._id, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => resetConnector(token, connectorInfos?._id)),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['connectorsList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setPasswordValue(data.password);
            setPasswordDisplayDialog(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('connector', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleResetPassword = (e) => {
        e.preventDefault()
        resetConnectorMutation.mutate()
        setResetPasswordConfirmationDialog(false);
    }

    const deleteConnectorMutation = useMutation({
        enabled: !!getAccessTokenSilently && !!connectorInfos?._id, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => deleteConnector(token, connectorInfos?._id)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['connectorsList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            callbackDelete(connectorInfos?._id); // that will delete the element on page
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('connector', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleDelete = (e) => {
        e.preventDefault()
        deleteConnectorMutation.mutate()
        setDeleteDialog(false);
    }

    return (
        <div className='widget clientSettings-widget'>
            {/* Reset Password Confirmation */}
            <Dialog
                fullWidth
                maxWidth='sm'
                open={resetPasswordConfirmationDialog}
                onClose={cancelUpdate}
                aria-labelledby="connector-reset-confirm"
                aria-describedby="connector-reset-confirm"
            >
                <DialogTitle id="connector-reset-confirm-title">
                    <p style={{ color: 'var(--title-1)' }}>{t('clientSettings.connectors-common.dialog-resetPwd-title')}</p>
                </DialogTitle>
                <DialogContent>
                    {t('clientSettings.connectors-common.dialog-resetPwd-line1')} <br /> {t('clientSettings.connectors-common.dialog-resetPwd-line2')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResetPassword}>
                        {t('clientSettings.connectors-common.dialog-resetPwd-yes')}
                    </Button>
                    <Button onClick={cancelUpdate}>
                        {t('clientSettings.connectors-common.dialog-resetPwd-no')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Deletion dialog confirmation */}
            <Dialog
                fullWidth
                maxWidth='sm'
                open={deleteDialog}
                onClose={cancelUpdate}
                aria-labelledby="connector-delete-confirm"
                aria-describedby="connector-delete-confirm"
            >
                <DialogTitle id="connector-delete-confirm-title">
                    <p style={{ color: 'var(--title-1)' }}>{t('clientSettings.connectors-common.dialog-deleteConnector-title')}</p>
                </DialogTitle>
                <DialogContent>
                    {t('clientSettings.connectors-common.dialog-deleteConnector-line1')} <br /> {t('clientSettings.connectors-common.dialog-deleteConnector-line2')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        {t('clientSettings.connectors-common.dialog-deleteConnector-yes')}
                    </Button>
                    <Button onClick={cancelUpdate}>
                        {t('clientSettings.connectors-common.dialog-deleteConnector-no')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Display Password Dialog */}
            <Dialog
                fullWidth
                maxWidth='md'
                open={passwordDisplayDialog}
                scroll={'paper'}
                onClose={() => setPasswordDisplayDialog(false)}
                aria-labelledby="connector-pwd-display-title"
                aria-describedby="connector-pwd-display-description"
            >
                <DialogTitle id="connector-pwd-display-title">
                    <h5>{t('clientSettings.connectors-common.dialog-displayPassword-title')}</h5>
                </DialogTitle>
                <DialogContent>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        <AlertTitle>{t('clientSettings.connectors-common.dialog-displayPassword-info')}</AlertTitle>
                    </Alert>
                    <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {isPasswordVisible
                            ? passwordValue
                            : '********************'
                        }
                    </pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPasswordVisible((current) => !current)}>
                        {isPasswordVisible
                            ? t('clientSettings.connectors-common.dialog-displayPassword-hideButton')
                            : t('clientSettings.connectors-common.dialog-displayPassword-showButton')
                        }
                    </Button>
                    <CopyToClipboard text={passwordValue}
                        onCopy={() => setPasswordCopied(true)}>
                        <Button>
                            {t('clientSettings.connectors-common.dialog-displayPassword-copyButton')}
                        </Button>
                    </CopyToClipboard>
                    <Button
                        onClick={() => handlePasswordDisplayClosure(false)}
                        disabled={!passwordCopied}
                    >
                        {t('clientSettings.connectors-common.dialog-displayPassword-closeButton')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Edit / add / view form */}
            <Grid container
                spacing={2}
                direction={{ xs: "column", md: 'row' }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} md='auto'>
                    {/* left image, becomes top on small devices */}
                    <img src={logo} alt="" width='50px' />
                </Grid>
                <Grid item md>
                    {/* Form */}
                    <form onSubmit={addMode ? (handleAdd) : (handleUpdate)} className="connectors-form">
                        {/* Form buttons and fields are moving on purpose */}
                        <Grid container spacing={2} direction={{ xs: "column", md: 'row' }} alignItems='center'>
                            <Grid item md>
                                <ConnectorForm
                                    displayNameRef={displayNameRef}
                                    siemUrlRef={siemUrlRef}
                                    ssoEnabledRef={ssoEnabledRef}
                                    addMode={addMode}
                                    viewMode={viewMode}
                                    editMode={editMode}
                                    connectorInfos={connectorInfos}
                                />
                            </Grid>
                            <Grid item>
                                {/* Buttons, depending on modes */}
                                <Grid container spacing={2} direction={{ xs: "row", md: "column" }} alignItems='stretch' justifyContent='space-evenly' height='100%'>
                                    {(editMode || addMode) && (<>
                                        <Grid item>
                                            <Button
                                                className='submit-button'
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    backgroundColor: "var(--button-background-6)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', margin: '0px 0px', width: '100%',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                                }}
                                            >
                                                {/* Save */}
                                                {t('clientSettings.connectorsButton.save')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className='submit-button'
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    backgroundColor: "var(--button-background-4)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', margin: '0px 0px', width: '100%',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                                                }}
                                                onClick={editMode ? (cancelUpdate) : (() => callbackCancelAdd(tempId))}
                                            >
                                                {/* Cancel */}
                                                {t('clientSettings.connectorsButton.cancel')}
                                            </Button>
                                        </Grid>
                                    </>)}
                                    {viewMode && (<>
                                        <Grid item>
                                            <Button
                                                className='submit-button'
                                                variant="contained"
                                                type="button"
                                                sx={{
                                                    backgroundColor: "var(--button-background-6)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', margin: '0px 0px', width: '100%',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                                }}
                                                onClick={startUpdate}
                                            >
                                                {/* Edit */}
                                                {t('clientSettings.connectorsButton.edit')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className='submit-button'
                                                variant="contained"
                                                type="button"
                                                sx={{
                                                    backgroundColor: "var(--button-background-4)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', margin: '0px 0px', width: '100%',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                                                }}
                                                onClick={() => setResetPasswordConfirmationDialog(true)}
                                            >
                                                {/* Reset */}
                                                {t('clientSettings.connectorsButton.resetPassword')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className='submit-button'
                                                variant="contained"
                                                type="button"
                                                sx={{
                                                    backgroundColor: "var(--button-background-2)",
                                                    textTransform: 'none',
                                                    fontSize: '14px', fontWeight: '400', margin: '0px 0px', width: '100%',
                                                    ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                                                }}
                                                onClick={() => setDeleteDialog(true)}
                                            >
                                                {/* Delete */}
                                                {t('clientSettings.connectorsButton.delete')}
                                            </Button>
                                        </Grid>
                                    </>)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}