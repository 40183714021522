import '../../styles/HuntsPage.css'

import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function AdminPage() {
	const { t } = useTranslation();

	return (
		<div className='nhub-campaign-body-container'>
			<div className="invest-menu-tab-bar admin-tab">
				<ul className='menu-tab-items'>
					<li><NavLink activeclassname={"active"} to={'/admin/users'}>{t('adminPages.adminPage.tab-Users')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/hunters'}>{t('adminPages.adminPage.tab-Hunters')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/pendingReports'}>{t('adminPages.adminPage.tab-PendingReports')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/pendingWikis'}>{t('adminPages.adminPage.tab-PendingWikis')}</NavLink></li>
					<li><NavLink activeclassname="active" to={'/admin/clients'}>{t('adminPages.adminPage.tab-Clients')}</NavLink></li>
					<li><NavLink activeclassname="active" to={'/admin/campaign'}>{t('adminPages.adminPage.tab-Campaigns')}</NavLink></li>
				</ul>
			</div>
			<div className="campaignBox-content">
				<Outlet />
			</div>
		</div>
	)
}

export default AdminPage