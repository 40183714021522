import '../../styles/Connectors.css'
import elasticlogo from '../../assets/images/connectors/elastic-logo.svg';

import Connector from './Connector';
import { Alert, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";

import { dateDisplay } from "../../utils/formatDate";
import DownloadButton from "../../components/DownloadButton";
import { downloadElasticConfig } from '../../services/connector.service';
import { useState } from 'react';

const ElasticForm = ({ displayNameRef, siemUrlRef, ssoEnabledRef, viewMode, addMode, editMode, connectorInfos }) => {
    const { t } = useTranslation();
    const [isSSOEnabled, SetisSSOEnabled] = useState(typeof connectorInfos?.sso_enabled === 'undefined' ? true : connectorInfos?.sso_enabled);
    return (
        <>
            {/* Form fields are 2x2 on big screens, and column on small */}
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={3} lg={2}>
                    <label htmlFor="displayName" className="summary-titles">{t('clientSettings.elastic-connector.form-displayName')}</label>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <input required ref={displayNameRef} disabled={!(editMode || addMode)} placeholder="elastic #1" type="text" id="displayName" defaultValue={connectorInfos?.displayName} style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <label htmlFor="ssoEnabled" className="summary-titles">{t('clientSettings.elastic-connector.form-SSOEnabled')}</label>
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <input ref={ssoEnabledRef} disabled={!(editMode || addMode)} type="checkbox" id="ssoEnabled" defaultChecked={addMode ? true : connectorInfos?.sso_enabled} onChange={(e) => SetisSSOEnabled(e.target.checked)} />
                </Grid>
                {isSSOEnabled ? (<>
                    <Grid item xs={12} sm={3} lg={2}>
                        <label htmlFor="siemUrl" className="summary-titles">{t('clientSettings.elastic-connector.form-SIEMURL')}</label>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={10}>
                        <input ref={siemUrlRef} required disabled={!(editMode || addMode)} placeholder="https://contoso.kb.northeurope.azure.elastic-cloud.com:9243" type="url" id="siemUrl" defaultValue={connectorInfos?.siem_url} style={{ width: '100%' }} />
                    </Grid>
                </>) : <></>}
                {(!isSSOEnabled && (addMode || editMode)) ? (<>
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            {t('clientSettings.elastic-connector.warningSSODisable')}
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.nybble-security.io/connectors/elastic-connector/">{t('clientSettings.elastic-connector.warningSSODisableLink')}</a>
                        </Alert>
                    </Grid>
                </>) : <></>}
                {/* Date and username are useless on add / edit */}
                {viewMode && (
                    <><Grid item xs={12} sm={3} lg={2}>
                        <label htmlFor="username" className="summary-titles">{t('clientSettings.elastic-connector.form-username')}</label>
                    </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <span id="username" style={{ width: '100%' }}>{connectorInfos?._id}</span>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={2}>
                            <label htmlFor="lastConn" className="summary-titles">{t('clientSettings.elastic-connector.form-lastConnection')}</label>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <span id="lastConn" style={{ width: '100%' }}>{connectorInfos?.lastUsedAt ? dateDisplay(connectorInfos?.lastUsedAt) : 'N/A'}</span>
                        </Grid>
                        {connectorInfos.sso_enabled ? (<>
                            <Grid item xs={12} sm={3} lg={2}>
                                <label htmlFor="sso" className="summary-titles">{t('clientSettings.elastic-connector.form-sso')}</label>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={10}>
                                <span id="sso" style={{ width: '100%' }}>
                                    <DownloadButton
                                        downloadParameters={{ connectorId: connectorInfos?._id }}
                                        fileName={'elastic-sso.yaml'}
                                        downloadFunction={downloadElasticConfig}
                                        label={t('clientSettings.downloadButton.download')} /></span>
                            </Grid>
                        </>) : <></>}
                        <Grid item xs={12} sm={3} lg={2}>
                            <label htmlFor="configs" className="summary-titles">{t('clientSettings.elastic-connector.form-configs')}</label>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <span id="configs" style={{ width: '100%' }}><a target="_blank" rel="noopener noreferrer" href="https://docs.nybble-security.io/connectors/elastic-configuration/">(Nybble Docs) Elastic configuration files</a></span>
                        </Grid>
                    </>)}
                { /* How to is useful on add / edit */}
                {!viewMode && (
                    <>
                        <Grid item xs={12} sm={3} lg={2}>
                            <label htmlFor="documentation" className="summary-titles">{t('clientSettings.elastic-connector.form-doc')}</label>
                        </Grid>
                        <Grid item xs={12} sm={9} lg={10}>
                            <span id="configs" style={{ width: '100%' }}><a target="_blank" rel="noopener noreferrer" href="https://docs.nybble-security.io/connectors/elastic-connector/">(Nybble Docs) Elastic connector howto</a></span>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default function ElasticConnector({ initialState, callbackAdd, callbackDelete, tempId, connectorInfos, client_id, callbackCancelAdd }) {

    return (
        <Connector
            initialState={initialState}
            callbackAdd={callbackAdd}
            callbackDelete={callbackDelete}
            tempId={tempId}
            connectorInfos={connectorInfos}
            client_id={client_id}
            callbackCancelAdd={callbackCancelAdd}
            connector_type={'elastic'}
            logo={elasticlogo}
            ConnectorForm={ElasticForm}
        />
    )
}