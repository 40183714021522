/*
    Everything to push / pull data from Stats API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_STATS_URL = `${calc_nhub_api()}/api/stats/`

export function getAlertsStats(token) {
    return axios
        .get(API_STATS_URL + 'alerts/1YBySeverityAndMonths', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//get stats of received alerts by period
export function postReceivedAlerts(token, content) {
    return axios
        .post(
            API_STATS_URL + 'alerts/received',
            { period: content },
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//get stats of underInvestigation alerts by period
export function postUnderInvestAlertsAlerts(token, content) {
    return axios
        .post(
            API_STATS_URL + 'alerts/underInvestigation',
            { period: content },
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//get stats of investigated alerts by period
export function postInvestigatedAlerts(token, content) {
    return axios
        .post(
            API_STATS_URL + 'alerts/investigated',
            { period: content },
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//get stats of falsePositive alerts by period
export function postFalsePositiveAlerts(token, content) {
    return axios
        .post(
            API_STATS_URL + 'alerts/falsePositive',
            { period: content },
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}