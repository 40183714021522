import '../../styles/Body.css'
import '../../App.css'

import { useState, useEffect, useContext, useRef } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { Grid } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { DoughnutChart, BarChart } from '../../components/Charts';
import { getAlertsStats, postReceivedAlerts, postUnderInvestAlertsAlerts, postInvestigatedAlerts, postFalsePositiveAlerts } from '../../services/stats.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function Overview() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryDataRef = useRef(false); // Flag to track if useEffect has been triggered

    //ToggleButtonGroup's values
    const [receivedAlertsPeriod, setReceivedAlertsPeriod] = useState('30Days');
    const [underInvestAlertsPeriod, setUnderInvestAlertsPeriod] = useState('30Days');
    const [investigatedAlertsPeriod, setInvestigatedAlertsPeriod] = useState('30Days');
    const [falsePositiveAlertsPeriod, setFalsePositiveAlertsPeriod] = useState('30Days');

    //ToggleButtonGroup's handle
    const handleChange = (event, newPeriod) => { if (newPeriod !== null) { setReceivedAlertsPeriod(newPeriod) }; };
    const handleChange2 = (event, newPeriod) => { if (newPeriod !== null) { setUnderInvestAlertsPeriod(newPeriod) }; };
    const handleChange3 = (event, newPeriod) => { if (newPeriod !== null) { setInvestigatedAlertsPeriod(newPeriod) }; };
    const handleChange4 = (event, newPeriod) => { if (newPeriod !== null) { setFalsePositiveAlertsPeriod(newPeriod) }; };

    // DoughnutChart's values
    const [receivedAlertsCount, setReceivedAlertsCount] = useState([0, 0, 0, 0]);
    const [underInvestAlertsCount, setUnderInvestAlertsCount] = useState([0, 0, 0, 0]);
    const [investigatedAlertsCount, setInvestigatedAlertsCount] = useState([0, 0, 0, 0]);
    const [falsePositiveAlertsCount, setFalsePositiveAlertsCount] = useState([0, 0, 0, 0]);

    const { setSnackbarObj } = useContext(UserInfosContext);

    // DoughnutChart's of received alerts by period
    const {
        data: receivedAlerts,
        error: errorReceivedAlerts,
        failureReason: failureReceivedAlerts,
        failureCount: failureCountReceivedAlerts,
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!receivedAlertsPeriod, // ensures the query is only executed when these conditions are met.
        queryKey: ['receivedAlerts', { period: receivedAlertsPeriod }],
        queryFn: () => getAccessTokenSilently().then((token) => postReceivedAlerts(token, receivedAlertsPeriod)),
    });
    useEffect(() => {
        if (receivedAlerts) {
            let newArrayReceivedAlertsCount = [0, 0, 0, 0]
            receivedAlerts.forEach(element => { newArrayReceivedAlertsCount.splice(element.severity - 1, 1, element.count) });
            setReceivedAlertsCount(newArrayReceivedAlertsCount)
        }
    }, [receivedAlerts])

    // DoughnutChart's of underInvestigation alerts by period
    const {
        data: underInvestAlerts,
        error: errorUnderInvestAlerts,
        failureReason: failureUnderInvestAlerts,
        failureCount: failureCountUnderInvestAlerts,
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!underInvestAlertsPeriod, // ensures the query is only executed when these conditions are met.
        queryKey: ['underInvestAlerts', { period: underInvestAlertsPeriod }],
        queryFn: () => getAccessTokenSilently().then((token) => postUnderInvestAlertsAlerts(token, underInvestAlertsPeriod)),
    });
    useEffect(() => {
        if (underInvestAlerts) {
            let newArrayUnderInvestAlertsCount = [0, 0, 0, 0]
            underInvestAlerts.forEach(element => { newArrayUnderInvestAlertsCount.splice(element.severity - 1, 1, element.count) });
            setUnderInvestAlertsCount(newArrayUnderInvestAlertsCount)
        }
    }, [underInvestAlerts])

    // DoughnutChart's of investigated alerts by period
    const {
        data: investigatedAlerts,
        error: errorInvestigatedAlerts,
        failureReason: failureInvestigatedAlerts,
        failureCount: failureCountInvestigatedAlerts,
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!investigatedAlertsPeriod, // ensures the query is only executed when these conditions are met.
        queryKey: ['investigatedAlerts', { period: investigatedAlertsPeriod }],
        queryFn: () => getAccessTokenSilently().then((token) => postInvestigatedAlerts(token, investigatedAlertsPeriod)),
    });
    useEffect(() => {
        if (investigatedAlerts) {
            let newArrayInvestigatedAlertsCount = [0, 0, 0, 0]
            investigatedAlerts.forEach(element => { newArrayInvestigatedAlertsCount.splice(element.severity - 1, 1, element.count) });
            setInvestigatedAlertsCount(newArrayInvestigatedAlertsCount)
        }
    }, [investigatedAlerts])

    // DoughnutChart's of falsePositive alerts by period
    const {
        data: falsePositiveAlerts,
        error: errorFalsePositiveAlerts,
        failureReason: failureFalsePositiveAlerts,
        failureCount: failureCountFalsePositiveAlerts,
    } = useQuery({
        enabled: !!getAccessTokenSilently && !!falsePositiveAlertsPeriod, // ensures the query is only executed when these conditions are met.
        queryKey: ['falsePositiveAlerts', { period: falsePositiveAlertsPeriod }],
        queryFn: () => getAccessTokenSilently().then((token) => postFalsePositiveAlerts(token, falsePositiveAlertsPeriod)),
    });
    useEffect(() => {
        if (falsePositiveAlerts) {
            let newArrayFalsePositiveAlertsCount = [0, 0, 0, 0]
            falsePositiveAlerts.forEach(element => { newArrayFalsePositiveAlertsCount.splice(element.severity - 1, 1, element.count) });
            setFalsePositiveAlertsCount(newArrayFalsePositiveAlertsCount)
        }
    }, [falsePositiveAlerts])

    // BarChart's values
    const [barChartLow, setBarChartLow] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [barChartMedium, setBarChartMedium] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [barChartHigh, setBarChartHigh] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [barChartCritical, setBarChartCritical] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    // Formatted array of the last 12 months
    const last12Months = [...new Array(12)].map((i, idx) => moment().startOf("month").subtract(idx, "months").format('YYYY-MM')).reverse();

    // All alert's stats to fill the BarChart
    const {
        data: alertsStats,
        error: errorAlertsStats,
        failureReason: failureAlertsStats,
        failureCount: failureCountAlertsStats,
    } = useQuery({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        queryKey: ['alertsStats'],
        queryFn: () => getAccessTokenSilently().then((token) => getAlertsStats(token)),
    });

    useEffect(() => {
        if (alertsStats && !queryDataRef.current) {
            // Create copies of the state arrays to avoid mutating them directly
            let newArrayBarChartLow = [...barChartLow];
            let newArrayBarChartMedium = [...barChartMedium];
            let newArrayBarChartHigh = [...barChartHigh];
            let newArrayBarChartCritical = [...barChartCritical];

            // Loop through alertsStats to update the new arrays
            alertsStats.forEach(element => {
                let index = last12Months.indexOf(element.createdAt);
                // Divide each object by severity then replace its value in the array associated where its index defined by its month
                if (index !== -1) { // Ensure the month exists in the last12Months array
                    if (element.severity === 1) {
                        newArrayBarChartLow[index] = element.count;
                    } else if (element.severity === 2) {
                        newArrayBarChartMedium[index] = element.count;
                    } else if (element.severity === 3) {
                        newArrayBarChartHigh[index] = element.count;
                    } else if (element.severity === 4) {
                        newArrayBarChartCritical[index] = element.count;
                    }
                }
            });

            // Update the state once after the loop
            setBarChartLow(newArrayBarChartLow);
            setBarChartMedium(newArrayBarChartMedium);
            setBarChartHigh(newArrayBarChartHigh);
            setBarChartCritical(newArrayBarChartCritical);

            queryDataRef.current = true; // Set the flag to true to indicate useEffect has been triggered
        }
    }, [alertsStats, barChartLow, barChartMedium, barChartHigh, barChartCritical, last12Months]);

    // Display an error snackbar in case of a query error
    useEffect(() => {
		const snackbarReceivedAlerts = ResponseSnackbarErrorHandler('receivedAlerts', errorReceivedAlerts, failureReceivedAlerts, failureCountReceivedAlerts)
		if (snackbarReceivedAlerts) { setSnackbarObj(snackbarReceivedAlerts) }

		const snackbarUnderInvestAlerts = ResponseSnackbarErrorHandler('underInvestAlerts', errorUnderInvestAlerts, failureUnderInvestAlerts, failureCountUnderInvestAlerts)
		if (snackbarUnderInvestAlerts) { setSnackbarObj(snackbarUnderInvestAlerts) }

		const snackbarInvestigatedAlerts = ResponseSnackbarErrorHandler('investigatedAlerts', errorInvestigatedAlerts, failureInvestigatedAlerts, failureCountInvestigatedAlerts)
		if (snackbarInvestigatedAlerts) { setSnackbarObj(snackbarInvestigatedAlerts) }

		const snackbarFalsePositiveAlerts = ResponseSnackbarErrorHandler('falsePositiveAlerts', errorFalsePositiveAlerts, failureFalsePositiveAlerts, failureCountFalsePositiveAlerts)
		if (snackbarFalsePositiveAlerts) { setSnackbarObj(snackbarFalsePositiveAlerts) }

		const snackbarAlertsStats = ResponseSnackbarErrorHandler('alertsStats', errorAlertsStats, failureAlertsStats, failureCountAlertsStats)
		if (snackbarAlertsStats) { setSnackbarObj(snackbarAlertsStats) }
	}, [errorAlertsStats, errorFalsePositiveAlerts, errorInvestigatedAlerts, errorReceivedAlerts, errorUnderInvestAlerts, failureAlertsStats, failureCountAlertsStats, failureCountFalsePositiveAlerts, failureCountInvestigatedAlerts, failureCountReceivedAlerts, failureCountUnderInvestAlerts, failureFalsePositiveAlerts, failureInvestigatedAlerts, failureReceivedAlerts, failureUnderInvestAlerts, setSnackbarObj]);

    return (
        <div className='nhub-overview-container'>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} >
                    <div className='widget chart '>
                        {/* <h5>Received alerts</h5> */}
                        <h5>{t('statistics.Overview.title-received')}</h5>
                        <ToggleButtonGroup
                            color='primary'
                            value={receivedAlertsPeriod}
                            exclusive
                            onChange={handleChange}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            sx={{
                                marginTop: "10px",
                                width: '100%',
                            }}
                        >
                            <ToggleButton
                                value='30Days'
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.30d')}
                            </ToggleButton>
                            <ToggleButton
                                value='6Months'
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.6m')}
                            </ToggleButton>
                            <ToggleButton
                                value='1Year'
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.1y')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <span className='chart-label'>{t('statistics.Overview.legend-chart')}</span>
                        <DoughnutChart data={receivedAlertsCount} label={'Alerts'} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <div className='widget chart'>
                        {/* <h5>Under investigation alerts</h5> */}
                        <h5>{t('statistics.Overview.title-underInvest')}</h5>
                        <ToggleButtonGroup
                            color="primary"
                            value={underInvestAlertsPeriod}
                            exclusive
                            onChange={handleChange2}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            sx={{
                                marginTop: "10px",
                                width: '100%',
                            }}
                        >
                            <ToggleButton
                                value="30Days"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.30d')}
                            </ToggleButton>
                            <ToggleButton
                                value="6Months"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.6m')}
                            </ToggleButton>
                            <ToggleButton
                                value="1Year"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.1y')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <span className='chart-label'>{t('statistics.Overview.legend-chart')}</span>
                        <DoughnutChart data={underInvestAlertsCount} label={'Investigations'} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <div className='widget chart '>
                        {/* <h5>Investigated alerts</h5> */}
                        <h5>{t('statistics.Overview.title-investigated')}</h5>
                        <ToggleButtonGroup
                            color="primary"
                            value={investigatedAlertsPeriod}
                            exclusive
                            onChange={handleChange3}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            sx={{
                                marginTop: "10px",
                                width: '100%',
                            }}
                        >
                            <ToggleButton
                                value="30Days"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.30d')}
                            </ToggleButton>
                            <ToggleButton
                                value="6Months"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.6m')}
                            </ToggleButton>
                            <ToggleButton
                                value="1Year"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.1y')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <span className='chart-label'>{t('statistics.Overview.legend-chart')}</span>
                        <DoughnutChart data={investigatedAlertsCount} label={'Investigations'} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <div className='widget chart '>
                        {/* <h5>False positive alerts</h5> */}
                        <h5>{t('statistics.Overview.title-falsePositive')}</h5>
                        <ToggleButtonGroup
                            color="primary"
                            value={falsePositiveAlertsPeriod}
                            exclusive
                            onChange={handleChange4}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            sx={{
                                marginTop: "10px",
                                width: '100%',
                            }}
                        >
                            <ToggleButton
                                value="30Days"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.30d')}
                            </ToggleButton>
                            <ToggleButton
                                value="6Months"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.6m')}
                            </ToggleButton>
                            <ToggleButton
                                value="1Year"
                                sx={{ textTransform: 'none', fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%', }}
                            >
                                {t('button.1y')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <span className='chart-label'>{t('statistics.Overview.legend-chart')}</span>
                        <DoughnutChart data={falsePositiveAlertsCount} label={'Investigations'} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='widget chart barChart'>
                        {/* <h5>Received alerts / month</h5> */}
                        <h5>{t('statistics.Overview.title-barChart')}</h5>
                        <BarChart label={last12Months} low={barChartLow} medium={barChartMedium} high={barChartHigh} critical={barChartCritical} />
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}
export default Overview