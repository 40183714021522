import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

function SkeletonCampaignItem({ rows }) {
  const { t } = useTranslation();

  return (
      [...Array(rows)].map((_, index) => (
        
        <div className='campaignItem-container widget' key={index} >
          <div className='campaignItem-identity'>
            <div className='campaignItem-logo'>
              {/* logo or colored font with few letters */}
              <Skeleton variant="img" width={'100%'} height={'100%'} />
            </div>
            <div className='campaignItem-contentCenter'>
              {/* Name of client, name of campaign, status, dates */}
              <Skeleton width={200} />
              <div className='campaignItem-statusDates' style={{ display: 'inline-flex', columnGap: '5px' }}>
                <Skeleton variant="rounded" width={110} height={23} />
                <Skeleton width={200} />
              </div>
            </div>
          </div>
          <div className='campaignItem-contentRight'>
            {/* bounty range, submit button or ask for join */}
            <div className='campaignItem-bounties'>
              <h3>{t('hunts.campaigns.bounty-range')}</h3>
              <span><Skeleton /></span>
            </div>
          </div>
        </div>

      ))
    
  );
};
export default SkeletonCampaignItem;