import '../../App.css'
import '../../styles/HuntsPage.css'
import '../../styles/Wiki.css'

import { useState, useEffect, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown'

import { Grid, TextField, Button, Skeleton } from '@mui/material';

import { RiSave2Line, RiArrowRightSLine, RiSendPlaneLine, RiRefreshLine, RiDeleteBin2Line } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getWikiPage, getRuleURLs } from '../../services/wiki.service';
import { postWikiContribution } from '../../services/wikiContribution.service'
import { UserInfosContext } from '../../context/UserInfosContext';


function CreateWikiContribution() {
	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient()
	let { wikiId } = useParams();
	const navigate = useNavigate();

	const { setSnackbarObj } = useContext(UserInfosContext);

	// react state. used for field validation and initial display
	const [wikiFormValues, setWikiFormValues] = useState({});
	const [wikiPath, setWikiPath] = useState();

	const {
		data: ruleURLs,
		isLoading: loadingRuleURLs,
		error: errorRuleURLs,
		failureReason: failureRuleURLs,
		failureCount: failureCountRuleURLs,
		isFetching: fetchingRuleURLs,
	} = useQuery({
		queryKey: ['ruleURLs'],
		queryFn: () => getRuleURLs(),
		staleTime: 60 * 60 * 1000, // 60 mins (duration data is considered fresh)
		cacheTime: 65 * 60 * 1000, // 65 mins (duration React Query stores inactive data before it is deleted from the cache)
	});
	useEffect(() => {
		if (ruleURLs) {
			setWikiPath(ruleURLs[wikiId])
		}
	}, [ruleURLs, wikiId])


	const {
		data: githubVersion,
		isLoading: loadingGithubVersion,
		error: errorGithubVersion,
		failureReason: failureGithubVersion,
		failureCount: failureCountGithubVersion,
		isFetching: fetchingGithubVersion
	} = useQuery({
		enabled: !!getAccessTokenSilently && !!wikiPath, // ensures the query is only executed when these conditions are met.
		queryKey: ['githubVersion', { path: wikiPath }],
		queryFn: () => getWikiPage(wikiPath),
	});
	useEffect(() => {
		if (githubVersion) {
			setWikiFormValues({
				current_version: githubVersion,
				new_version: githubVersion,
				wiki_file_path: wikiPath,
				wiki_page_name: wikiPath.substring(wikiPath.lastIndexOf('/') + 1)
			})
		}
	}, [wikiId, githubVersion, wikiPath])

	// validation failed indicators
	const [wikiFormErrors, setWikiFormErrors] = useState({
		wiki_page_name: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	// has user done at least one thing on the field (to avoid displaying an error at 1st form display)
	const [wikiFormTouches, setWikiFormTouches] = useState({
		wiki_page_name: false,
		wiki_file_path: false,
		new_version: false,
		current_version: false
	});

	// update the react state with user input
	const handleInputChange = e => {
		const { name, value } = e.target
		setWikiFormValues({ ...wikiFormValues, [name]: value })
	}

	// update the validation infos when user leaves the field (used only on fields requiring validation)
	const handleBlur = e => {
		const { name,
			// value
		} = e.target
		wikiFormValues[name] ? setWikiFormErrors({ ...wikiFormErrors, [name]: false }) : setWikiFormErrors({ ...wikiFormErrors, [name]: true });
		setWikiFormTouches({ ...wikiFormTouches, [name]: true });
	}

	const postWikiContributionMutation = useMutation({
		enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
		mutationFn: () => getAccessTokenSilently().then((token) => postWikiContribution(token, wikiFormValues)),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['wikiContributionsList'] }) //invalid query data, need refetch
			setSnackbarObj({
				message: t("snackbar.saved"),
				status: 'success',
				timestamp: new Date().getTime()
			})
			navigate('/wikis/contribution/' + data.wikiContribution)
		},
		onError: (error) => {
			const snackbarError = ResponseSnackbarErrorHandler('wikiContributionsList', error)
			if (snackbarError) { setSnackbarObj(snackbarError) }
		}
	})
	const handleSave = (e) => {
		e.preventDefault()
		postWikiContributionMutation.mutate()
	}

	useEffect(() => {
		const snackbarGithubVersion = ResponseSnackbarErrorHandler('githubVersion', errorGithubVersion, failureGithubVersion, failureCountGithubVersion)
		if (snackbarGithubVersion) { setSnackbarObj(snackbarGithubVersion) }

		const snackbarRuleURLs = ResponseSnackbarErrorHandler('ruleURLs', errorRuleURLs, failureRuleURLs, failureCountRuleURLs)
		if (snackbarRuleURLs) { setSnackbarObj(snackbarRuleURLs) }

	}, [errorGithubVersion, errorRuleURLs, failureCountGithubVersion, failureCountRuleURLs, failureGithubVersion, failureRuleURLs, setSnackbarObj]);

	/* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
	const loadingGithubVersionData = ((loadingGithubVersion && fetchingGithubVersion) || (loadingRuleURLs && fetchingRuleURLs))


	return (
		<div className='report-container'>
			<div className="menu-title">
				<h4>
					<span>
						<Link activeclassname={"active"} to='/wikis'>
							{/* Edit Wiki */}
							{t('wikis.createWikiContribution.head-title')}
						</Link>
					</span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} />{wikiFormValues.wiki_page_name}
				</h4>
				{loadingGithubVersionData ? <Skeleton width={'350px'} /> : null}
			</div>
			<div className='report-form'>
				<Grid container spacing={2} direction='row'>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiSave2Line />}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-6)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-6)" }
							}}
							onClick={(e) => { handleSave(e) }}
						>
							{/* Save */}
							{t('button.save')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiSendPlaneLine />}
							disabled={true}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-4)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-4)" }
							}}
						>
							{/* Submit */}
							{t('button.submit')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiRefreshLine />}
							disabled={true}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-5)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-5)" }
							}}
						>
							{/* Submit */}
							{t('button.reload')}
						</Button>
					</Grid>
					<Grid item xs={6} sm='auto'>
						<Button
							variant="contained"
							type="submit"
							startIcon={<RiDeleteBin2Line />}
							disabled={true}
							fullWidth
							sx={{
								backgroundColor: "var(--button-background-2)",
								textTransform: 'none',
								fontSize: '14px', fontWeight: '400', height: '100%',
								':hover': { backgroundColor: "var(--button-background-hover-2)" }
							}}
						>
							{/* Submit */}
							{t('button.delete')}
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={12} md={6}>
						<div className="widget">
							<h5>{t('form.wiki-edition')}</h5>
							<div className="widgetFormValuesTextArea edit_wiki_content">
								{loadingGithubVersionData
									? [...Array(10)].map((_, index) => (
										<Skeleton key={index} />
									))
									: <TextField
										fullWidth
										required
										name="new_version"
										variant="standard"
										multiline
										minRows={3}
										maxRows={50}
										onChange={handleInputChange}
										onBlur={handleBlur}
										value={wikiFormValues.new_version}
									/>
								}
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6}>
						<div className='widget'>
							<h5>{t('form.wiki-render')}</h5>
							<div className='invest-markdown edit_wiki_content'>
								{loadingGithubVersionData
									? <>
										<Skeleton variant="rounded" width={'100%'} height={'27px'} sx={{ margin: '0.67em 0' }} />
										{[...Array(9)].map((_, index) => (
											<Skeleton key={index} />
										))}
									</>
									: <ReactMarkdown children={wikiFormValues.new_version} />
								}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}
export default CreateWikiContribution