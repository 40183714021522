import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'

import React from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { dateDisplay } from '../../utils/formatDate';
import { SelectColumnFilter } from '../../components/TableFilters';


function WikiContributionsTable({ wikiContributionsList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { header: t('tables.header-Title'), width: '210px' },
    { header: t('tables.header-Status'), enableColumnFilter:true },
    { header: t('tables.header-RejectReason') },
    { header: t('tables.header-CreatedDate'), enableSorting:true },
    { header: t('tables.header-UpdateDate'), enableSorting:true }
  ]

  if (loadingData) return (
    <div className='tableFixHead alert-table'>
      <SkeletonTable columns={headers} rows={20} />
    </div>
  )

  return (<Table data={wikiContributionsList} />);
}



function Table({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Title'),
        accessor: 'wiki_page_name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
        Cell: ({ row, cell: { value } }) =>
          <div className="row-title">
            <Link to={{
              pathname: '/wikis/contribution/' + row.original._id
              // state: { value },
            }}
              values={value} title="Contribution">{value} </Link>
          </div>
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
      },
      {
        Header: t('tables.header-RejectReason'),
        accessor: 'reject_reason',
        Filter: false,
        canSorter: false,
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    [t]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      //  defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='tableFixHead alert-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(!data || data.length === 0)
            ? <p style={{ margin: '15px' }}>{t('wikis.wikiContributionsTable.info-noData')}</p>
            : rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>

                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  );
}

export default WikiContributionsTable;