import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import React from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";
import { monthDisplayUTC } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter } from '../../components/TableFilters';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";

function ClientInvoicePeriods({ invoice_periods, loadingData }) {
    const { t } = useTranslation();

    const headers = [
        { header: t('adminPages.clientInvoiceTable.date') },
        { header: t('adminPages.clientInvoiceTable.consumed-and-allowed') },
        { header: t('adminPages.clientInvoiceTable.invoice_status'), enableColumnFilter:true },
        { header: t('adminPages.clientInvoiceTable.comment') },
    ]

    return (
        <>
            <h5>{t('adminPages.clientInvoice.mainTitle')}</h5>
            {loadingData
                ? <div className='tableFixHead' style={{ maxHeight: '400px' }}>
                    <SkeletonTable columns={headers} rows={3} />
                </div>
                : !!invoice_periods
                    ? (<ClientInvoicePeriodsTable data={invoice_periods} />)
                    : (<>{t('adminPages.clientInvoice.loading')}</>)
            }
        </>
    )
}


function ClientInvoicePeriodsTable({ data }) {
    const { t } = useTranslation();

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )


    const columns = React.useMemo(
        () => [
            {
                Header: t('adminPages.clientInvoiceTable.date'),
                Filter: false,
                accessor: 'date', // accessor is the "key" in the data
                canSorter: false, // sortable by is name
                Cell: ({ row, cell: { value } }) => <>{monthDisplayUTC(value)}</>
            },
            {
                Header: t('adminPages.clientInvoiceTable.consumed-and-allowed'),
                Filter: false,
                canSorter: false,
                maxWidth: 40,
                minWidth: 10,
                width: 20,
                Cell: ({ row }) => <>{row.original.alerts_consumed + '/' + row.original.alerts_allowed}</>
            },
            {
                Header: t('adminPages.clientInvoiceTable.invoice_status'),
                accessor: 'invoice_status',
                Filter: SelectColumnFilter,
                canSorter: false,
                maxWidth: 40,
                minWidth: 10,
                width: 20,
            },
            {
                Header: t('adminPages.clientInvoiceTable.comment'),
                accessor: 'comment',
                canSorter: false,
                Filter: false,
                maxWidth: 40,
                minWidth: 10,
                width: 20,
            },
        ],
        [t]
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                sortBy: [
                    {
                        id: 'date',
                        desc: true
                    }
                ]
            }
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    );

    return (
        <div className='tableFixHead' style={{ maxHeight: '400px' }}>
            <table {...getTableProps()} >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                                    <div className='th-div'>
                                        {column.render('Header')}
                                        <span className='sort-span'>
                                            {column.canSorter
                                                ? column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <FaSortDown color='var(--table-th-filter-active)' />
                                                        : <FaSortUp color='var(--table-th-filter-active)' />
                                                    : < FaSort />
                                                : ''
                                            }
                                        </span>
                                        <span>{column.canFilter ? column.render('Filter') : null}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr key={row.id}{...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td key={cell.id}{...cell.getCellProps({
                                            // style: {
                                            //   minWidth: cell.column.minWidth,
                                            //   width: cell.column.width,
                                            // },
                                        }
                                        )} >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

    );
}


export default ClientInvoicePeriods;