import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';

import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Select, MenuItem, Button, IconButton, ClickAwayListener, Skeleton } from '@mui/material';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { RiAddLine, RiFilter2Line } from 'react-icons/ri';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import CreateCampaign from './CreateCampaign';
import { getHuntCampaignReports } from '../../services/huntCampaign.service';
import { dateDisplay, } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { UserInfosContext } from '../../context/UserInfosContext';

function CampaignsTable({ huntCampaignsList, clientsList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { header: t('tables.header-Name'), width: '140px', enableColumnFilter:true },
    { header: t('tables.header-Client'), enableColumnFilter:true },
    { header: t('tables.header-Status'), enableColumnFilter:true },
    { header: t('tables.header-Reports#') },
    { header: t('tables.header-StartDate'), enableSorting:true },
    { header: t('tables.header-EndDate'), enableSorting:true }
  ]

  if (loadingData) return (
    <div className='tableFixHead invest-table'>
      <SkeletonTable columns={headers} rows={5} />
    </div>
  )

  return (<Table data={huntCampaignsList} clientsList={clientsList} />);
}


function ReportsCell({ campaignId }) {
  const [campaignReports, setCampaignReports] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  const { setSnackbarObj } = useContext(UserInfosContext);

  const {
    data: huntCampaignReports,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['huntCampaignReportsList', { _id: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaignReports(token, campaignId)),
  });

  useEffect(() => {
    if (huntCampaignReports) {
      setCampaignReports(huntCampaignReports)
    }
  }, [huntCampaignReports])

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('huntCampaignReportsList', error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }

  }, [error, failureCount, failureReason, setSnackbarObj]);

  if (isLoading && isFetching) return <Skeleton />

  return (<>{campaignReports.length}</>)
}

function Table({ data, clientsList }) {
  const { t } = useTranslation();

  const [openCreateCampaign, setOpenCreateCampaign] = useState(false);


  const handleClickOpenCreateCampaign = () => {
    setOpenCreateCampaign(true);
  };

  // This is a custom filter UI for selecting client by id
  // and render a name instead
  // GKR: putting the function inside the component gives access to the clientList to do id -> client name mapping
  const SelectClientIDColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id }
  }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })

      return [...options.values()]
    }, [id, preFilteredRows])

    const [isOpen, setIsOpen] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    // Render a multi-select box0
    return (
      <>
        <span
          className="filter-opener"
        >
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isFiltered ? (
              <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
            ) : (
              <RiFilter2Line />
            )}
          </IconButton>
        </span>
        {isOpen
          ?
          <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
            <div className='filter-container'>
              <Select
                MenuProps={{
                  disablePortal: true
                }}
                value={filterValue}
                onChange={e => {
                  setIsFiltered(e.target.value);
                  setFilter(e.target.value || undefined)
                }}
                sx={{
                  m: 1,
                  minWidth: 120,
                  fontSize: 12,
                  height: 24,
                  maxHeight: 24,
                  minHeight: 24,
                  backgroundColor: "var(--nhub-color-background-1)",
                }}
                variant="outlined"
                autoWidth
              >
                <MenuItem value={undefined} sx={{ fontSize: 12 }}>
                  {/* All */}
                  <em>{t('tables.select-all')}</em>
                </MenuItem>
                {options.map((option, i) => (
                  <MenuItem key={i} value={option} sx={{ fontSize: 12 }}>
                    <em>{(clientsList.length > 0) ? clientsList.find(element => element._id === option).displayName : option}</em>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </ClickAwayListener>
          : null}
      </>
    )
  }


  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Name'),
        accessor: 'name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Cell: ({ row, cell: { value } }) =>
          <Link to={{
            pathname: '/admin/campaign/' + row.original._id,
            state: { value },
          }}
            values={value}>{value} </Link>
      },
      {
        Header: t('tables.header-Client'),
        accessor: 'client_id',
        Filter: SelectClientIDColumnFilter,
        filter: 'includes',
        canSorter: false,
        style: {
          maxWidth: 20,
          minWidth: 5,
          width: 10
        },
        Cell: ({ row, cell: { value } }) => clientsList.length !== 0 ? (clientsList.find(element => element._id === value)).displayName : <>{value}</>
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-Reports#'),
        accessor: '_id',
        Filter: false,
        canSorter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <ReportsCell campaignId={value} />
      },
      {
        Header: t('tables.header-StartDate'),
        accessor: 'start_date',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>
      },
      {
        Header: t('tables.header-EndDate'),
        accessor: 'end_date',
        canSorter: true,
        Filter: false,
        style: {
          maxWidth: 40,
          minWidth: 10,
          width: 20
        },
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    // QLS : disable react-hooks/exhaustive-deps to remove the warning of the hook dependency requirement for SelectClientIDColumnFilter
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientsList]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <div className='tableFixHead invest-table'>
        <table {...getTableProps()} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                    <div className='th-div'>
                      {column.render('Header')}
                      <span className='sort-span'>
                        {column.canSorter
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? <FaSortDown color='var(--table-th-filter-active)' />
                              : <FaSortUp color='var(--table-th-filter-active)' />
                            : < FaSort />
                          : ''
                        }
                      </span>
                      <span>{column.canFilter ? column.render('Filter') : null}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='hunt-button-container'>
        <Button
          variant="contained"
          startIcon={<RiAddLine />}
          sx={{
            backgroundColor: "var(--button-background-6)",
            textTransform: 'none',
            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
          }}
          onClick={() => { handleClickOpenCreateCampaign() }}
        >
          {/* Create a new Campaign */}
          {t('adminPages.campaignsTable.button-newCampaign')}
        </Button>
      </div>
      {openCreateCampaign
        ? <div>
          <CreateCampaign setOpenCreateCampaign={setOpenCreateCampaign} openCreateCampaign={openCreateCampaign} clientsList={clientsList} />
        </div>
        : null
      }
    </div>
  );
}

export default CampaignsTable;