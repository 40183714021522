/*
    Everything to push / pull data from HuntReport API
*/


import axios from "axios";
import { calc_nhub_api } from '../context/envURL'

const API_HUNT_REPORT_URL = `${calc_nhub_api()}/api/hunt-report/`

//get all reports
export function getAllHuntReport(token) {
    return axios
        .get(API_HUNT_REPORT_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

//Save a report
export function postHuntReport(token, content) {
    return axios
        .post(
            API_HUNT_REPORT_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
        .catch(error => { throw error });
}

//Report Pending for the admin
export function getHuntReportPending(token) {
    return axios
        .get(API_HUNT_REPORT_URL + 'pending', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

//get report details
export function getHuntReport(token, id) {
    return axios
        .get(API_HUNT_REPORT_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then(response => response.data)
        .catch(error => { throw error });
}

//Edit and Submit a report
export function putHuntReport(token, id, content) {

    return axios
        .put(
            API_HUNT_REPORT_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
        .catch(error => { throw error });
}

//For admin to accept Hunter's report
export function postHuntReportAccept(token, id) {
    return axios
        .post(
            API_HUNT_REPORT_URL + id + '/accept',
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
        .catch(error => { throw error });
}

//For admin to reject Hunter's report with a reason explained
export function postHuntReportReject(token, id, rejectReason) {
    return axios
        .post(
            API_HUNT_REPORT_URL + id + '/reject',
            rejectReason,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            })
        .then(response => response.data)
        .catch(error => { throw error });
}

