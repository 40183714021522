import '../../styles/HuntsPage.css'
import '../../styles/Tables.css'
import '../../styles/ClientSettings.css'

import { useEffect, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Alert, AlertTitle } from '@mui/material';

import Loader from '../../components/Loader'
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import DownloadButton from '../../components/DownloadButton';
import { getClientMe, downloadConfig } from '../../services/client.service';
import { UserInfosContext } from '../../context/UserInfosContext';


function Agents() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

  const clientId = userInfos.client_id[0]

  const {
    data: myClientInfo,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['myClientInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getClientMe(token)),
  });

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('myClientInfo', error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }

  }, [error, failureCount, failureReason, setSnackbarObj]);

  /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if (isLoading && isFetching) return <Loader />

  return (
    <div className='nhub-alert-body-container'>
      {myClientInfo?.instance !== 'HUB+DETECT' ?
        (
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>{t('clientSettings.agents.byos-line1')}<br />{t('clientSettings.agents.byos-line2')}</AlertTitle>
          </Alert>
        ) : (
          <>
            <div className='agent-title'>
              <h4>{t('clientSettings.agents.clientShortName-title')} <span className='download-subtitle'> {myClientInfo?.shortName} </span></h4>
            </div>
            <div className='widget clientSettings-widget'>
              <h5>{t('clientSettings.agents.logstash-title')}<span className='download-subtitle'>{t('clientSettings.agents.logstash-title2')}</span></h5>
              <DownloadButton
                downloadParameters={{ clientId: clientId, config: 'logstash.yml' }}
                fileName={'logstash.yml'}
                downloadFunction={downloadConfig}
                label={t('clientSettings.downloadButton.download')}
              />
              <div>
                <span className='download-info'>
                  {t('clientSettings.agents.link-info')}
                </span>
                <span className='external-links'>
                  <a
                    href={"https://docs.nybble-security.io/elastic-by-nybble/logstash-collector/"}
                    title={"https://docs.nybble-security.io/elastic-by-nybble/logstash-collector/"}
                    target="_blank"
                    rel="noopener noreferrer">
                    https://docs.nybble-security.io/elastic-by-nybble/logstash-collector
                  </a>
                </span>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}

export default Agents;