import React from 'react'

export function BreakLineRender({ content }) {

    const lines = content.split('\n');

    return (
        <>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index < lines.length - 1 && <br />} {/* Render <br /> for all lines except the last one */}
                </React.Fragment>
            ))}
        </>
    );
};